//PC
@media screen and (min-width 769px)
	.news-detail__body
		section
			padding 1em
			border 1px solid #eee
		.boxStyle1
			background #eee
			border-color transparent
		.boxStyle2
			border-color #999
	.intro
		position fixed
		z-index 1000
		top 0
		left 0
		width 100vw
		height 100vh
		&__lead
			visibility hidden
			width 20px
			height auto

	.schop-animation
		position absolute
		overflow hidden
		pointer-events none
		.inner
			position relative
			transition transform 1.5s linear
			canvas
				position absolute


	.schop-animation.schop01
		left 20px
		margin-top 0px
		.inner
			margin-top -100px
			transform-origin right top
			transform rotateZ(90deg)
			canvas
				right -11px
				bottom 0px
		&.show
			.inner
				transform rotateZ(0deg)

	.schop-animation.schop02
		margin-top -400px
		right 0
		.inner
			right -180px
			transform-origin left top
			transform rotateZ(-60deg)
			canvas
				left -12px
				bottom 0px
		&.show
			.inner
				transform rotateZ(-24deg)

	.schop-animation.schop03
		margin-top -380px
		right 0
		.inner
			right -180px
			transform-origin left top
			transform rotateZ(-60deg)
			canvas
				left -12px
				bottom 0px
		&.show
			.inner
				transform rotateZ(-24deg)

	.schop-animation.schop04
		margin-top -550px
		left 0
		.inner
			left -200px
			transform-origin right top
			transform rotateZ(60deg)
			canvas
				right -11px
				bottom 0px
		&.show
			.inner
				transform rotateZ(23deg)


	.schop-animation.schop05
		margin-top -600px
		right 0
		.inner
			right -180px
			transform-origin left top
			transform rotateZ(-60deg)
			canvas
				left -12px
				bottom 0px
		&.show
			.inner
				transform rotateZ(-24deg)

	.cookie
		position fixed
		z-index 20
		bottom 0
		left 0
		width 100vw
		height 140px
		background #ffe600
		visibility hidden
		transform translateY(140px)
		transition transform 0.25s ease-out, visibility 0.25s
		&.open
			visibility visible
			transform translateY(0)
		&__text
			width 740px
			padding 25px 0
			margin 0 auto
			p
				font-size 14px
				font-weight 600
				line-height 28px
				a
					color #ff72a2
					border-bottom 2px solid #ff72a2
					&:hover
						border-bottom 2px solid #ffe600
		&__closebtn
			position absolute
			top 16px
			right 18px
			width 22px

	.mv
		&__wrap
			position relative
			width 100vw
			height 100vh
			min-height 640px
			min-height 750px
		&__movie.loading
			&.bg
				background-image none
				background-color #ffe600
		&__movie
			overflow hidden
			position absolute
			top 0
			left 0
			width 100vw
			height 100vh
			min-height 640px
			min-height 750px
			&.bg
				background url(/assets/images/top/bg-mv.jpg) center center no-repeat
				background-size cover
			video
				width 100%
				height 100%
				object-fit cover
		&__title
			position absolute
			top 50%
			left 50%
			transform translateY(-50%) translateX(-50%)
			-webkit-transform translateY(-50%) translateX(-50%)
			img
				width 523px
			&-01
				margin-bottom 33px
			&-02
				margin-bottom 26px
			&-03
				margin-bottom 26px
		&__banner
			opacity 0
			position absolute
			bottom 65px
			bottom 45px
			left 0
			width 100%
			ul
				text-align center
				width 876px
				margin 0 auto
				li
					display inline-block
					width 200px
					margin 0 5px
					a
						display block
						border-radius 8px
						box-shadow 0px 0px 4px rgba(0,0,0,0.15);
						transition transform 0.2s ease-out, box-shadow 0.2s ease-out
						&:hover
							position relative
							transform translateY(-5px)
							box-shadow 0px 0px 8px rgba(0,0,0,0.25)
						img
							width 100%
			&__prev
			&__next
				position absolute
				top 20px
				left calc(50% - 438px - 45px)
				a
					overflow hidden
					display block
					width 30px
					height 30px
					img
						width 30px
					&:hover
						img
							position relative
							top -30px
			&__prev
				transform: scale(-1, 1);
			&__next
				left auto
				right calc(50% - 438px - 45px)
			&__pager
				display none

	.top-lead
		opacity 0
		margin-bottom 150px
		&__wrap
			padding-top 183px
		&__title
			position relative
			text-align center
			width 304px
			margin 0 auto 94px
			canvas
				position absolute
				left 50%
				top -115px
				transform translateX(-50%)
			img
				position relative
				z-index 10
				width 304px
		&__text
			text-align center
			p
				font-size 18px
				letter-spacing 0.5px
				font-weight 600
				line-height 46px
				+ p
					padding-top 1.6em
		&__btn
			padding-top 65px
			a
				display block
				width 390px
				height 70px
				background-color #ffe600
				border-radius (70px/2)
				border 3px solid #000
				margin 0 auto
				&:hover
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 18px
					font-weight 600
					line-height 67px
					color #000
					&::after
						content ''
						position absolute
						top calc(50% - 10px)
						right 23px
						width 20px
						height 20px
						background url(/assets/images/common/ico-btn.svg)
						background-size 20px auto

	.top-movie
		padding-bottom 8px //box-shadowが消えるので
		background-color #ffe600
		&__wrap
			padding-top 145px
		&__title
			text-align center
			margin-bottom 75px
			span
				display inline-block
				width 123px
				padding-bottom 20px
				border-bottom 3px solid #000
		&__content
			position relative
			width 820px
			height 470px
			background-color #fff
			border-radius 10px
			padding 10px 0 0 10px
			box-shadow 0px 0px 8px rgba(0,0,0,0.25)
			margin 0 auto
			video
				overflow hidden
				width 800px
				height 450px
				background-color #000
				border-radius 5px
			&__btn
				overflow hidden
				cursor pointer
				position absolute
				z-index 10
				bottom 30px
				right 30px
				width 40px
				height 40px
				img
					position relative
					top 0
					width 40px
				&.active
					img
						top -40px

	.top-feature
		background-color #ffe600
		padding-bottom 150px
		&__wrap
			padding-top 145px
		&__title
			text-align center
			margin-bottom 52px
			span
				display inline-block
				width 190px
				padding-bottom 20px
				border-bottom 3px solid #000
		&__title__02
			text-align center
			margin-bottom 52px
			span
				display inline-block
				width 325px
				padding-bottom 20px
				border-bottom 3px solid #000
		&__lead
			text-align center
			font-size 16px
			font-weight 600
			line-height 34px
			margin-bottom 75px
		&__detail
			&-01
			&-02
			&-03
			&-04
			&-05
			&-new-01
				position relative
				width 960px
				margin 0 auto 150px
				&__pct
					overflow hidden
					border-radius 15px
					box-shadow 0px 0px 8px rgba(0,0,0,0.25)
					img
						width 100%
				&__lead
					position absolute
					font-size 16px
					letter-spacing 0.06em
					font-weight 600
					line-height 32px
					a
						color #ff72a2
						text-decoration underline
						&:hover
							text-decoration none
				&__title
				&__title01
				&__title02
				&__subpct
					position absolute
			&-01
				&__title01
					top 83px
					left 520px
					width 245px
				&__title02
					top 137px
					left 520px
					width 248px
				&__lead
					top 200px
					left 520px
				&__subpct
					top 312px
					left 368px
					width (1244px/2)
					img
						width 100%
			&-02
				&__title01
					top 86px
					left 58px
					width 320px
				&__title02
					top 137px
					left 58px
					width 222px
				&__lead
					top 200px
					left 58px
				&__subpct
					top 362px
					left 170px
					width 370px
					img
						width 100%
			&-03
				&__title
					top 86px
					left 520px
					width 242px
				&__lead
					top 159px
					left 520px
				&__subpct
					top 311px
					left 458px
					width (1064px/2)
					img
						width 100%
			&-04
				margin-bottom 0
				&__title01
					top 126px
					left 58px
					width 415px
				&__title02
					top 178px
					left 58px
					width 220px
				&__lead
					top 253px
					left 58px
			&-05
				&__title01
					top 86px
					left 58px
					width 350px
				&__title02
					top 137px
					left 58px
					width 382px
				&__lead
					top 175px
					left 58px
				&__subpct
					top 332px
					left 20px
					width (1080px/2)
					img
						width 100%
			
			&-new-01
				&__btns
					padding-top 65px
					a
						display block
						width 390px
						height 70px
						background-color #ffe600
						border-radius (70px/2)
						border 3px solid #000
						margin 0 auto
						&:hover
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-pink.png)
							background-size (118px/2) (26px/2)
							animation btnStripe59 2s infinite linear
						span
							position relative
							display block
							text-align center
							width 100%
							height 100%
							font-size 18px
							font-weight 600
							line-height 67px
							color #000
							&::after
								content ''
								position absolute
								top calc(50% - 10px)
								right 23px
								width 20px
								height 20px
								background url(/assets/images/common/ico-btn.svg)
								background-size 20px auto
	.top-about
		background-color #ffe600
		padding-bottom 150px
	.top-school
		background-color #ffe600
		padding-bottom 50px
		&__wrap
			overflow hidden
			//padding-top 150px
			padding-bottom 0px
		&__textarea
			text-align center
			margin-bottom 74px
			&--icon
				width 110px;
				margin 0 auto 15px
				background #fff
				aspect-ratio 1/1
				overflow hidden
				border-radius 100%
				padding 5px 0
				img
					width auto
					height 100%
			p
				font-size 16px
				letter-spacing 0.5px
				font-weight 600
				line-height 32px
			&--btn
				padding-top 25px
				a
					display block
					width 390px
					height 70px
					background-color #ffe600
					border-radius (70px/2)
					border 3px solid #000
					margin 0 auto
					&:hover
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 18px
						font-weight 600
						line-height 67px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 10px)
							right 23px
							width 20px
							height 20px
							background url(/assets/images/common/ico-btn.svg)
							background-size 20px auto

		&__title
			text-align center
			margin-bottom 50px
			span
				display inline-block
				padding-bottom 15px
				border-bottom 3px solid #000
				img
					height 28px
	.top-course
		background-color #ffe600
		padding-bottom 50px
		&__wrap
			overflow hidden
			padding-top 150px
			padding-bottom 0px
		&__title
			text-align center
			margin-bottom 50px
			span
				display inline-block
				padding-bottom 15px
				border-bottom 3px solid #000
				img
					height 28px
		&__lead
			text-align center
			margin-bottom 74px
			p
				font-size 16px
				letter-spacing 0.5px
				font-weight 600
				line-height 32px
		&__list
			max-width 1160px
			margin 0 auto
			+ .top-course__title
				margin-top 90px
			ul
				display flex
				flex-wrap wrap
				justify-content center
				margin 0 -20px
				li
					display flex
					flex-direction column
					position relative
					overflow hidden
					width 360px
					background-color #fff
					box-shadow 0px 0px 4px rgba(0,0,0,0.25)
					border-radius 8px
					margin-right 20px
					margin-left 20px
					margin-bottom 60px
			&__pct
				position relative
				overflow hidden
				width 100%
				height 250px
				margin-bottom 30px
				.wave
					position absolute
					display block
					bottom 0
				> img
					width 360px
					height 250px
					object-fit cover
				&__icon
					position absolute
					top 9px
					left 10px
					img
						height 28px

			&__schooltitle
				text-align center
				font-size 20px
				letter-spacing 0.05em
				font-weight 600
				line-height 38px
				padding 0 0 30px
				border-bottom 1px solid #c0c0c0
				margin 0 25px 30px
			&__title
				text-align center
				font-size 20px
				letter-spacing 0.05em
				font-weight 600
				line-height 38px
				padding 0 25px 28px
				//svg幅設定
				&.socialcreativecourse
					margin-top -15px
					margin-bottom -5px
					img
						width 284px
				&.artdesigncourse
					margin-top -15px
					margin-bottom -5px
					img
						width 228px
				&.programingcreativecourse
					margin-top -15px
					margin-bottom -5px
					img
						width 268px
			&__subtitle
				text-align center
				font-size 20px
				letter-spacing 0.03em
				font-weight 600
				line-height 38px
				padding 28px 0 22px
				margin 0 25px
				border-top 1px solid #c0c0c0
			&__text
				font-size 16px
				letter-spacing 0.05em
				font-weight 600
				line-height 32px
				padding 0 25px 50px
				flex-grow: 1;
				> span
					color red
			&__btn
				// position absolute
				display flex
				justify-content space-between
				// bottom 24px
				// left 0
				width 100%
				margin-bottom: 30px;

				> span
					display block
					width 312px
					height 60px
					text-align center
					font-size 16px
					letter-spacing 0.08em
					font-weight 600
					line-height 60px
					background-color #ffe600
					border-radius 30px
					margin 0 auto
				> a
					display block
					width 312px
					height 60px
					background-color #ffe600
					border 3px solid #000
					border-radius 30px
					margin 0 auto
					&:hover
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-blue.png)
						background-size (168px/2) (60px/2)
						animation btnStripe84 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 16px
						letter-spacing 0.08em
						font-weight 600
						line-height 53px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 10px)
							right 23px
							width 20px
							height 20px
							background url(/assets/images/common/ico-btn.svg)
							background-size 20px auto
				//トップ用ボタン
				&-single
				&-double
					text-align center
					p
						flex-grow 1
						font-size 16px
						font-weight 600
						letter-spacing 0.05em
						margin-bottom 12px
					a
						display block
						width 100%
						height 60px
						background-color #ffe600
						border 3px solid #000
						border-radius 30px
						margin 0 auto
						&:hover
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-green.png)
							background-size (168px/2) (108px/2)
							animation btnStripe84 2s infinite linear
						span
							position relative
							display block
							text-align center
							width 100%
							height 100%
							font-size 16px
							letter-spacing 0.08em
							font-weight 600
							line-height 53px
							color #000
							&::after
								content ''
								position absolute
								top calc(50% - 10px)
								right 23px
								width 20px
								height 20px
								background url(/assets/images/common/ico-btn.svg)
								background-size 20px auto
				&-single
					width 100%
					margin 0 25px
				&-double
					display flex
					flex-direction column
					width 142px
					&:nth-of-type(1)
						margin-left 25px
					&:nth-of-type(2)
						margin-right 25px
				//各スクールトップ用ボタン
				&-2col
					// position absolute
					// bottom 0
					// left 0
					width 100%
					// height 242px
					padding 0 25px
					&-top
						display flex
						flex-wrap wrap
						justify-content space-between
						margin-bottom 10px
						p
							font-size 16px
							font-weight 600
					&-icon
						display flex
						align-items center
						justify-content center
						width 130px
						height 24px
						font-size 12px
						font-weight 600
						color #fff
						border-radius 4px
						&.entry-remain
							background-color #00C864
						&.entry-cancel
							background-color #007038
						&.standby
							background-color #009ffc
						&.end
							background-color #b8b8b8
						.small
							font-size 10px
						&.online
							width auto
					&-bottom
						margin-bottom 30px
						a
							display block
							height 60px
							background-color #ffe600
							border 3px solid #000
							border-radius 30px
							margin 0 auto
							&:hover
								text-decoration none
								background url(/assets/images/common/bg-btn-hover-green.png)
								background-size (168px/2) (108px/2)
								animation btnStripe84 2s infinite linear
							&.blue:hover
								background url(/assets/images/common/bg-btn-hover-blue.png)
								background-size (168px/2) (60px/2)
							span
								position relative
								display block
								text-align center
								width 100%
								height 100%
								font-size 16px
								letter-spacing 0.08em
								font-weight 600
								line-height 53px
								color #000
								&::after
									content ''
									position absolute
									top calc(50% - 10px)
									right 23px
									width 20px
									height 20px
									background url(/assets/images/common/ico-btn.svg)
									background-size 20px auto
				//ホバー青
				a.clrBlue:hover
					background url(/assets/images/common/bg-btn-hover-blue.png)
					background-size (168px/2) (60px/2)
					animation btnStripe84 2s infinite linear

			&__scrollbar
				display none
		&__briefing
			width 360px
			padding 40px 0 100px
			margin 0 auto
			&__title
				position relative
				left -40px
				display flex
				align-items center
				justify-content center
				text-align center
				padding 7px 10px
				width 440px
				height 90px
				font-size 16px
				font-weight 600
				line-height 26px
				background #fff
				box-shadow 0px 0px 4px rgba(0,0,0,0.15)
				border-radius 45px
				margin-bottom 40px
				&::before
					content ""
					position absolute
					top 100%
					left 50%
					margin-left -6px
					border 6px solid transparent
					border-top 6px solid #fff
			&__banner
				a
					display block
					width 360px
					border-radius 14px
					box-shadow 0px 0px 4px rgba(0,0,0,0.15)
					transition transform 0.2s ease-out, box-shadow 0.2s ease-out
					&:hover
						position relative
						transform translateY(-5px)
						box-shadow 0px 0px 8px rgba(0,0,0,0.25)
					img
						width 100%

	.top-news
		background-color: #ffe600;
		padding-bottom 150px
		&__title
			text-align center
			margin-bottom 40px
			img
				height 24px
		&__list
			width 960px
			margin 0 auto
			border-top 1px solid #000
			ul
				li
					border-bottom 1px solid #000
					a
						position relative
						display flex
						flex-wrap wrap
						justify-content space-between
						font-size 16px
						letter-spacing 0.08em
						color #000
						padding 34px 0 32px 18px
						&::after
							content ''
							position absolute
							right 23px
							top calc(50% - 10px)
							width 20px
							height 20px
							background url(/assets/images/common/ico-btn.svg) center top
							background-size 20px auto
						&:hover
							text-decoration none
							&::after
								background url(/assets/images/common/ico-btn.svg) center bottom
								background-size 20px auto
							.top-news__list__title
								color #ff72a2
			&__date
				width 130px
				padding-top 2px
			&__title
				width calc(100% - 130px)
				font-weight 600
				padding-right 60px
		&__btn
			padding-top 65px
			a
				display block
				width 390px
				height 70px
				background-color #ffe600
				border-radius (70px/2)
				border 3px solid #000
				margin 0 auto
				&:hover
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 18px
					font-weight 600
					line-height 67px
					color #000
					&::after
						content ''
						position absolute
						top calc(50% - 10px)
						right 23px
						width 20px
						height 20px
						background url(/assets/images/common/ico-btn.svg)
						background-size 20px auto

	.top-report
		background-color: #ffe600;
		padding-bottom 150px
		&__title
			text-align center
			margin-bottom 40px
			img
				height 30px
		&__list
			width 960px
			margin 0 auto
			border-top 1px solid #000
			ul
				li
					border-bottom 1px solid #000
					a
						position relative
						display flex
						flex-wrap wrap
						align-items center
						justify-content space-between
						font-size 16px
						letter-spacing 0.08em
						color #000
						padding 34px 0 32px 18px
						&::after
							content ''
							position absolute
							right 23px
							top calc(50% - 10px)
							width 20px
							height 20px
							background url(/assets/images/common/ico-btn.svg) center top
							background-size 20px auto
						&:hover
							text-decoration none
							&::after
								background url(/assets/images/common/ico-btn.svg) center bottom
								background-size 20px auto
							.top-report__list__title
								color #ff72a2
			&__thumb
				overflow hidden
				position relative
				display block
				width 100px
				height 100px
				border-radius 8px
				img
					height 100%
					position absolute
					top 50%
					left 50%
					transform translateY(-50%) translateX(-50%)
			&__text
				width 810px
				padding-right 60px
			&__date
				display block
				margin-bottom 15px
			&__title
				font-weight 600

		&__btn
			padding-top 65px
			a
				display block
				width 390px
				height 70px
				background-color #ffe600
				border-radius (70px/2)
				border 3px solid #000
				margin 0 auto
				&:hover
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 18px
					font-weight 600
					line-height 67px
					color #000
					&::after
						content ''
						position absolute
						top calc(50% - 10px)
						right 23px
						width 20px
						height 20px
						background url(/assets/images/common/ico-btn.svg)
						background-size 20px auto


//IE11
@media all and (-ms-high-contrast: none)

	.mv
		&__movie
			video
				width 100%
				height auto
		//SVGの画像大きさ指定
		&__title
			&-01
				img
					height 24px
			&-02
				img
					height 90px
			&-03
				img
					height 90px
			&-04
				img
					height 36px

	.top-course
		.top-course__title
			.schop01
				width 254px
			.schop02
				width 176px


//SP
@media screen and (max-width 768px)

	.intro
		position fixed
		z-index 1000
		top 0
		left 0
		width 100vw
		height 100vh
		&__lead
			visibility hidden
			width 20px
			height auto

	.schop-animation
		position absolute
		overflow hidden
		pointer-events none
		.inner
			position relative
			transition transform 1.5s linear
			canvas
				position absolute
				width 80px
				height 45px


	.schop-animation.schop01
		left 0px
		margin-top -20px
		.inner
			left 5px
			top 20px
			transform-origin right top
			transform rotateZ(100deg)
			canvas
				right -6px
				bottom -2px
		&.show
			.inner
				transform rotateZ(45deg)
		img
			width 167px
			height 167px

	.schop-animation.schop02
		margin-top -85px
		right 0
		.inner
			top -135px
			right -160px
			transform-origin left top
			transform rotateZ(-30deg)
			canvas
				left -8px
				bottom -1px
		&.show
			.inner
				transform rotateZ(-15deg)
		img
			width 305px
			height 305px

	.schop-animation.schop03
		margin-top -60px
		right 0
		.inner
			top -135px
			right -150px
			transform-origin left top
			transform rotateZ(-35deg)
			canvas
				left -8px
				bottom -1px
		&.show
			.inner
				transform rotateZ(-15deg)
		img
			width 305px
			height 305px

	.schop-animation.schop04
		margin-top -230px
		left 0
		.inner
			left -100px
			transform-origin right top
			transform rotateZ(60deg)
			canvas
				right -6px
				bottom -2px
		&.show
			.inner
				transform rotateZ(29deg)
		img
			width 331px
			height 331px



	.schop-animation.schop05
		margin-top -90px
		right 0
		.inner
			top -135px
			right -150px
			transform-origin left top
			transform rotateZ(-30deg)
			canvas
				left -8px
				bottom -1px
		&.show
			.inner
				transform rotateZ(-15deg)
		img
			width 305px
			height 305px


	.cookie
		position fixed
		z-index 20
		bottom 0
		left 0
		width 100vw
		background #ffe600
		visibility hidden
		transform translateY(140px)
		transition transform 0.25s ease-out, visibility 0.25s
		&.open
			visibility visible
			transform translateY(0px)
		&__text
			padding 35px 30px
			p
				font-size 10px
				font-weight 600
				line-height 21px
				a
					color #ff72a2
					border-bottom 2px solid #ff72a2
		&__closebtn
			position absolute
			top 0
			right 0
			width 38px
			a
				display block
				padding 11px
				img
					width 100%

	.mv
		&__wrap
			position relative
			width 100vw
			height calc(100vw + 140px)
		&__movie.loading
				background-image none
				background-color #ffe600
		&__movie
			overflow hidden
			position absolute
			top 0
			left 0
			width 100vw
			height 100vw
			background url(/assets/images/top/bg-mv--sp.jpg) center center no-repeat
			background-size cover
			video
				width 100%
				height 100%
				object-fit cover
		&__title
			position absolute
			top 50%
			left 50%
			width 100%
			text-align center
			transform translateY(-90%) translateX(-50%)
			img
				width 77%
			&-01
				margin-bottom 14px
			&-02
				margin-bottom 11px
			&-03
				margin-bottom 13px
		&__banner
			overflow hidden
			opacity 0
			position absolute
			bottom 0
			width 100%
			> ul
				height 105px
				//ひとまず幅適当に
				width 5000px
				padding-top 4px
				padding-left 15px
				li
					float left
					width 315px
					margin 0 7px
					img
						width 100%
					a
						display block
						overflow hidden
						border-radius 10px
					a:active
						position relative
						top -2px
			&__prev
			&__next
				display none
			&__pager
				opacity 0
				ul
					text-align center
					padding-top 4px
					li
						display inline-block
						margin 0 2px
						a
							display block
							text-indent -9999px
							width 12px
							height 12px
							border 2px solid #000
							background-color #fff
							border-radius 6px
						&.current
							a
								background-color #000


	.top-lead
		opacity 0
		margin-bottom 75px
		&__wrap
			padding-top 104px
		&__title
			position relative
			text-align center
			width 196px
			margin 0 auto 55px
			canvas
				position absolute
				left 50%
				top -80px
				transform scaleX(0.7) scaleY(0.7) translateX(-70%)
			img
				position relative
				z-index 10
				width 196px
		&__text
			text-align center
			p
				font-size 12px
				letter-spacing 0.5px
				font-weight 600
				line-height 27px
				+ p
					padding-top 1.6em
		&__btn
			padding-top 62px
			a
				display block
				width 84%
				height 60px
				background-color #ffe600
				border-radius (60px/2)
				border 2px solid #000
				margin 0 auto
				&:active
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 15px
					font-weight 600
					line-height 56px
					color #000
					&::after
						content ''
						position absolute
						top calc(50% - 12px)
						right 18px
						width 24px
						height 24px
						background url(/assets/images/common/ico-btn.svg)
						background-size 24px auto

	.top-movie
		padding-bottom 4px //box-shadowが消えるので
		background-color #ffe600
		&__wrap
			width 84%
			padding 75px 0 0
			margin 0 auto
		&__title
			text-align center
			margin-bottom 40px
			span
				display inline-block
				padding-bottom 16px
				border-bottom 2px solid #000
				img
					height 19px
		&__content
			position relative
			width 100%
			background-color #fff
			border-radius 5px
			padding 5px 5px 1px 5px
			box-shadow 0px 0px 4px rgba(0,0,0,0.25)
			video
				overflow hidden
				width 100%
				height auto
				padding 8px 0
				background-color #000
				border-radius 5px
			&__btn
				overflow hidden
				position absolute
				z-index 10
				bottom 10px
				right 10px
				width 30px
				height 30px
				img
					position relative
					top 0
					width 30px
				&.active
					img
						top -30px !important

	.top-feature
		background-color #ffe600
		padding-bottom 90px
		&__wrap
			width 84%
			padding 75px 0 0
			margin 0 auto
		&__title
			text-align center
			margin-bottom 35px
			span
				display inline-block
				padding-bottom 16px
				border-bottom 2px solid #000
				img
					height 19px
		&__title__02
			text-align center
			margin-bottom 35px
			span
				display inline-block
				padding-bottom 16px
				border-bottom 2px solid #000
				img
					height 19px
		&__lead
			text-align center
			font-size 12px
			font-weight 600
			line-height 24px
			margin-bottom 28px
		&__detail
			&-01
			&-02
			&-03
			&-04
			&-05
				position relative
				margin 0 auto 130px
				background-color #ffffff
				border-radius 6px
				box-shadow 0px 0px 4px rgba(0,0,0,0.25)
				&__pct
					overflow hidden
					margin-bottom 30px
					border-top-left-radius 6px
					border-top-right-radius 6px
					img
						width 100%
				&__lead
					font-size 12px
					letter-spacing 0.06em
					font-weight 600
					line-height 24px
					padding 10px 20px 0
					a
						color #ff72a2
						text-decoration underline
						&:active
							text-decoration none
				&__title
				&__title01
				&__title02
					text-align center
					margin-bottom 15px
					img
						height 19px
				&__subpct
					position absolute
			&-01
				padding-bottom 96%
				&__subpct
					bottom -7%
					right 10.5%
					width 77%
					text-align right
					img
						width 100%
						max-width 580px
			&-02
				padding-bottom 40%
				&__subpct
					bottom -13%
					left 7%
					width 86%
					text-align center
					img
						width 100%
						max-width 450px
			&-03
				padding-bottom 100px
				&__subpct
					bottom -19%
					left -5.5%
					width 111%
					text-align center
					img
						width 100%
						max-width 450px
			&-04
				padding-bottom 40px
				margin-bottom 0
			&-05
				padding-bottom 20px
				&__subpct
					bottom -11%
					right -5.5%
					width 103%
					text-align right
					img
						width 100%
						max-width 580px
			&-new-01
				position relative
				margin 0 auto 70px
				&__pct
					overflow hidden
					border-top-left-radius 6px
					border-top-right-radius 6px
					border-radius 6px
					background-color #ffffff
					box-shadow 0px 0px 4px rgba(0,0,0,0.25)
					img
						width 100%
				&__btns
					padding-top 40px
					a
						display block
						width 100%
						max-width 600px
						height 60px
						background-color #ffe600
						border-radius (60px/2)
						border 2px solid #000
						margin 0 auto
						&:active
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-pink.png)
							background-size (118px/2) (26px/2)
							animation btnStripe59 2s infinite linear
						span
							position relative
							display block
							text-align center
							width 100%
							height 100%
							font-size 15px
							font-weight 600
							line-height 56px
							color #000
							&::after
								content ''
								position absolute
								top calc(50% - 12px)
								right 18px
								width 24px
								height 24px
								background url(/assets/images/common/ico-btn.svg)
								background-size 24px auto
	.top-school
		background-color #ffe600
		padding-bottom 90px
		&__wrap
			padding 75px 0 0
			margin 0 auto
		&__title
			text-align center
			margin-bottom 35px
			span
				display inline-block
				padding-bottom 16px
				border-bottom 2px solid #000
				img
					height 19px
		&__textarea
			text-align center
			margin-bottom 74px
			&--icon
				width 80px;
				margin 0 auto 15px
				background #fff
				aspect-ratio 1/1
				overflow hidden
				border-radius 100%
				padding 5px 0
				img
					width auto
					height 100%
			p
				text-align center
				font-size 12px
				font-weight 600
				line-height 24px
				margin-bottom 28px
			&--btn
				a
					display block
					width 84%
					height 60px
					background-color #ffe600
					border-radius (60px/2)
					border 2px solid #000
					margin 0 auto
					&:active
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 15px
						font-weight 600
						line-height 56px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 12px)
							right 18px
							width 24px
							height 24px
							background url(/assets/images/common/ico-btn.svg)
							background-size 24px auto

	.top-course
		background-color #ffe600
		padding-bottom 75px
		&__wrap
			padding-top 75px
			padding-bottom 0px
		&__title
			text-align center
			margin-bottom 34px
			span
				display inline-block
				padding-bottom 15px
				border-bottom 2px solid #000
				img
					height 18px
		&__lead
			width 84%
			text-align center
			margin 0 auto 36px
			p
				font-size 12px
				letter-spacing 0.5px
				font-weight 600
				line-height 24px
		&__list
			position relative
			overflow-x hidden
			+ .top-course__title
				margin-top 72px
			ul
				display flex
				flex-wrap nowrap
				//リストの幅設定（ひとまず12件MAXで）
				width calc((315px + 15px) * 12)
				padding-left calc((100% - 315px) / 2)
				li
					position relative
					overflow hidden
					width 315px
					background-color #fff
					box-shadow 0px 0px 2px rgba(0,0,0,0.2)
					border-radius 8px
					padding-bottom 100px
					margin-right 15px
					margin-bottom 25px
			&__pct
				position relative
				overflow hidden
				height 190px
				margin-bottom 22px
				.wave
					display block
					position absolute
					bottom -2px
					left 0
				// &::after
				// 	content ''
				// 	position absolute
				// 	bottom 0
				// 	left 0
				// 	width 100%
				// 	height 6px
				// 	background url(/assets/images/common/mask-wave.png)
				// 	background-size 30px 6px
				> img
					width 100%
					height 190px
					object-fit cover
				&__icon
					position absolute
					top 10px
					left 10px
					img
						height 24px

			&__schooltitle
				text-align center
				font-size 17px
				letter-spacing 0.05em
				font-weight 600
				line-height 25px
				padding 0 0 22px
				border-bottom 1px solid #c0c0c0
				margin 0 20px 18px
			&__title
				text-align center
				font-size 17px
				letter-spacing 0.05em
				font-weight 600
				line-height 25px
				padding 0 20px 16px
				//svg幅設定
				//&.socialcreativecourse
				//&.artdesigncourse
				//&.programingcreativecourse
				//	margin-top -7px
				//	margin-bottom -3px
				img
					width 100%
					height auto
			&__subtitle
				text-align center
				font-size 17px
				font-weight 600
				line-height 25px
				padding 18px 0 12px
				margin 0 20px
				border-top 1px solid #c0c0c0
			&__text
				font-size 12px
				font-weight 600
				line-height 24px
				padding 0 20px
				margin-bottom 25px
				>span
					color red

			&__btn
				position absolute
				display flex
				justify-content space-between
				bottom 24px
				left 0
				width 100%
				padding 0 20px
				> span
					display block
					width 100%
					height 45px
					text-align center
					font-size 13px
					letter-spacing 0.08em
					font-weight 600
					line-height 44px
					background-color #ffe600
					border-radius 23px
				> a
					display block
					width 100%
					height 45px
					background-color #ffe600
					border 2px solid #000
					border-radius 23px
					&:active
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-blue.png)
						background-size (168px/4) (60px/4)
						animation btnStripe42 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 13px
						letter-spacing 0.08em
						font-weight 600
						line-height 42px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 8px)
							right 13px
							width 17px
							height 17px
							background url(/assets/images/common/ico-btn.svg)
							background-size 17px auto
				&-single
				&-double
					text-align center
					p
						flex-grow 1
						font-size 12px
						font-weight 600
						letter-spacing 0.05em
						margin-bottom 12px
					a
						display block
						width 100%
						height 45px
						background-color #ffe600
						border 2px solid #000
						border-radius 23px
						margin 0 auto
						&:active
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-green.png)
							background-size (168px/4) (108px/4)
							animation btnStripe84 2s infinite linear
						span
							position relative
							display block
							text-align center
							width 100%
							height 100%
							font-size 12px
							letter-spacing 0.08em
							font-weight 600
							line-height 42px
							color #000
							&::after
								content ''
								position absolute
								top calc(50% - 8px)
								right 13px
								width 17px
								height 17px
								background url(/assets/images/common/ico-btn.svg)
								background-size 17px auto
				&-single
					width 100%
				&-double
					display flex
					flex-direction column
					width 122px
				//各スクールトップ用ボタン
				&-2col
					width 100%
					padding 0 20px
					&-top
						display flex
						flex-wrap wrap
						justify-content space-between
						p
							font-size 12px
							font-weight 600
							margin-bottom 10px
					&-icon
						display flex
						align-items center
						justify-content center
						width 141px
						height 20px
						font-size 10px
						line-height 1
						font-weight 600
						color #fff
						border-radius 5px
						margin-bottom 10px
						margin-left auto  // 右寄せにする
						&.entry-remain
							background-color #00C864
						&.entry-cancel
							background-color #007038
						&.standby
							background-color #009ffc
						&.end
							background-color #b8b8b8
						.small
							font-size 8px
					&-bottom
						margin-bottom 25px
						a
							display block
							height 45px
							background-color #ffe600
							border 2px solid #000
							border-radius 23px
							margin 0 auto
							&:active
								text-decoration none
								background url(/assets/images/common/bg-btn-hover-green.png)
								background-size (168px/4) (108px/4)
								animation btnStripe84 2s infinite linear
							&.blue:active
								background url(/assets/images/common/bg-btn-hover-blue.png)
								background-size (168px/4) (60px/4)
							span
								position relative
								display block
								text-align center
								width 100%
								height 100%
								font-size 12px
								letter-spacing 0.08em
								font-weight 600
								line-height 42px
								color #000
								&::after
									content ''
									position absolute
									top calc(50% - 8px)
									right 13px
									width 17px
									height 17px
									background url(/assets/images/common/ico-btn.svg)
									background-size 17px auto
				//ホバー青
				a.clrBlue:active
					background url(/assets/images/common/bg-btn-hover-blue.png)
					background-size (168px/4) (60px/4)
					animation btnStripe42 2s infinite linear

			&__scrollbar
				height 6px
				margin 0 30px
				background-color #c1c1c1
				border-radius 3px
				&-cursor
					width 33% //ひとまず幅設定
					height 6px
					background-color #000
					border-radius 3px
		&__briefing
			width 84%
			padding 40px 0 0
			margin 0 auto
			+ .top-course__title
				margin-top 33px
			&__title
				position relative
				display flex
				align-items center
				justify-content center
				text-align center
				padding 7px 10px
				width 100%
				height 65px
				font-size 12px
				font-weight 600
				line-height 21px
				background #fff
				box-shadow 0px 0px 2px rgba(0,0,0,0.2)
				border-radius 33px
				margin 0 auto 28px
				&::before
					content ""
					position absolute
					top 100%
					left 50%
					margin-left -6px
					border 6px solid transparent
					border-top 6px solid #fff
			&__banner
				a
					display block
					width 100%
					border-radius 4px
					transition transform 0.2s ease-out, box-shadow 0.2s ease-out
					&:active
						position relative
						transform translateY(-3px)
					img
						width 100%

	.top-news
		background-color: #ffe600;
		padding-bottom 90px
		&__title
			text-align center
			margin-bottom 20px
			img
				height 16px
		&__list
			border-top 1px solid #000
			margin 0 30px
			ul
				li
					border-bottom 1px solid #000
					a
						display block
						position relative
						letter-spacing 0.08em
						color #000
						padding 13px 30px 9px 0
						&::after
							content ''
							position absolute
							right 0
							top calc(50% - 6px)
							width 13px
							height 13px
							background url(/assets/images/common/ico-btn.svg) center top
							background-size 13px auto
						&:hover
							text-decoration none
						&:active
							text-decoration none
							&::after
								background url(/assets/images/common/ico-btn.svg) center bottom
								background-size 13px auto
							.top-news__list__title
								color #ff72a2
			&__date
				display block
				font-size 9px
				margin-bottom 3px
			&__title
				display block
				font-size 12px
				line-height 24px
				font-weight 600
		&__btn
			padding-top 40px
			a
				display block
				width 84%
				height 60px
				background-color #ffe600
				border-radius (60px/2)
				border 2px solid #000
				margin 0 auto
				&:active
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 15px
					font-weight 600
					line-height 56px
					color #000
					&::after
						content ''
						position absolute
						top calc(50% - 12px)
						right 18px
						width 24px
						height 24px
						background url(/assets/images/common/ico-btn.svg)
						background-size 24px auto

	.top-report
		background-color: #ffe600;
		padding-bottom 90px
		&__title
			text-align center
			margin-bottom 20px
			img
				height 20px
		&__list
			border-top 1px solid #000
			margin 0 30px
			ul
				li
					border-bottom 1px solid #000
					a
						position relative
						display flex
						flex-wrap wrap
						align-items center
						justify-content space-between
						letter-spacing 0.08em
						color #000
						padding 15px 30px 11px 0
						&::after
							content ''
							position absolute
							right 0
							top calc(50% - 6px)
							width 13px
							height 13px
							background url(/assets/images/common/ico-btn.svg) center top
							background-size 13px auto
						&:hover
							text-decoration none
						&:active
							text-decoration none
							&::after
								background url(/assets/images/common/ico-btn.svg) center bottom
								background-size 13px auto
							.top-report__list__title
								color #ff72a2
			&__thumb
				overflow hidden
				position relative
				display block
				width 60px
				height 60px
				border-radius 4px
				img
					height 100%
					position absolute
					top 50%
					left 50%
					transform translateY(-50%) translateX(-50%)
			&__text
				width calc(100% - 70px)
			&__date
				display block
				font-size 9px
				margin-bottom 3px
			&__title
				display block
				font-size 12px
				line-height 24px
				font-weight 600
		&__btn
			padding-top 40px
			a
				display block
				width 84%
				height 60px
				background-color #ffe600
				border-radius (60px/2)
				border 2px solid #000
				margin 0 auto
				&:active
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 15px
					font-weight 600
					line-height 56px
					color #000
					&::after
						content ''
						position absolute
						top calc(50% - 12px)
						right 18px
						width 24px
						height 24px
						background url(/assets/images/common/ico-btn.svg)
						background-size 24px auto


//SP　狭い場合
@media screen and (max-width 333px)

	.schop-animation.schop03
		img
			width 310px
			height 310px

	.top-course__briefing__title
		br
			display none