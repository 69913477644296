//PC
@media screen and (min-width 769px)

	.faq

		&-pagelink
			width 960px
			margin 0 auto 80px
			&__list
				ul
					display flex
					flex-wrap wrap
					justify-content space-between
					margin-top -20px
					li
						width 225px
						margin-top 20px
						a
							display block
							width 225px
							height 50px
							background-color #ffe600
							border-radius (50px/2)
							border 3px solid #000
							margin 0 auto
							margin-top 20px
							&:hover
								text-decoration none
								background url(/assets/images/common/bg-btn-hover-pink.png)
								background-size (118px/2) (26px/2)
								animation btnStripe59 2s infinite linear
							span
								position relative
								display block
								text-align center
								width 100%
								height 100%
								font-size 18px
								font-weight 600
								line-height 47px
								color #000
								&::after
									content ''
									position absolute
									top calc(50% - 10px)
									right 12px
									width 20px
									height 20px
									background url(/assets/images/common/ico-btn-bottom.svg)
									background-size 20px auto

		&-list
			margin-bottom 150px
			&__wrap
				width 960px
				background-color #fff
				border-radius 16px
				padding 70px 80px 50px
				box-shadow 0px 0px 4px rgba(0,0,0,0.15)
				margin 0 auto 148px
			&__title
				font-size 24px
				font-weight 600
				padding-bottom 34px
				border-bottom 1px solid #cacaca
			&__detail
				letter-spacing 0.05em
				&__q
					min-height 52px
					font-size 20px
					font-weight 600
					line-height 38px
					padding 8px 70px 0 70px
					background url(/assets/images/faq/ico-q.svg) left top no-repeat
					background-size 52px auto
				&__a
					font-size 16px
					font-weight 600
					line-height 32px
					color #666666
					padding 0 0 32px 0
					a
						color #ff72a2
						text-decoration underline
				dl
					border-bottom 1px solid #cacaca
					&:nth-last-of-type(1)
						border-bottom 0px
					&.selected
						dt::after
							transform rotate(45deg)
						dd
							max-height 10rem
					dt
						position relative
						cursor pointer
						padding 32px 0
						&::after
							content ""
							position absolute
							top 47px
							right 10px
							width 22px
							height 22px
							background url(/assets/images/faq/ico-plus.svg)
							background-size 22px auto
							pointer-events none
							transition transform 0.25s
					dd
						display block
						margin-top -5px
						max-height: 0
						overflow hidden
						transition max-height 0.25s ease-in-out


//SP
@media screen and (max-width 768px)

	.faq
		&__wrap
			width 84%
			background-color #fff
			border-radius 8px
			box-shadow 0px 0px 2px rgba(0,0,0,0.15)
			margin 0 auto

		&-pagelink
			width 84%
			margin 0 auto
			&__list
				padding-bottom 15px
				ul
					display flex
					flex-wrap wrap
					justify-content space-between
					li
						width 48.4%
						margin-bottom 15px
						a
							display block
							width 100%
							height 30px
							background-color #ffe600
							border-radius (30px/2)
							border 2px solid #000
							margin 0 auto
							&:active
								text-decoration none
								background url(/assets/images/common/bg-btn-hover-pink.png)
								background-size (118px/2) (26px/2)
								animation btnStripe59 2s infinite linear
							span
								position relative
								display block
								text-align center
								width 100%
								height 100%
								font-size 12px
								font-weight 600
								line-height 28px
								color #000
								&::after
									content ''
									position absolute
									top calc(50% - 6px)
									right 7px
									width 13px
									height 13px
									background url(/assets/images/common/ico-btn-bottom.svg)
									background-size 13px 13px

		&-list
			margin-bottom 75px
			&__wrap
				width 84%
				background-color #fff
				box-shadow 0px 0px 2px rgba(0,0,0,0.15);
				border-radius 8px
				padding 20px 20px 12px
				margin 0 auto 25px
			&__title
				font-size 17px
				font-weight 600
				padding-bottom 17px
				border-bottom 1px solid #cacaca
			&__detail
				letter-spacing 0.05em
				&__q
					min-height 30px
					font-size 12px
					font-weight 600
					line-height 24px
					padding 4px 30px 0 40px
					background url(/assets/images/faq/ico-q.svg) left top no-repeat
					background-size 30px auto
				&__a
					font-size 12px
					font-weight 600
					line-height 24px
					color #666666
					padding 0 0 15px 0
					a
						color #ff72a2
						text-decoration underline
				dl
					border-bottom 1px solid #cacaca
					&:nth-last-of-type(1)
						border-bottom 0px
					&.selected
						dt::after
							transform rotate(45deg)
						dd
							max-height 10rem
					dt
						position relative
						cursor pointer
						padding 13px 0 18px
						&::after
							content ""
							position absolute
							top 24px
							right 5px
							width 11px
							height 11px
							background url(/assets/images/faq/ico-plus.svg)
							background-size 11px auto
							pointer-events none
							transition transform 0.25s
					dd
						margin-top -5px
						max-height: 0
						overflow hidden
						transition max-height 0.25s ease-in-out