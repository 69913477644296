//PC
//@media screen and (min-width 769px)
	.header__title
		.trial
			margin-bottom 20px
		&__lead
			font-weight bold
	.trialindex
		padding-bottom 100px
		font-size 1rem
		&__list
			margin auto
			max-width 1080px
			width 90%
			background #fff
			border-radius 30px
			padding 50px 5%
			&__block + &__block
				margin-top 5rem
			&__block
				.blockttl
					text-align center
					font-size 150%
					font-weight bold
					position relative
					margin-bottom 20px
					span
						display inline-flex
						border 1px solid #000
						border-radius 1000px
						height 2em
						width 8em
						justify-content center
						align-items center
						background #fff
						position relative
						z-index 1
				.blockttl:before
					content ""
					display block
					position absolute
					width 100%
					height 1px
					background #000
					top 50%
					left 0
				table
					width calc(100% - 40px)
					margin auto
					font-size 137.5%
					font-weight bold
					thead
						th
							padding 10px
							border-bottom 1px solid
						th:first-child
							width 33%
						th:nth-child(2)
							width 33%
						th:nth-child(3)
							width 33%
					tbody
						td
							padding 20px 10px
							text-align center
					.trail__btn a
							width 100%
							width 263px
							height 50px
							background-color #ffe600
							border-radius (50px/2)
							border 3px solid #000
							margin 0 auto
							display flex
							align-items center
							justify-content center
							&:hover
								text-decoration none
								background url(/assets/images/common/bg-btn-hover-pink.png)
								background-size (118px/2) (26px/2)
								animation btnStripe59 2s infinite linear
							span
								position relative
								display block
								text-align center
								width 100%
								font-size 14px
								font-weight 600
								color #000
								&::after
									content ''
									position absolute
									top calc(50% - 10px)
									right 23px
									width 20px
									height 20px
									background url(/assets/images/common/ico-btn.svg)
									background-size 20px auto

//SP
@media screen and (max-width 768px)
	.trialindex
		font-size 0.75rem
		&__list
			&__block
				table
					width calc(100% - 40px)
					margin auto
					font-size 14px
					font-weight bold
					tr 
						display flex
						flex-wrap wrap
					thead
						th
							padding 5px
						th:first-child
							width 60%
						th:nth-child(2)
							width 40%
						th:nth-child(3) 
							display none
						th:nth-child(3)
							width 100%
					tbody
						tr
							padding 5px 0
						tr:not(:first-child)
							border-top 1px dotted #ccc
						td
							padding 15px 5px
						td:first-child
							width 60%
						td:nth-child(2)
							width 40%
						td:nth-child(3)
							padding 0 5px 15px
							width 100%
					.trail__btn a
							width 100%
							width 263px
							height 3em
							background-color #ffe600
							border-radius (3em/2)
							border 3px solid #000
							margin 0 auto
							display flex
							align-items center
							justify-content center
							&:hover
								text-decoration none
								background url(/assets/images/common/bg-btn-hover-pink.png)
								background-size (118px/2) (26px/2)
								animation btnStripe59 2s infinite linear
							span
								position relative
								display block
								text-align center
								width 100%
								font-size 14px
								font-weight 600
								color #000
								&::after
									content ''
									position absolute
									top calc(50% - 10px)
									right 23px
									width 20px
									height 20px
									background url(/assets/images/common/ico-btn.svg)
									background-size 20px auto

