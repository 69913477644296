//PC
@media screen and (min-width 769px)

	.policy
		&__wrap
			width 960px
			background-color #fff
			box-shadow 0px 0px 4px rgba(0,0,0,0.15);
			border-radius 16px
			margin 0 auto 162px
		&__detail
			padding 70px 78px 90px
			&__title
				font-size 20px
				margin-bottom 40px
			&__text
				font-size 16px
				font-weight 600
				line-height 38px
				.indent
					display block
					text-indent: -1.0rem
					padding-left 1.4rem
				&-caution
					color #666666
					font-size 16px
					font-weight 600
					line-height 38px
					+ .policy__detail__text
						padding-top 2.4em
				+ .policy__detail__text
					padding-top 2.4em
				+ .policy__detail__text-caution
					padding-top 2.4em
				+ .policy__detail__title
					padding-top 2.2em
				a
					overflow visible
					position relative
					display inline-block
					line-height 1.4
					color #ff72a2
					letter-spacing 0.05em
					border-bottom 2px solid #ff72a2
					&:hover
						text-decoration none
						border-bottom 2px solid #fff
					&[target="_blank"]::after
						content ''
						overflow hidden
						position absolute
						right -25px
						top 7px
						width 19px
						height 16px
						background url(/assets/images/common/ico-blank.svg) center top
						background-size 19px 32px
	
	.law
		&__wrap
			width 960px
			padding 55px 0 45px
			background-color #fff
			box-shadow 0px 0px 4px rgba(0,0,0,0.15);
			border-radius 16px
			margin 0 auto 162px
		&__detail
			width 800px
			margin 0 auto
			table
				width 100%
				font-size 18px
				letter-spacing 0.03em
				font-weight 600
				line-height 38px
				small
					font-size 18px
					color #666666
				tr
					&:nth-last-of-type(1)
						th
						td
							border-color #fff
					th
						text-align left
						width 170px
						vertical-align top
						padding-top 21px
						padding-left 10px
						padding-right 20px
						padding-bottom 22px
						border-bottom 1px solid #878787
					td
						width 630px
						vertical-align top
						padding-top 21px
						padding-bottom 22px
						border-bottom 1px solid #878787
						a
							overflow visible
							position relative
							display inline-block
							line-height 1.5
							color #ff72a2
							letter-spacing 0.05em
							border-bottom 2px solid #ff72a2
							&[target="_blank"]::after
								content ''
								overflow hidden
								position absolute
								right -25px
								top 5px
								width 19px
								height 16px
								background url(/assets/images/common/ico-blank.svg) center top
								background-size 19px 32px
							&:hover
								text-decoration none
								border-bottom 2px solid #ffffff
	.terms
		&__wrap
			width 960px
			background-color #fff
			box-shadow 0px 0px 4px rgba(0,0,0,0.15);
			border-radius 16px
			margin 0 auto 162px
		&__detail
			padding 70px 78px 90px
			&__title
				font-size 20px
				margin-bottom 40px
				padding-top 2.4em
			&__text
				font-size 16px
				font-weight 600
				line-height 38px
				&-line
					font-size 16px
					font-weight 600
					line-height 38px
					padding-top 1.0em
					strong
						color: #ff0000
					+ .terms
						padding-top 2.4em
				+ .terms__detail__text
					padding-top 2.4em
				+ .terms__detail__text-line
					padding-top 1.0em
				+ .terms__detail__title
					padding-top 2.2em

				a
					overflow visible
					position relative
					display inline-block
					line-height 1.4
					color #ff72a2
					letter-spacing 0.05em
					border-bottom 2px solid #ff72a2
					&:hover
						text-decoration none
						border-bottom 2px solid #fff
					&[target="_blank"]::after
						content ''
						overflow hidden
						position absolute
						right -25px
						top 7px
						width 19px
						height 16px
						background url(/assets/images/common/ico-blank.svg) center top
						background-size 19px 32px


//SP
@media screen and (max-width 768px)

	.policy
		&__wrap
			width 84%
			background-color #fff
			box-shadow 0px 0px 2px rgba(0,0,0,0.15);
			border-radius 8px
			margin 0 auto 80px
		&__detail
			padding 35px 24px 40px
			&__title
				font-size 13px
				line-height 24px
				margin-bottom 25px
			&__text
				font-size 12px
				font-weight 600
				line-height 24px
				&-caution
					color #666666
					font-size 12px
					font-weight 600
					line-height 24px
					+ .policy__detail__text
						padding-top 2.4em
				+ .policy__detail__text
					padding-top 2.4em
				+ .policy__detail__text-caution
					padding-top 2.4em
				+ .policy__detail__title
					padding-top 2.2em
				a
					position relative
					overflow visible
					display inline
					color #ff72a2
					letter-spacing 0.05em
					border-bottom 1px solid #ff72a2
					&:active
						text-decoration none
						border-bottom 1px solid #fff
					&[target="_blank"]::after
						position absolute
						bottom 4px
						right -13px
						content ""
						overflow hidden
						display inline-block
						width 10px
						height 8px
						background url(/assets/images/common/ico-blank.svg)
						background-size 10px 16px

	.law
		&__wrap
			width 84%
			padding 25px 0
			background-color #fff
			box-shadow 0px 0px 2px rgba(0,0,0,0.15);
			border-radius 8px
			margin 40px auto 80px
		&__detail
			margin 0 20px
			table
				width 100%
				font-size 12px
				font-weight 600
				line-height 24px
				tr
					&:nth-last-of-type(1)
						th
						td
							border-color #fff
					th
						width 80px
						text-align left
						vertical-align top
						padding-top 10px
						padding-bottom 11px
						border-bottom 1px solid #a6a6a6
					td
						vertical-align top
						padding-top 10px
						padding-bottom 11px
						border-bottom 1px solid #a6a6a6
						a
							position relative
							overflow visible
							display inline
							color #ff72a2
							letter-spacing 0.05em
							border-bottom 1px solid #ff72a2
							&:active
								text-decoration none
								border-bottom 1px solid #fff
							&[target="_blank"]::after
								position absolute
								bottom 4px
								right -13px
								content ""
								overflow hidden
								display inline-block
								width 10px
								height 8px
								background url(/assets/images/common/ico-blank.svg) center top
								background-size 10px 16px

	.terms
		&__wrap
			width 84%
			background-color #fff
			box-shadow 0px 0px 2px rgba(0,0,0,0.15);
			border-radius 8px
			margin 0 auto 80px
		&__detail
			padding 35px 24px 40px
			&__title
				font-size 13px
				line-height 24px
				margin-bottom 25px
				padding-top 2.4em
			&__text
				font-size 12px
				font-weight 600
				line-height 24px
				&-line
					font-size 12px
					font-weight 600
					line-height 24px
					padding-top 1.0em
					+ .terms__detail__text
						padding-top 2.4em
				+ .terms__detail__text
					padding-top 2.4em
				+ .terms__detail__text-line
					padding-top 1.0em
				+ .terms__detail__title
					padding-top 2.2em
				a
					position relative
					overflow visible
					display inline
					color #ff72a2
					letter-spacing 0.05em
					border-bottom 1px solid #ff72a2
					&:active
						text-decoration none
						border-bottom 1px solid #fff
					&[target="_blank"]::after
						position absolute
						bottom 4px
						right -13px
						content ""
						overflow hidden
						display inline-block
						width 10px
						height 8px
						background url(/assets/images/common/ico-blank.svg)
						background-size 10px 16px