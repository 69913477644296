.report-wrapper
	max-width 640px
	position relative
	left 50%
	transform translateX(-50%)

// // レポート
.report
	padding-bottom  65px  //ナビ部分

	&__head
		position  relative
		background-color  #fff
		&__title
			display  flex
			align-items  center
			justify-content  center
			height  65px
			span
				font-size  17px
				font-weight  600

		&__wave
			position  absolute
			bottom  0
			left  0
			width  100%
			height  6px
			background  url(/assets/images/report/bg-wave-yellow.png)
			background-size  auto 6px

	&__top
		margin-top  25px
		margin-bottom  35px
		&-wrap
			width  84%
			margin  0 auto

		select
			appearance  none
			// NOTE: スマホ時のフォントサイズ変更
			font-size  16px
			font-weight  600
			width  100%
			height  37px
			border  0px
			text-indent  1em
			background  #fff url(/assets/images/report/ico-pulldown.svg) right 14px center no-repeat
			background-size  10px 11px
			border-radius  8px
			border  1px solid #cacaca
			text-overflow  ellipsis
			padding-right  30px

	&__title
		margin-bottom  27px
		&-wrap
			display  flex
			flex-wrap  wrap
			justify-content  space-between
			width  84%
			margin  0 auto

		&-left
			width  calc(100% - 45px)

		&-right
			text-align  right
			width  45px
			padding-top  8px

		&__sub
			font-size  12px
			font-weight  600
			margin-bottom  10px

		&__main
			font-size  17px
			line-height  24px
			font-weight  600

		&__btn
			img
				width  35px


		&__pct
			padding-top  30px
			img
				width  100%
				position relative
				left 50%
				transform translateX(-50%)
				@media screen and (min-width: 640px)
					width 84%

	&__timeline
		padding-bottom  30px
		&-wrap
			width  84%
			margin  0 auto

		&__year
			display  flex
			align-items  center
			justify-content  center
			width  103px
			height  25px
			font-size  12px
			font-weight  600
			background-color  #fff
			border-radius  13px
			box-shadow  0px 0px 4px rgba(0,0,0,0.2)
			margin  0 auto 25px

		&__baseblock
			clear  both
			width  88.9%
			margin-bottom  48px
			&.is_right
				float  right

			&-wrap
				position  relative
				padding-bottom  20px
				background-color  #fff
				border-radius  8px
				box-shadow  0px 0px 4px rgba(0,0,0,0.2)

			&__date
				font-size  10px
				margin-bottom  8px

			&__title
				display  block
				position  relative
				font-size  14px
				line-height  22px
				color  #fff
				font-weight  600
				padding  20px
				background-color  #0da5ff
				border-radius  8px
				margin-bottom  16px
				.is_absence &
					color  #666666
					background-color  #f0f0f0
					&  after
						content  ''
						position  absolute
						z-index  1
						top  -4px
						right  10px
						width  40px
						height  22px
						background  url(/assets/images/report/ico-absence.svg)
						background-size  40px 22px

				&__wave
					position  absolute
					bottom  0
					left  0
					width  100%
					height  6px
					background  url(/assets/images/report/bg-wave-white.png)
					background-size  auto 6px

			&__text
				font-size  12px
				line-height  20px
				padding  0 20px
				+ .report__timeline__baseblock__odai
					margin-top  15px

			&__odai
				display  flex
				justify-content  space-between
				padding  0 20px 18px
				&__icon
					display  block
					width  62px
					height  18px
					font-size  10px
					color  #000
					font-weight  600
					line-height  18px
					padding-left  24px
					background-color  #ffe600
					background-image  url(/assets/images/report/ico-photo-s.svg)
					background-size  13px 11px
					background-repeat  no-repeat
					background-position  8px center
					border-radius  9px

				&__title
					width  calc(100% - 70px)
					font-size  12px
					font-weight  600
					line-height  20px

				&__nophoto
					padding  0 20px 15px
					font-size  12px

			&__pct
				overflow hidden
				width 180px
				border-radius 8px
				margin 0 auto
				position relative  // 0729追記 -----
				// 0729 追記 -----
				&::before
					content ""
					display block
					padding-top 100%
				img
					width 100%
					// 0729 追記 ----
					height 100%
					position absolute
					top 0
					left 0
					right 0
					bottom 0
					object-fit contain
					background-color #FFE600
					// 0729 追記 -----

			&__pctlist
				padding  0 20px
				ul
					display  flex
					flex-wrap  wrap
					margin-bottom  -15px
					li
						overflow  hidden
						width  29.1%
						border-radius  8px
						margin-right  6.3%
						margin-bottom  15px
						position: relative  // 0729追記
						& nth-of-type(3n)
							margin-right  0
						&::before
							content: ""
							display: block
							padding-top: 100%
						img
							width: 100%
							// 0729追記 ----
							height: 100%
							position: absolute
							top: 0
							bottom: 0
							left: 0
							right: 0
							margin: auto
							object-fit: contain
							background-color: #FFE600
							// 0729追記 -----

						img
							width  100%

			hr
				margin  15px 20px
				padding  0
				height  1px
				border  none
				border-bottom  1px solid #cacaca

			&__hanamaru
				position  absolute
				bottom  -18px
				right  -20px
				width  51px
				height  49px
				background-image  url(/assets/images/report/pct-hanamaru.svg)
				background-size  51px 49px
				.is_right &
					right  auto
					left  -25px

		&__trophyget
			clear  both
			text-align  center
			margin-bottom  40px
			&__date
				font-size  10px

			&__pct
				margin-bottom  15px
				img
					width  100%

			&__desc
				font-size  17px
				font-weight  600
				line-height  30px
				color  #000000

		&__message
			clear  both
			margin-bottom  30px
			&-wrap
				display  flex
				flex-wrap  wrap
				justify-content  space-between

			&__thumb
				overflow  hidden
				width  60px
				height  60px
				border-radius  30px
				margin-top  38px
				img
					width  100%

			&__text
				width  74.6%
				&__title
					font-size  10px
					margin 30px 0 8px 0

				&__fukidashi
					position  relative
					background-color  #fff
					border-radius  8px
					box-shadow  0px 0px 4px rgba(0,0,0,0.2)
					word-wrap break-word
					&::before
						content  ""
						position  absolute
						top  35px
						left  -18px
						margin-top  -15px
						border  10px solid transparent
						border-right  10px solid #fff

					span
						display  block
						font-size  12px
						line-height  20px
						padding  15px

	&__pagenation
		position  relative
		background-color  #fff
		&__wave
			position  absolute
			top  -6px
			left  0
			width  100%
			height  6px
			background  url(/assets/images/report/bg-wave-white.png)
			background-size  auto 6px

		&-wrap
			padding  0 10px

		&__next,
		&__prev
			a
				position  relative
				display  flex
				align-items  center
				height  75px
				font-size  12px
				font-weight  600
				line-height  20px
				&  after
					content  ''
					position  absolute
					top  calc(50% - 7px)
					width  14px
					height  14px
					background  url(/assets/images/report/ico-btn.svg)
					background-size  14px auto

		&__next
			+.report__pagenation__prev
				border-top  1px solid #cacaca

			a
				text-align  right
				padding-right  25px
				padding-left  12.6%
				&  after
					right  0px

		&__prev
			a
				padding-left  25px
				padding-right  12.6%
				&  after
					left  0px
					transform  scale(-1, 1)

	&__modal
		position  fixed
		z-index  100
		top  0
		left  0
		display  flex
		align-items  center
		justify-content  center
		width  100%
		height  100vh

		&__bg
			position  absolute
			top  0
			left  0
			display  block
			width  100%
			height  100%
			background-color  rgba(#000000, 0.9) //変数のスコープがないので持ってくる

		&__scroll-pane
			position relative
			top 0
			left 0
			display flex
			flex-wrap nowrap
			justify-content flex-start

		&__pct
			position  relative
			text-align  center
			margin-bottom  10px
			// スマホのとき適用
			@media screen and (max-width 330px)
				margin-top calc(50vh - 50%)
			&__image-wrapper
				min-width: 100%  // flexの折り返しなし
				max-height 330px
				position: relative
				// PCのとき適用
				@media screen and (min-width 330px)
					margin-top calc(calc(100vh - 330px) / 2)
				// 0729追記 -----------------
				&::before
					content: ""
					display: block
					padding-top: 100%
				> img
					width: 100%
					height: 100%
					position: absolute
					top: 0
					left: 0
					right: 0
					bottom: 0
					object-fit: contain
				// 0729追記 -----------------
			&__prev,
			&__next
				position  absolute
				bottom  -42px
				a
					display  flex
					align-items  center
					justify-content  center
					width  32px
					height  32px
					img
						width  11px

			&__prev
				left  0
				transform  scale(-1, 1)

			&__next
				right  0

			&__closebtn
				position  absolute
				bottom  -42px
				left  calc(50% - 16px)
				a
					display  flex
					align-items  center
					justify-content  center
					width  32px
					height  32px
					img
						width  17px


		&__empty
			height  100vh
			height  -webkit-fill-available
			text-align  center
			&-wrap
				position  absolute
				width  100%
				top  50%
				left  50%
				margin-top  -30px
				transform  translateX(-50%) translateY(-50%)

			&__icon
				margin-bottom  28px
				img
					width  (94px / 2)

			&__text
				font-size  12px
				font-weight  600