//PC
@media screen and (min-width 769px)

	.form-edit + .header
		margin-top 125px

	.form
		//編集
		&-edit
			&-wrap
				position relative
				overflow hidden
				width 960px
				padding 80px 80px 70px
				background-color #fff
				border-radius 8px
				box-shadow 0px 0px 4px rgba(0,0,0,0.2)
				margin 0 auto 60px
				+ .form-edit__input-btn
					margin-top 85px
			&__lead
				text-align center
				font-size 16px
				font-weight 600
				letter-spacing 0.05em
				line-height 32px
				margin-top -45px
				margin-bottom 55px
				&-caution
					padding-top 20px
					font-weight 400
					span
						font-family serif
						color #ff0000
			&__closebtn
				position absolute
				top 0
				right 0
				a
					display flex
					align-items center
					justify-content center
					width 90px
					height 90px
					&:hover
						opacity 0.5
					img
						width 34px

			//PCのみ各wrap
			// - 入力箇所を左右に分ける
			&__pcwrap-input2col
				display flex
				flex-wrap wrap
				dl
					width 376px
					&:nth-of-type(2n-1)
						margin-right 45px
						dt
							width 100%

			//★
			&__thumb
				position relative
				margin-bottom 70px
				&__pct
					overflow hidden
					width 150px
					height 150px
					border-radius 50%
					margin 0 auto 20px
					img
						width 100%
				&__edit
					position absolute
					right -2px
					bottom -4px
					width 46px
					img
						width 100%
				&__link
					text-align center
					a
					label
						cursor: pointer
						font-size 16px
						font-weight 600
						color #ff72a2
						text-decoration underline
						padding-left 30px //センター出し
					&-require
						font-family serif
						font-size 16px
						color #ff0000
						font-weight 400
						margin-left 10px
			//★
			&__subtitle
				display flex
				align-items center
				justify-content center
				width 120px
				height 25px
				font-size 14px
				line-height 1.1
				font-weight 600
				background-color #f0f0f0
				border-radius 13px
				margin-top -20px
				margin-bottom 14px
				&-bgyellow
					display flex
					align-items center
					justify-content center
					width 150px
					height 36px
					font-size 16px
					line-height 1.1
					font-weight 600
					background-color #ffe600
					border-radius 18px
					margin -20px auto 40px

			&__input //以下入力エリア
				dl
					margin-bottom 58px
					dt
						display flex
						flex-wrap wrap
						margin-bottom 19px
					dd
						display flex
						flex-wrap wrap
						justify-content space-between
				input[type="text"],
				input[type="email"],
				select
					-webkit-appearance none
					-moz-appearance none
					appearance none
					width 100%
					height 50px
					border 2px solid #cacaca
					font-size 16px
					font-weight 600
					text-indent 14px
					border-radius 6px
					&.error
						border-color #ff0000
				select
					background #fff url(/assets/images/me/ico-pulldown.svg) right 14px center no-repeat
					background-size 16px 14px
				// フォーム選択時の見た目
				input[type="text"],
				input[type="email"]
					&:focus
						border 2px solid #000
					&::selection
						background-color #cacaca
					&::-webkit-input-placeholder
						color #cacaca
					&::-moz-placeholder
						color #cacaca
					&:-ms-input-placeholder
						color #cacaca
				&-title
					font-size 16px
					font-weight 600
					line-height 1.1
				&-require
					position relative
					top 1px
					font-family serif
					font-size 16px
					line-height 1.1
					color #ff0000
					font-weight 400
					margin-left 10px
				//&-require
				//	display flex
				//	align-items center
				//	justify-content center
				//	width 75px
				//	height 25px
				//	font-size 14px
				//	line-height 1.1
				//	font-weight 600
				//	background-color #ffe600
				//	border-radius 6px
				&-postnumber
					display flex
					flex-wrap wrap
					dl
						width 376px
						margin-right 45px
					&-btn
						width 230px
						padding-top 35px
						a
							display block
							width 230px
							height 52px
							background-color #f0f0f0
							border-radius (52px/2)
							border 3px solid #000
							margin 0 auto
							&:hover
								background-color #ff72a2
							span
								position relative
								display block
								text-align center
								width 100%
								height 100%
								font-size 18px
								font-weight 600
								line-height 48px
								color #000
								&::after
									content ''
									position absolute
									top calc(50% - 11px)
									right 15px
									width 22px
									height 22px
									background url(/assets/images/common/ico-btn.svg)
									background-size 22px auto
				&-form
					&-1col
						position relative
						width 100%
					&-2col
						position relative
						width 47.8%
					&-birth
						&-y
							position relative
							width 27.6%
						&-m
						&-d
							position relative
							width 22.9%
						&-text
							width 5.8%
							font-size 16px
							font-weight 600
							padding-top 30px
					&-postnumber1
						position relative
						width 47.8%
					&-postnumber-text
						width 4.4%
						text-align center
						font-size 16px
						line-height 50px
						font-weight 600
					&-postnumber2
						position relative
						width 47.8%
					&-tel1
					&-tel2
					&-tel3
						position relative
						width 29%
					&-tel-text
						width 6%
						text-align center
						font-size 16px
						line-height 50px
						font-weight 600
				&-error
					position absolute
					left 0
					top 52px
					font-size 14px
					line-height 1.2
					font-weight 600
					color #ff0000

				&-btn
					position relative
					width 390px
					margin 0 auto 60px
					+ .form-backbtn
						margin-top -20px
					input[type="submit"]
					a
						-webkit-appearance none
						-moz-appearance none
						appearance none
						border 0px
						display block
						text-align center
						width 100%
						font-size 18px
						font-weight 600
						line-height 68px
						color #000
						height 70px
						background-color #ffe600
						border-radius (70px/2)
						border 3px solid #000
						&:hover
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-pink.png)
							background-size (118px/2) (26px/2)
							animation btnStripe59 2s infinite linear
					&::after
						content ''
						position absolute
						top calc(50% - 10px)
						right 25px
						width 20px
						height 20px
						background url(/assets/images/common/ico-btn.svg)
						background-size 20px auto
					&.arrowbottom::after
						transform rotate(90deg)



		//詳細
		&-information
			padding-bottom 50px
			&-wrap
				overflow hidden
				width 960px
				padding 80px 80px 20px
				background-color #fff
				border-radius 16px
				box-shadow 0px 0px 4px rgba(0,0,0,0.2)
				margin 0 auto 80px
				// 幅が狭い場合（ログイン画面）
				&.pcnarrow
					width 510px
					padding 70px 60px 80px
					margin 0 auto 120px
			&__lead
				text-align center
				font-size 16px
				font-weight 600
				letter-spacing 0.05em
				line-height 32px
				margin-top -45px
				margin-bottom 55px
			//★タイトル
			&__title
				text-align center
				font-size 24px
				line-height 48px
				font-weight 600
				margin-bottom 65px
				//★線ありのタイトル
				&-wline
					text-align center
					border-bottom 2px solid #cacaca
					margin-bottom 40px
					span
						position relative
						top 16px
						display inline-block
						font-size 20px
						font-weight 600
						padding 0 20px
						background-color #fff
			//★タイトル（罫線あり）
			&__subtitle
				text-align center
				font-size 24px
				font-weight 600
				line-height 1
				padding-bottom 40px
				border-bottom 1px solid #cacaca
				margin-bottom 62px
				+ .form-information__btn
					margin-top 30px
			//名前のみ
			&__name
				text-align center
				font-size 17px
				letter-spacing 0.03em
				font-weight 600
				margin-bottom 14px
			//サムネ＋名前
			&__thumb
				text-align center
				margin 0 auto 30px
				&__pct
					overflow hidden
					width 90px
					height 90px
					border-radius 50%
					margin 0 auto 15px
					img
						width 100%
				&__name
					font-size 17px
					letter-spacing 0.03em
					font-weight 600
					small
						display block
						font-size 9px
						padding-top 6px
			//サムネ＋名前 ※横
			&__thumb-horizontal
				display flex
				align-items center
				justify-content center
				margin-bottom 25px
				&__pct
					overflow hidden
					width 60px
					height 60px
					border-radius 50%
					margin-right 15px
					img
						width 100%
				&__name
					font-size 17px
					letter-spacing 0.03em
					font-weight 600
			//★入力情報の確認リスト
			&__list
				dl
					display flex
					flex-wrap wrap
					justify-content space-between
					margin-bottom 49px
					dt
						width 197px
						font-size 16px
						padding-top 3px
						padding-left 10px
					dd
						width calc(100% - 197px)
						min-height 25px
						font-size 20px
						font-weight 600
					+ .form-edit__subtitle
						margin-top 75px
						margin-bottom 42px
				//リスト内 編集ボタン幅調整
				.form-editbtn
					width 100%
					margin 25px 0 70px 0
			//罫線
			hr
				border 0px
				border-bottom 1px solid #cacaca
				margin 30px 0
			//ボタン各種
			&__btn
				+ .form-information__btn
					margin-top 40px
				+ .form-information__text
					margin-top 25px
				a
					display block
					width 390px
					height 70px
					background-color #ffe600
					border-radius (70px/2)
					border 3px solid #000
					margin 0 auto
					&:hover
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 18px
						font-weight 600
						line-height 67px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 10px)
							right 25px
							width 20px
							height 20px
							background url(/assets/images/common/ico-btn.svg)
							background-size 20px auto
				&.bggray a
					background-color #f0f0f0
				&.back a span::after
					right auto
					left 22px
					transform scale(-1, 1)
				&.disable
					opacity 0.25
					a
						pointer-events none
						background-color #ccc
			//★
			&__linebtn
				+ .form-information__title-wline
					margin-top 58px
				a
					display block
					width 100%
					height 64px
					padding-left 44px
					background-color #00c300
					background-image url(/assets/images/form/ico-line.svg)
					background-position left center
					background-repeat no-repeat
					background-size auto 100%
					border-radius 8px
					margin 0 auto
					&:hover
						background-color #00e000
						background-image url(/assets/images/form/ico-line-hover.svg)
					&:active
						background-color #00b300
						background-image url(/assets/images/form/ico-line-active.svg)
					span
						display block
						text-align center
						font-size 22px
						letter-spacing 0.05em
						font-weight 600
						line-height 60px
						color #fff
			//★テキスト
			&__text
				font-size 16px
				font-weight 600
				line-height 32px
				padding 0 40px
				margin-bottom 48px
				&.pccenter
					text-align center
				&.pcgray
					color #666666
				&.topnarrow
					margin-top -30px
				+ .form-information__btn.back
					margin-bottom 70px
				&.nopadding
					padding 0
				//ログイン時のpadding
				&.small
					padding 0
			//★チェックボックス
			&__checkbox
				display flex
				justify-content center
				margin-bottom 65px
				//チェックボックスの下の注意書き
				+ .form-information__text
					margin-top -48px
				//無効
				&.disable
					color #cacaca
					label
						pointer-events none
						.form-information__checkbox__toggle
							background url(/assets/images/me/ico-checkbox-disable.svg)
							background-size 100%
				label
					cursor pointer
					position relative
					display flex
					align-items center
					min-height 30px
				&__label
					font-size 16px
					line-height 1.2
					font-weight 600
					padding-left 52px
				input
					position absolute
					left -1000px
					top 0
					&:checked + .form-information__checkbox__toggle::after
						content ""
						display block
						position absolute
						top 0px
						left 0px
						width 33px
						height 33px
						background url(/assets/images/me/ico-checkbox-on.svg)
						background-size 100%
				&__toggle
					content ""
					position absolute
					top 0px
					left 0px
					margin auto
					width 33px
					height 33px
					background url(/assets/images/me/ico-checkbox-off.svg)
					background-size 100%


		//★最下部ボタン各種
		&-followbtn
		&-backbtn
		&-editbtn
			width 390px
			margin 80px auto 130px
			a
				display block
				width 390px
				height 70px
				background-color #ffe600
				border-radius (70px/2)
				border 3px solid #000
				margin 0 auto
				&:hover
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 18px
					font-weight 600
					line-height 67px
					color #000
					&::after
						content ''
						position absolute
						top calc(50% - 10px)
						right 23px
						width 20px
						height 20px
						background url(/assets/images/common/ico-btn.svg)
						background-size 20px auto
		&-backbtn
		&-editbtn
			a
				span
					&::after
						transform: scale(-1, 1);
						top calc(50% - 10px)
						right auto
						left 23px
		&-editbtn
			margin 80px auto
			a
				background-color #f0f0f0



		//クラス選択
		&-selectclass
			margin-bottom 160px
			&-wrap
				overflow hidden
				width 960px
				padding-bottom 40px
				background-color #fff
				border-radius 16px
				box-shadow 0px 0px 4px rgba(0,0,0,0.2)
				margin 0 auto
			&__pct
				position relative
				overflow hidden
				height 350px
				margin-bottom 52px
				.wave
					position absolute
					display block
					bottom 0
					transform scale(1.5, 1.5)
				img
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
					-webkit-transform: translateY(-50%) translateX(-50%);
					width: 100%;
			&__list
				font-size 16px
				font-weight 600
				line-height 32px
				padding 0 80px 28px
				dl
					display flex
					flex-wrap wrap
					justify-content space-between
					font-size 20px
					line-height 40px
					margin-bottom 18px
					dt
						width 122px
					dd
						width calc(100% - 122px)
					+ .form-selectclass__list__title
						padding-top 37px
				&__title
					font-size 16px
					margin-bottom 13px
				&__radiobtn
					margin-bottom 28px
					+ .form-selectclass__list__title
						padding-top 45px
					label
						position relative
						display flex
						align-items center
						min-height 80px
						padding 10px 0
						border-top 1px solid #cacaca
						border-bottom 1px solid #cacaca
						margin-bottom -1px
					&__label
						font-weight 600
						padding-left 105px
					input
						position absolute
						left -1000px
						top 0
						&:checked + .form-selectclass__list__radiobtn__toggle::after
							content ""
							display block
							position absolute
							top calc(50% - 12px)
							left 0
							width 24px
							height 24px
							background url(/assets/images/form/ico-radiobtn-on.svg)
							background-size 100%
					&__toggle
						content ""
						position absolute
						top calc(50% - 12px)
						left 40px
						margin auto
						width 24px
						height 24px
						background url(/assets/images/form/ico-radiobtn-off.svg)
						background-size 100%
			&__detail
				padding-bottom 10px
				border-top 1px solid #c3c3c3
				border-bottom 1px solid #c3c3c3
				margin 45px 80px 60px
				&__title
					display flex
					justify-content center
					position relative
					top -20px
					margin 0 auto 3px
					span
						display inline-block
						height 40px
						font-size 18px
						line-height 37px
						letter-spacing 0.08em
						font-weight 600
						padding 0 30px
						background-color #fff
						border 1px solid #cacaca
						border-radius 20px
				&__text
					p
						font-size 16px
						font-weight 600
						line-height 32px
						color #666666
						margin-bottom 2em
				+ .form-selectclass__btn
					margin-bottom 42px
			&__btn
				position relative
				width 390px
				margin 0 auto
				input[type="submit"]
				a
					-webkit-appearance none
					-moz-appearance none
					appearance none
					border 0px
					display block
					text-align center
					width 390px
					height 70px
					font-size 18px
					font-weight 600
					line-height 67px
					color #000
					background-color #ffe600
					border-radius (70px/2)
					border 3px solid #000
					&:hover
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
				&::after
					content ''
					position absolute
					top calc(50% - 10px)
					right 23px
					width 20px
					height 20px
					background url(/assets/images/common/ico-btn.svg)
					background-size 20px auto
				&.arrowbottom::after
					transform rotate(90deg)



		//サムネイル設定
		&-thumbnail
			user-select none
			position fixed
			overflow-y scroll
			display flex
			top 0
			left 0
			width 100%
			height 100%
			padding-top 50px
			background-color rgba(255,230,0,0.9)
			z-index 20
			&-wrap
				position relative
				width 600px
				height 700px
				padding 80px 0
				background-color #fff
				border-radius 16px
				box-shadow 0px 0px 4px rgba(0,0,0,0.2)
				margin 0 auto
			&__closebtn
				position absolute
				z-index 100
				top 30px
				right 30px
				a
					display block
					width 30px
					height 30px
					img
						width 100%
			&__pct
				overflow hidden
				position relative
				width 400px
				height 400px
				margin 0 auto 38px
				canvas
					width 400px
					height 400px
					cursor move
				&::before
					position absolute
					pointer-events none
					top 0
					left 0
					content ""
					width 400px
					height 400px
					background url(/assets/images/form/mask-wh.png)
					background-position center center
					background-size cover
			&__slider
				user-select none
				position relative
				width 312px
				margin 0 auto 44px
				&__minus
				&__plus
					position absolute
					top -8px
					img
						width 20px
				&__minus
					left -40px
				&__plus
					right -40px
				&__bar
					position relative
					width 312px
					height 4px
					background-color #cacaca
					border-radius 2px
					&__btn
						position absolute
						cursor pointer
						top -8px
						left -8px
						width 20px
						height 20px
						border 3px solid #000000
						background-color #ffe600
						border-radius 50%
			&__btn
				width 390px
				margin 0 auto
				a
					display block
					width 100%
					height 70px
					background-color #ffe600
					border-radius (70px/2)
					border 3px solid #000
					&:hover
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 18px
						font-weight 600
						line-height 67px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 10px)
							right 25px
							width 20px
							height 20px
							background url(/assets/images/common/ico-btn.svg)
							background-size 20px auto


//SP
@media screen and (max-width 768px)

	.formPage
		.header__logo
			margin-bottom 60px
		.header__title
			margin-bottom 35px
		.form-information + .header
			margin-top -30px

	.form
		//編集
		&-edit
			&-wrap
				position relative
				overflow hidden
				width 84%
				padding 30px 20px 15px
				background-color #fff
				border-radius 8px
				box-shadow 0px 0px 2px rgba(0,0,0,0.2)
				margin 0 auto 30px
				+ .form-edit__input-btn
					margin 40px 8% 20px
			&__lead
				text-align center
				font-size 12px
				font-weight 600
				letter-spacing 0.05em
				line-height 24px
				padding 0 40px
				margin-top -10px
				margin-bottom 25px
				&-caution
					padding-top 10px
					font-weight 400
					span
						font-family serif
						color #ff0000
			&__closebtn
				position absolute
				top 0
				right 0
				a
					display flex
					align-items center
					justify-content center
					width 45px
					height 45px
					&:action
						opacity 0.5
					img
						width 17px

			//★
			&__thumb
				position relative
				margin-bottom 30px
				&__pct
					overflow hidden
					width 90px
					height 90px
					border-radius 50%
					margin 0 auto 3px
					img
						width 100%
				&__edit
					position absolute
					right -2px
					bottom -4px
					width 34px
					img
						width 100%
				&__link
					text-align center
					a
					label
						cursor pointer
						font-size 10px
						font-weight 600
						color #ff72a2
						text-decoration underline
						padding-left 15px //センター出し
					&-require
						font-family serif
						font-size 10px
						color #ff0000
						font-weight 400
						margin-left 5px
			//★
			&__subtitle
				display flex
				align-items center
				justify-content center
				width 75px
				height 15px
				font-size 9px
				line-height 1.1
				font-weight 600
				background-color #f0f0f0
				border-radius 3px
				margin-bottom 14px
				&-bgyellow
					display flex
					align-items center
					justify-content center
					width 103px
					height 25px
					font-size 12px
					line-height 1.1
					font-weight 600
					background-color #ffe600
					border-radius 13px
					margin 0 auto 27px

			&__input //以下入力エリア
				dl
					margin-bottom 25px
					dt
						display flex
						flex-wrap wrap
						margin-bottom 8px
					dd
						display flex
						flex-wrap wrap
						justify-content space-between
				input[type="text"],
				input[type="email"],
				select
					-webkit-appearance none
					-moz-appearance none
					appearance none
					width 100%
					height 30px
					border 1px solid #cacaca
					font-size 16px
					font-weight 600
					text-indent 8px
					border-radius 3px
					&.error
						border-color #ff0000
				select
					background #fff url(/assets/images/me/ico-pulldown.svg) right 14px center no-repeat
					background-size 10px 11px
				// フォーム選択時の見た目
				input[type="text"],
				input[type="email"]
					&:focus
						border 1px solid #000
					&::selection
						background-color #cacaca
					&::-webkit-input-placeholder
						color #cacaca
					&::-moz-placeholder
						color #cacaca
					&:-ms-input-placeholder
						color #cacaca
				&-title
					font-size 12px
					line-height 1.1
					font-weight 600
				&-require
					font-size 12px
					line-height 1.1
					font-weight 400
					font-family serif
					color #ff0000
					margin-left 5px
				//&-require
				//	display flex
				//	align-items center
				//	justify-content center
				//	width 43px
				//	height 15px
				//	font-size 9px
				//	line-height 1.1
				//	font-weight 600
				//	background-color #ffe600
				//	border-radius 3px
				&-postnumber
					display flex
					flex-wrap wrap
					justify-content space-between
					dl
						width 47.8%
					&-btn
						width 47.8%
						padding-top 22px
						a
							display block
							width 100%
							height 29px
							background-color #f0f0f0
							border-radius (29px/2)
							border 2px solid #000
							margin 0 auto
							&:active
								opacity 0.8
							span
								position relative
								display block
								text-align center
								width 100%
								height 100%
								font-size 12px
								font-weight 600
								line-height 26px
								color #000
								&::after
									content ''
									position absolute
									top calc(50% - 7px)
									right 8px
									width 13px
									height 13px
									background url(/assets/images/common/ico-btn.svg)
									background-size 13px auto
				&-form
					&-1col
						position relative
						width 100%
					&-2col
						position relative
						width 47.8%
					&-birth
						&-y
							position relative
							width 27.6%
						&-m
						&-d
							position relative
							width 22.9%
						&-text
							width 5.8%
							font-size 12px
							font-weight 600
							padding-top 13px
					&-postnumber1
						position relative
						width 38%
					&-postnumber-text
						width 11.4%
						text-align center
						font-size 12px
						line-height 30px
						font-weight 600
					&-postnumber2
						position relative
						width 48.6%
					&-tel1
					&-tel2
					&-tel3
						position relative
						width 29%
					&-tel-text
						width 6%
						text-align center
						font-size 12px
						line-height 30px
						font-weight 600
				&-error
					position absolute
					left 0
					top 31px
					font-size 9px
					line-height 1.2
					font-weight 600
					color #ff0000

				&-btn
					position relative
					margin-bottom 22px
					input[type="submit"]
					a
						-webkit-appearance none
						-moz-appearance none
						appearance none
						border 0px
						display block
						text-align center
						width 100%
						font-size 14px
						font-weight 600
						line-height 42px
						color #000
						height 45px
						background-color #ffe600
						border-radius (45px/2)
						border 2px solid #000
						margin 0 auto
						&:active
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-pink.png)
							background-size (118px/2) (26px/2)
							animation btnStripe59 2s infinite linear
					&::after
						content ''
						position absolute
						top calc(50% - 8px)
						right 12px
						width 17px
						height 17px
						background url(/assets/images/common/ico-btn.svg)
						background-size 17px auto
					&.arrowbottom::after
						transform rotate(90deg)



		//詳細
		&-information
			padding-bottom 50px
			&-wrap
				overflow hidden
				width 84%
				padding 40px 20px
				background-color #fff
				border-radius 8px
				box-shadow 0px 0px 2px rgba(0,0,0,0.2)
				margin 0 auto 38px
				+ .form-information-wrap
					margin-top -10px
			&__lead
				text-align center
				font-size 12px
				font-weight 600
				letter-spacing 0.05em
				line-height 24px
				padding 0 40px
				margin-top -10px
				margin-bottom 25px
			//★タイトル
			&__title
				text-align center
				font-size 17px
				line-height 30px
				font-weight 600
				margin-bottom 20px
				//★線ありのタイトル
				&-wline
					text-align center
					border-bottom 1px solid #cacaca
					margin-bottom 30px
					span
						position relative
						top 10px
						display inline-block
						font-size 12px
						font-weight 600
						padding 0 3px
						background-color #fff
			//★タイトル（罫線あり）
			&__subtitle
				text-align center
				font-size 17px
				font-weight 600
				line-height 27px
				padding-bottom 24px
				border-bottom 1px solid #cacaca
				margin-bottom 12px
				+ .form-information__btn
					margin-top 30px
			//名前のみ
			&__name
				text-align center
				font-size 17px
				letter-spacing 0.03em
				font-weight 600
				margin-bottom 14px
			//サムネ＋名前
			&__thumb
				text-align center
				margin 0 auto 30px
				&__pct
					overflow hidden
					width 90px
					height 90px
					border-radius 50%
					margin 0 auto 15px
					img
						width 100%
				&__name
					font-size 17px
					letter-spacing 0.03em
					font-weight 600
					small
						display block
						font-size 9px
						padding-top 6px
			//サムネ＋名前 ※横
			&__thumb-horizontal
				display flex
				align-items center
				justify-content center
				margin-bottom 25px
				&__pct
					overflow hidden
					width 60px
					height 60px
					border-radius 50%
					margin-right 15px
					img
						width 100%
				&__name
					font-size 17px
					letter-spacing 0.03em
					font-weight 600
			//★入力情報の確認リスト
			&__list
				dl
					font-size 12px
					line-height 24px
					padding-top 15px
					margin-bottom 10px
					&:nth-last-of-type(1)
						margin-bottom 0
					dt
						margin-bottom 7px
					dd
						font-size 14px
						font-weight 600
				.form-edit__subtitle
					margin-top 38px
					margin-bottom -5px
				//リスト内 編集ボタン幅調整
				.form-editbtn
					width 100%
					margin 25px 0 0 0
			//罫線
			hr
				border 0px
				border-bottom 1px solid #cacaca
				margin 30px 0
			//ボタン各種
			&__btn
				+ .form-information__btn
					margin-top 20px
				+ .form-information__text
					margin-top 25px
				a
					display block
					width 100%
					height 45px
					background-color #ffe600
					border-radius (45px/2)
					border 2px solid #000
					margin 0 auto
					&:active
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 14px
						font-weight 600
						line-height 42px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 8px)
							right 12px
							width 17px
							height 17px
							background url(/assets/images/common/ico-btn.svg)
							background-size 17px auto
				&.bggray a
					background-color #f0f0f0
				&.back a span::after
					right auto
					left 12px
					transform scale(-1, 1)
				&.disable
					opacity 0.25
					a
						pointer-events none
						background-color #ccc
			//★
			&__linebtn
				+ .form-information__title-wline
					margin-top 18px
				a
					display block
					width 100%
					height 45px
					padding-left 44px
					background-color #00c300
					background-image url(/assets/images/form/ico-line.svg)
					background-position left center
					background-repeat no-repeat
					background-size auto 100%
					border-radius 8px
					margin 0 auto
					&:active
						background-color #00b300
						background-image url(/assets/images/form/ico-line-active.svg)
					span
						display block
						text-align center
						font-size 13px
						letter-spacing 0.05em
						font-weight 600
						line-height 42px
						color #fff
			//★テキスト
			&__text
				font-size 12px
				font-weight 600
				line-height 24px
				margin-bottom 20px
				+ .form-information__checkbox
					margin-bottom 0px
				&.center
					text-align center
				&.small
					color #666666
					font-size 10px
					line-height 20px
			//★チェックボックス
			&__checkbox
				display flex
				justify-content center
				margin-bottom 28px
				//チェックボックスの下の注意書き
				+ .form-information__text
					margin-top -10px
				//無効
				&.disable
					color #cacaca
					label
						pointer-events none
						.form-information__checkbox__toggle
							background url(/assets/images/me/ico-checkbox-disable.svg)
							background-size 100%
				label
					cursor pointer
					position relative
					display flex
					align-items center
					min-height 30px
				&__label
					font-size 12px
					font-weight 600
					padding-left 35px
				input
					position absolute
					left -1000px
					top 0
					&:checked + .form-information__checkbox__toggle::after
						content ""
						display block
						position absolute
						top 0px
						left 0px
						width 27px
						height 27px
						background url(/assets/images/me/ico-checkbox-on.svg)
						background-size 100%
				&__toggle
					content ""
					position absolute
					top 0px
					left 0px
					margin auto
					width 27px
					height 27px
					background url(/assets/images/me/ico-checkbox-off.svg)
					background-size 100%


		//★最下部ボタン各種
		&-followbtn
		&-backbtn
		&-editbtn
			width 84%
			margin 0 auto 80px
			a
				display block
				width 100%
				height 45px
				background-color #ffe600
				border-radius (45px/2)
				border 2px solid #000
				margin 0 auto
				&:active
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 14px
					font-weight 600
					line-height 42px
					color #000
					&::after
						content ''
						position absolute
						top calc(50% - 8px)
						right 12px
						width 17px
						height 17px
						background url(/assets/images/common/ico-btn.svg)
						background-size 17px auto
		&-backbtn
		&-editbtn
			a
				span
					&::after
						transform: scale(-1, 1);
						top calc(50% - 8px)
						right auto
						left 12px
		&-editbtn
			a
				background-color #f0f0f0



		//クラス選択
		&-selectclass
			padding-bottom 50px
			&-wrap
				overflow hidden
				width 84%
				padding-bottom 40px
				background-color #fff
				border-radius 8px
				box-shadow 0px 0px 2px rgba(0,0,0,0.2)
				margin 0 auto 38px
			&__pct
				position relative
				overflow hidden
				height 115px
				margin-bottom 26px
				.wave
					position absolute
					display block
					bottom 0
				img
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
					-webkit-transform: translateY(-50%) translateX(-50%);
					width: 100%;
				&__icon
					position absolute
					top 10px
					left 10px
					width 94px
			&__list
				font-size 12px
				font-weight 600
				line-height 24px
				padding 0 20px 28px
				dl
					margin-bottom 28px
				&__radiobtn
					margin-bottom 28px
					label
						position relative
						display flex
						align-items center
						min-height 30px
						padding 10px 0
						border-top 1px solid #cacaca
						border-bottom 1px solid #cacaca
						margin-bottom -1px
					&__label
						font-weight 600
						padding-left 35px
					input
						position absolute
						left -1000px
						top 0
						&:checked + .form-selectclass__list__radiobtn__toggle::after
							content ""
							display block
							position absolute
							top calc(50% - 8px)
							left 0px
							width 16px
							height 16px
							background url(/assets/images/form/ico-radiobtn-on.svg)
							background-size 100%
					&__toggle
						content ""
						position absolute
						top calc(50% - 8px)
						left 0px
						margin auto
						width 16px
						height 16px
						background url(/assets/images/form/ico-radiobtn-off.svg)
						background-size 100%
			&__detail
				border-top 1px solid #c3c3c3
				border-bottom 1px solid #c3c3c3
				margin 0 20px 30px
				&__title
					display flex
					justify-content center
					position relative
					top -12px
					margin 0 auto 3px
					span
						display inline-block
						height 25px
						font-size 12px
						line-height 24px
						letter-spacing 0.08em
						font-weight 600
						padding 0 15px
						background-color #fff
						border 1px solid #cacaca
						border-radius 13px
				&__text
					p
						font-size 10px
						font-weight 600
						line-height 20px
						color #666666
						margin-bottom 2em
			&__btn
				position relative
				margin 0 20px
				input[type="submit"]
				a
					-webkit-appearance none
					-moz-appearance none
					appearance none
					border 0px
					display block
					text-align center
					width 100%
					font-size 14px
					font-weight 600
					line-height 42px
					color #000
					height 45px
					background-color #ffe600
					border-radius (45px/2)
					border 2px solid #000
					margin 0 auto
					&:active
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
				&::after
					content ''
					position absolute
					top calc(50% - 8px)
					right 12px
					width 17px
					height 17px
					background url(/assets/images/common/ico-btn.svg)
					background-size 17px auto
				&.arrowbottom::after
					transform rotate(90deg)



		//サムネイル設定
		&-thumbnail
			position fixed
			// position relative
			left 0
			top 0
			width 100%
			height 100%
			z-index 20
			background-color #000
			canvas
				width: 100%
				height: 100%
			&__mask
				position absolute
				pointer-events none
				z-index 50
				top 0
				left 0
				display block
				width 100%
				height 100%
				background url(/assets/images/form/mask.png) center center no-repeat
				background-size 500px auto
			&__closebtn
				position absolute
				z-index 100
				top 15px
				left 15px
				a
					display block
					width 18px
					height 18px
					img
						width 100%
			&__pct
				position relative
			&__btn
				position absolute
				z-index 110
				top calc((100% / 2) + 158px + 50px)
				left 0
				width 100%
				a
					display block
					width 84%
					height 45px
					background-color #ffe600
					border-radius (45px/2)
					border 2px solid #000
					margin 0 auto
					&:active
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 14px
						font-weight 600
						line-height 42px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 8px)
							right 12px
							width 17px
							height 17px
							background url(/assets/images/common/ico-btn.svg)
							background-size 17px auto
