//PC
@media screen and (min-width 769px)
	.menu
		a
			-webkit-tap-highlight-color:rgba(0,0,0,0);
		&__btn
			z-index 100
			position fixed
			top 30px
			right 30px
			overflow hidden
			width 62px
			height 62px
			img
				width 100%
			a:hover img
				position relative
				top -62px
		&__list
			z-index 200
			position fixed
			top 0
			left 0
			width 100vw
			height 100vh
			background-color rgba(0,0,0,0.0)
			transition background-color 0.5s ease, visibility 0.5s
			user-select none
			visibility hidden
			&.open
				visibility visible
				background-color rgba(0,0,0,0.5)
				transition background-color 3s ease, visibility 3s
				.menu__list__wrap
					transform translateX(0px)
					// transition transform .25s ease-in
			&__wrap
				transform translateX(440px)
				transition transform .25s ease-out
				position fixed
				top 0
				right 0
				width 440px
				height 100vh
				overflow-y scroll
				padding 30px 20px 0
				background-color #ffe600
			&__head
				height 90px
				&__logo
					img
						width 164px
				&__closebtn
					position absolute
					top 30px
					right 30px
					overflow hidden
					width 60px
					height 60px
					img
						width 100%
					a:hover img
						position relative
						top -60px
			&__main
				border-top 2px solid #000
				border-bottom 2px solid #000
				margin-bottom 32px
				ul
					li
						a
							position relative
							display block
							height 70px
							font-size 20px
							line-height 70px
							font-weight 600
							color #000
							padding-left 63px
							&::before
								content ''
								position absolute
								top calc(50% - 17px)
								left 14px
								width 34px
								height 34px
								background url(/assets/images/common/ico-home.svg) center top
								background-size 34px 68px
							&::after
								content ''
								position absolute
								top calc(50% - 13px)
								right 16px
								width 26px
								height 26px
								background url(/assets/images/common/ico-btn.svg) center top
								background-size 26px auto
							&:hover
								color #ff72a2
								text-decoration none
								&::before
									background url(/assets/images/common/ico-home.svg) center bottom
									background-size 34px 68px
								&::after
									background url(/assets/images/common/ico-btn.svg) center bottom
									background-size 26px auto
						span
							position relative
							display block
							height 70px
							font-size 20px
							line-height 70px
							font-weight 600
							color #000
							padding-left 63px
							border-top 2px solid #000
							border-bottom 1px solid #000
							&::before
								content ''
								position absolute
								top calc(50% - 17px)
								left 14px
								width 34px
								height 34px
								background url(/assets/images/common/ico-schop.svg)
								background-size 34px 34px
						ul
							li
								background: url(/assets/images/common/bg-dotline.png) left bottom repeat-x
								background-size 10px 3px
								a
									font-size 18px
									border-bottom 0px
									&::before
										content ''
										position absolute
										top calc(50% - 3px)
										left 25px
										width 6px
										height 6px
										background none
										background-color #000
										border-radius 3px
									&:hover::before
										background none
										background-color #000
								&:nth-last-of-type(1)
									background-image none
			&__banner
				padding-bottom 40px
				border-bottom 2px solid #000
				margin-bottom: 40px
				&__lead
					text-align center
					font-size 16px
					font-weight 600
					line-height 32px
					margin-bottom 24px
				&__link
					a
						display inline-block
						transition transform 0.2s ease-out, box-shadow 0.2s ease-out
						&:hover
							position relative
							transform translateY(-5px)
							box-shadow 0px 0px 8px rgba(0,0,0,0.25)
						img
							width 100%
			&__sub
				ul
					padding-left 20px
					li
						margin-bottom 26px
						a
							position relative
							font-size 16px
							font-weight 600
							color #000
							&[target="_blank"]::after
								content ''
								overflow hidden
								position absolute
								right -25px
								top 2px
								width 19px
								height 16px
								background url(/assets/images/common/ico-blank.svg) center bottom
								background-size 19px 32px
							&:hover
								color #ff72a2
								text-decoration none
								&::after
									background url(/assets/images/common/ico-blank.svg) center top
									background-size 19px 32px


//SP
@media screen and (max-width 768px)
	.menu
		a
			-webkit-tap-highlight-color:rgba(0,0,0,0);
		&__btn
			position fixed
			z-index 103
			top 14px
			right 14px
			overflow hidden
			width 46px
			height 46px
			img
				width 100%
			a:active img
				position relative
				top -46px
		&__list
			position fixed
			z-index 200
			top 0
			left 0
			width 100vw
			height 100vh
			background-color rgba(0,0,0,0.0)
			visibility hidden
			transition background-color 0.5s, visibility 0.5s
			&.open
				visibility visible
				background-color rgba(0,0,0,0.7)
				transition background-color 3s, visibility 3s
				.menu__list__wrap
					transform translateX(0px)
			&__wrap
				transform translateX(440px)
				transition transform 0.25s ease-out
				position fixed
				top 0
				right 0
				width (560px/2)
				height 100vh
				overflow-y scroll
				padding 15px 14px 0
				background-color #ffe600
			&__head
				height 58px
				&__logo
					img
						width (234px/2)
				&__closebtn
					position absolute
					top 14px
					right 14px
					overflow hidden
					width 45px
					height 45px
					img
						width 100%
					a:active img
						position relative
						top -45px
			&__main
				border-top 2px solid #000
				border-bottom 2px solid #000
				margin-bottom 23px
				ul
					li
						a
							position relative
							display block
							height 52px
							font-size 12px
							line-height 50px
							font-weight 600
							color #000
							padding-left 38px
							&::before
								content ''
								position absolute
								top calc(50% - 10px)
								left 8px
								width 20px
								height 20px
								background url(/assets/images/common/ico-home.svg) center top
								background-size 20px 40px
							&::after
								content ''
								position absolute
								top calc(50% - 8px)
								right 9px
								width 17px
								height 17px
								background url(/assets/images/common/ico-btn.svg) center top
								background-size 17px auto
							&:active
								color #ff72a2
								text-decoration none
								&::before
									background url(/assets/images/common/ico-home.svg) center bottom
									background-size 20px 40px
								&::after
									background url(/assets/images/common/ico-btn.svg) center bottom
									background-size 17px auto
						span
							position relative
							display block
							height 52px
							font-size 14px
							line-height 50px
							font-weight 600
							color #000
							padding-left 38px
							border-top 2px solid #000
							border-bottom 1px solid #000
							&::before
								content ''
								position absolute
								top calc(50% - 10px)
								left 8px
								width 20px
								height 20px
								background url(/assets/images/common/ico-schop.svg)
								background-size 20px 20px
						ul
							li
								background: url(/assets/images/common/bg-dotline--sp.png) left bottom repeat-x
								background-size 5px 3px
								a
									font-size 12px
									border-bottom 0px
									&::before
										content ''
										position absolute
										top calc(50% - 3px)
										left 15px
										width 4px
										height 4px
										background none
										background-color #000
										border-radius 2px
									&:active::before
										background none
										background-color #000
								&:nth-last-of-type(1)
									background-image none
			&__banner
				padding-bottom 30px
				border-bottom 2px solid #000
				margin-bottom: 20px
				&__lead
					text-align center
					font-size 12px
					font-weight 600
					line-height 21px
					margin-bottom 22px
				&__link
					a
						display inline-block
						transition transform 0.2s ease-out, box-shadow 0.2s ease-out
						&:hover
							position relative
							transform translateY(-5px)
							box-shadow 0px 0px 8px rgba(0,0,0,0.25)
						img
							width 100%
			&__sub
				padding-bottom 100px
				ul
					padding-left 10px
					li
						margin-bottom 15px
						a
							position relative
							font-size 11px
							font-weight 600
							color #000
							&[target="_blank"]::after
								position absolute
								bottom 4px
								right -13px
								content ""
								overflow hidden
								display inline-block
								width 10px
								height 8px
								background url(/assets/images/common/ico-blank.svg) center bottom
								background-size 10px 16px
							&:active
								color #ff72a2
								text-decoration none
								&::after
									background url(/assets/images/common/ico-blank.svg) center top
									background-size 10px 16px
