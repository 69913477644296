//PC
@media screen and (min-width 769px)
	.modal
		display none
		position fixed
		overflow-y scroll
		top 0
		left 0
		width 100%
		height 100vh
		background-color rgba(255,230,0,0.9)
		z-index 1000
		&-bg
			position absolute
			top 0
			left 0
			width 100%
			height 100vh
		&-wrap
			position relative
			top 50%
			left 50%
			transform translateY(-50%) translateX(-50%)
			width 90%
			max-width 960px
			padding 10px
			// padding-right 10px
			background-color #fff
			border-radius 16px
			box-shadow 0px 0px 2px rgba(0,0,0,0.2)
			&__inner
				width 100%
				height 100%
				// ここでvhを使うのは...?
				max-height 90vh
				padding 75px 40px 70px
				overflow auto
				&::-webkit-scrollbar
					width 10px
					display block
					background rgba(0,100,100,0)
				&::-webkit-scrollbar-thumb
					border-radius 10px
					background-color rgba(0,0,0,0.3)
		&__closebtn
			position absolute
			top 28px
			right 28px
			a
				img
					width 30px
		&__title
			font-size 16px
			font-weight 600
			margin-bottom 40px
		&__text
			font-size 16px
			line-height 2
			&.error
				color red

		&__btns
			display flex
			justify-content center
			margin 50px 10px 0
		&__btn
			position relative
			margin 0 20px
			width 190px
			&.cancel
				a
					background #f0f0f0
			a
				display flex
				justify-content center
				align-items center
				width 100%
				font-size 14px
				font-weight 600
				line-height 42px
				color #000
				height 45px
				background-color #ffe600
				border-radius (45px/2)
				border 3px solid #000
				margin 0 auto
				&:hover
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
			&::after
				content ''
				position absolute
				top calc(50% - 8px)
				right 12px
				width 17px
				height 17px
				background url(/assets/images/common/ico-btn.svg)
				background-size 17px auto


//SP
@media screen and (max-width 768px)
	.modal
		display none
		position fixed
		overflow-y scroll
		top 0
		left 0
		width 100%
		height 100vh
		background-color rgba(255,230,0,0.9)
		z-index 1000
		&-bg
			position absolute
			top 0
			left 0
			width 100%
			height 100vh
		&-wrap
			position absolute
			top 50%
			left 50%
			transform translateY(-50%) translateX(-50%)
			padding 2px
			width 84%
			background-color #fff
			border-radius 8px
			&__inner
				width 100%
				height 100%
				max-height 90vh
				padding 35px 20px 30px
				overflow auto
				&::-webkit-scrollbar-track
					display none
		&__closebtn
			position absolute
			top 15px
			right 15px
			a
				img
					width 16px
		&__title
			font-size 14px
			font-weight 600
			margin-bottom 28px
		&__text
			font-size 14px
			line-height 2
			&.error
				color red

		&__btns
			display flex
			justify-content center
			margin 30px -20px 0
		&__btn
			position relative
			margin 30px 5px 0
			width 140px
			&.cancel
				a
					background #f0f0f0
			a
				display flex
				justify-content center
				align-items center
				width 100%
				font-size 12px
				font-weight 600
				line-height 42px
				color #000
				height 45px
				background-color #ffe600
				border-radius (45px/2)
				border 3px solid #000
				margin 0 auto
				&:hover
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
			&::after
				content ''
				position absolute
				top calc(50% - 8px)
				right 12px
				width 17px
				height 17px
				background url(/assets/images/common/ico-btn.svg)
				background-size 17px auto
