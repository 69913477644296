//PC
@media screen and (min-width 769px)

	.briefing
		&__wrap
			overflow hidden
			width 960px
			background-color #fff
			border-radius 16px
			box-shadow 0px 0px 4px rgba(0,0,0,0.15)
			margin 0 auto 160px
		&__pct
			position relative
			margin-bottom 72px
			img
				width 100%
			.wave
				display block
				margin-top -14px
				transform scale(1.5, 1.5)

		&__flow
			margin 0 80px 120px
			font-weight 600
			position relative

			&__figure
				display flex
				justify-content space-between
				margin-bottom 40px

				&__item
					width 22%
					flex-shirink 0
					position relative

					&::before
						content ""
						display inline-block
						width 16px
						height 18px
						background url(/assets/images/briefing/pct-arrow.svg)
						background-repeat no-repeat
						background-position center center
						position absolute
						// NOTE: なんとかカッコつけて合わせようとした残骸
						// top 25%
						// right -25%
						// transform translateX(-100%) translateY(-100%)
						// NOTE: 目で合わせたやつ
						top 75px
						right -45px
						transform translateX(-100%) translateY(-100%)

					// PCの場合最後の矢印は非表示
					&:nth-child(4)
						&::before
							display none

					.figure
						position relative
						display flex
						flex-direction column
						align-items center

						&__textblock
							display block

						&--img
							width 110px
							margin-bottom 30px

						&--caption
							margin-bottom 30px
							text-align center
							font-size 20px
							letter-spacing 0.1rem

						&--caption--detailed
							fong-size: 16px
							line-height 1.6rem
							letter-spacing 0.1rem

			&--arrow
				width 100%
				height 18px
				position relative
				text-align center
				margin-bottom 40px

				&::before
					content ""
					display inline-block
					width 16px
					height 100%
					background url(/assets/images/briefing/pct-arrow.svg)
					background-repeat no-repeat
					background-position center center
					position absolute
					transform rotateZ(90deg)

			&--join
				width 100%
				height 100px
				background-color #ffe600
				border-radius 5px

				.link
					width 100%
					height 100%
					font-size 20px
					display flex
					justify-content center
					align-items center
					position relative

					&::before
						content ""
						display inline-block
						width 40px
						height 40px
						background url(/assets/images/briefing/ico-schop.svg)
						background-repeat no-repeat
						background-position center center
						position absolute
						left 30%

		&__lead
			font-size 16px
			font-weight 600
			line-height 32px
			margin 0 80px 95px
		&__list
			margin 0 80px 100px
			border-top 1px solid #cacaca
			&__title
				display flex
				align-items center
				justify-content center
				position relative
				top -20px
				width 120px
				height 40px
				font-size 18px
				font-weight 600
				background-color #fff
				border 1px solid #cacaca
				border-radius 20px
				margin 0 auto -40px
			&__type
				display flex
				align-items center
				justify-content center
				width 110px
				height 28px
				font-size 16px
				font-weight 600
				color #fff
				background-color #28c850
				border-radius 6px
				&.off
					background-color #cacaca
			&__date
				display block
				width 500px
				font-size 16px
				font-weight 600
				padding-left 30px
			&__status
				display block
				width 180px
				font-weight 600
				text-align center
				padding 12px 0
				border-left 1px solid #cacaca
				&::after
					content "受付中"
					color #ff0000
					.briefing__list__end &
						content "受付終了"
						color #cacaca
			ul
				margin-bottom 80px
				li
					display flex
					flex-wrap wrap
					align-items center
					justify-content space-between
					letter-spacing 0.08em
					padding 14px 0 14px 10px
					border-bottom 1px solid #cacaca
					&:nth-of-type(1)
						.briefing__list__type
							margin-top 25px
						.briefing__list__date
							padding-top 25px
						.briefing__list__status
							padding-top 35px
			&__btn
				a
					display block
					width 390px
					height 70px
					background-color #ffe600
					border-radius (70px/2)
					border 3px solid #000
					margin 0 auto
					&:hover
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 18px
						font-weight 600
						line-height 67px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 10px)
							right 23px
							width 20px
							height 20px
							background url(/assets/images/common/ico-btn.svg)
							background-size 20px auto
		&__caution
			color #666666
			margin 0 80px 75px
			p
				font-size 16px
				font-weight 600
				line-height 32px
				margin-bottom 2em
				a
					color #ff72a2
					text-decoration underline
					&:hover
						text-decoration none

		//サンクスページ
		&__thanks
			&-wrap
				// position: absolute;
				// top: 45%;
				// left: 50%;
				// transform: translateY(-50%) translateX(-50%);
				// -webkit-transform: translateY(-50%) translateX(-50%);
				width: 860px;
				margin: 0 auto;
				padding 80px 50px;
			&__pct
				width 180px
				margin 0 auto 50px
				img
					width 180px
			&__lead
				text-align center
				font-size 16px
				font-weight 600
				line-height 32px


//SP
@media screen and (max-width 768px)

	.briefing
		&__wrap
			overflow hidden
			width 84%
			background-color #fff
			border-radius 8px
			box-shadow 0px 0px 2px rgba(0,0,0,0.15)
			margin 0 auto 80px
		&__pct
			position relative
			margin-bottom 32px
			img
				width 100%
			.wave
				display block
				margin-top -14px

		&__flow
			margin 0 20px 60px
			font-weight 600
			position relative

			&__figure
				display flex
				flex-direction column
				justify-content space-around

				&__item
					height auto
					position relative
					margin-bottom 50px

					&::before
						content ""
						display inline-block
						width 13px
						height 11px
						background url(/assets/images/briefing/pct-arrow.svg)
						background-repeat no-repeat
						background-position center center
						position absolute
						transform rotateZ(90deg) translate(50%)
						// NOTE: 上下真ん中に寄せる難しい式
						bottom calc(100% - 70px -(50% - 10px))
						left 27.5px

					.figure
						display flex
						align-items flex-start

						&--img
							width 70px

						&__textblock
							display flex
							flex-direction column
							padding-left 15px

						&--caption
							font-size 15px
							letter-spacing 0.05rem

						&--caption--detailed
							font-size: 12px
							line-height 1.6rem
							letter-spacing 0.05rem

			&--arrow
				display none

			&--join
				height 70px
				background-color #ffe600
				border-radius 3px

				.link
					width 100%
					height 100%
					display flex
					justify-content center
					align-items center

					&::before
						content ""
						display inline-block
						width 25px
						height 25px
						margin-right 10px
						background url(/assets/images/briefing/ico-schop.svg)
						background-repeat no-repeat
						background-position center center
						// position absolute
						// left 15%

		&__lead
			font-size 12px
			font-weight 600
			line-height 24px
			margin 0 20px 45px
		&__list
			margin 0 20px 32px
			border-top 1px solid #cacaca
			&__title
				display flex
				align-items center
				justify-content center
				position relative
				top -12px
				width 95px
				height 25px
				font-size 12px
				font-weight 600
				background-color #fff
				border 1px solid #cacaca
				border-radius 13px
				margin 0 auto -20px
			&__type
				display flex
				align-items center
				justify-content center
				width 55px
				height 17px
				font-size 9px
				font-weight 600
				color #fff
				background-color #28c850
				border-radius 3px
				&.off
					background-color #cacaca
			&__date
				display block
				width calc(100% - 123px)
				font-size 12px
				font-weight 600
				line-height 24px
				padding 3px 0
				padding-left 14px
				border-right 1px solid #cacaca
				span
					display inline-block
					width 110px
			&__status
				display block
				width 68px
				font-size 12px
				font-weight 600
				text-align center
				&::after
					content "受付中"
					color #ff0000
					.briefing__list__end &
						content "受付終了"
						color #cacaca
			ul
				margin-bottom 30px
				li
					display flex
					flex-wrap wrap
					align-items center
					justify-content space-between
					letter-spacing 0.05em
					padding 10px 0 10px 5px
					border-bottom 1px solid #cacaca
			&__btn
				a
					display block
					width 100%
					height 46px
					background-color #ffe600
					border-radius (46px/2)
					border 2px solid #000
					margin 0 auto
					&:active
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 13px
						font-weight 600
						line-height 40px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 8px)
							right 18px
							width 17px
							height 17px
							background url(/assets/images/common/ico-btn.svg)
							background-size 17px auto
		&__caution
			color #666666
			margin 0 20px 40px
			p
				font-size 10px
				font-weight 600
				line-height 20px
				margin-bottom 2em
				a
					color #ff72a2
					text-decoration underline
					&:active
						text-decoration none

		//サンクスページ
		&__thanks
			&-wrap
				// position: absolute;
				// top: 45%;
				// left: 50%;
				// transform: translateY(-50%) translateX(-50%);
				// -webkit-transform: translateY(-50%) translateX(-50%);
				max-width: 350px;
				padding 50px 10px
				margin: auto
			&__pct
				width 130px
				margin 0 auto 34px
				img
					width 130px
			&__lead
				text-align center
				font-size 12px
				font-weight 600
				line-height 24px


//SP　狭い場合
@media screen and (max-width 333px)

	.briefing__list__date
		span
			width auto
			padding-right 5px