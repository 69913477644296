//PC
@media screen and (min-width 769px)

	.company
		&__wrap
			width 960px
			background-color #fff
			border-radius 16px
			box-shadow 0px 0px 4px rgba(0,0,0,0.15)
			margin 0 auto

		&-pagelink
			width 960px
			margin 0 auto 80px
			&__list
				ul
					display flex
					flex-wrap wrap
					justify-content space-between
					justify-content center
					li
						width 225px
						margin 0 10px
						a
							display block
							width 225px
							height 50px
							background-color #ffe600
							border-radius (50px/2)
							border 3px solid #000
							margin 0 auto
							&:hover
								text-decoration none
								background url(/assets/images/common/bg-btn-hover-pink.png)
								background-size (118px/2) (26px/2)
								animation btnStripe59 2s infinite linear
							span
								position relative
								display block
								text-align center
								width 100%
								height 100%
								font-size 18px
								font-weight 600
								line-height 47px
								color #000
								&::after
									content ''
									position absolute
									top calc(50% - 10px)
									right 12px
									width 20px
									height 20px
									background url(/assets/images/common/ico-btn-bottom.svg)
									background-size 20px auto

		&-vision
			margin-bottom 150px
			.company__wrap
				overflow hidden
				text-align center
				padding 0 0 110px
			&__pct
				margin-bottom 60px
				img
					width 100%
			&__title
				margin-bottom 84px
				img
					height 30px
			&__lead
				margin-bottom 70px
				img
					height 80px
			&__text
				p
					font-size 18px
					font-weight 600
					line-height 46px

		&-message
			margin-bottom 150px
			.company__wrap
				overflow hidden
				padding 80px 0 140px
			&__title
				text-align center
				margin-bottom 50px
				img
					height 32px
			&__tab
				margin-bottom 50px
				ul
					text-align center
					li
						display inline-block
						margin 0 15px
						a
							font-size 21px
							font-weight 600
							color #ff72a2
							border-bottom 3px solid #ff72a2
							&:hover
								border-bottom 3px solid #fff
						&.current a
							color #000
							border-bottom 3px solid #fff
			&__detail
				ul
					li
						display none
						&.current
							display block
				&__head
					display flex
					flex-wrap wrap
					align-items center
					justify-content space-between
					width 660px
					margin 0 auto 75px
					&__thumb
						width 325px
						padding-left 35px
						img
							width 240px
					&__text
						width 334px
						strong
							display block
							font-size 36px
							font-weight 600
							letter-spacing 0.05em
							margin-bottom 10px
						p
							font-size 14px
							font-weight 600
							letter-spacing 0.05em
				&__body
					width 660px
					margin 0 auto
					p
						font-size 16px
						font-weight 600
						line-height 32px
						strong
							font-size 24px
						+ p
							padding-top 1.6em
						a
							overflow visible
							position relative
							display inline-block
							line-height 1.4
							color #ff72a2
							letter-spacing 0.05em
							border-bottom 2px solid #ff72a2
							&:hover
								text-decoration none
								border-bottom 2px solid #fff

		&-member
			margin-bottom 150px
			.company__wrap
				overflow hidden
				padding 80px 0 20px
			&__title
				text-align center
				margin-bottom 80px
				img
					height 32px
			&__detail
				width 800px
				margin 0 auto
				dl
					display flex
					flex-wrap wrap
					align-items center
					justify-content space-between
					margin-bottom 80px
					dt
						width 270px
					dd
						width 480px
				&__pct
					overflow hidden
					width 270px
					img
						width 540px
					.current &
						img
							position relative
							left -270px
				&__text
					font-weight 600
					span
						display block
						font-size 16px
						letter-spacing 0.05em
						margin-bottom 14px
					strong
						display block
						font-size 24px
						letter-spacing 0.05em
						margin-bottom 28px
					p
						font-size 16px
						line-height 32px
						a
							overflow visible
							position relative
							display inline-block
							line-height 1.5
							color #ff72a2
							letter-spacing 0.05em
							border-bottom 2px solid #ff72a2
							&[target="_blank"]::after
								content ''
								overflow hidden
								position absolute
								right -25px
								top 4px
								width 19px
								height 16px
								background url(/assets/images/common/ico-blank.svg) center top
								background-size 19px 32px
							&:hover
								text-decoration none
								border-bottom 2px solid #fff

		&-info
			margin-bottom 160px
			.company__wrap
				overflow hidden
				padding 80px 0 100px
			&__title
				text-align center
				margin-bottom 80px
				img
					height 32px
			&__detail
				width 800px
				margin 0 auto
				border-top 1px solid #a6a6a6
				table
					width 100%
					font-size 18px
					font-weight 600
					line-height 38px
					tr
						th
							text-align left
							width 170px
							vertical-align top
							padding-top 21px
							padding-left 10px
							padding-bottom 22px
							border-bottom 1px solid #a6a6a6
						td
							width 630px
							padding-top 21px
							padding-bottom 22px
							border-bottom 1px solid #a6a6a6
							a
								overflow visible
								position relative
								display inline-block
								line-height 1.5
								color #ff72a2
								letter-spacing 0.05em
								border-bottom 2px solid #ff72a2
								&[target="_blank"]::after
									content ''
									overflow hidden
									width 19px
									height 16px
									background url(/assets/images/common/ico-blank.svg) center top
									background-size 19px 32px
								&:hover
									text-decoration none
									border-bottom 2px solid #ffffff


//IE11
@media all and (-ms-high-contrast: none)
	body
		margin 0
		padding 0


//SP
@media screen and (max-width 768px)

	.company
		&__wrap
			width 84%
			background-color #fff
			border-radius 8px
			box-shadow 0px 0px 2px rgba(0,0,0,0.15)
			margin 0 auto

		&-pagelink
			width 84%
			margin 0 auto
			&__list
				padding-bottom 15px
				ul
					display flex
					flex-wrap wrap
					justify-content space-between
					li
						width 48.4%
						margin-bottom 15px
						a
							display block
							width 100%
							height 30px
							background-color #ffe600
							border-radius (30px/2)
							border 2px solid #000
							margin 0 auto
							&:active
								text-decoration none
								background url(/assets/images/common/bg-btn-hover-pink.png)
								background-size (118px/2) (26px/2)
								animation btnStripe59 2s infinite linear
							span
								position relative
								display block
								text-align center
								width 100%
								height 100%
								font-size 12px
								font-weight 600
								line-height 28px
								color #000
								&::after
									content ''
									position absolute
									top calc(50% - 6px)
									right 7px
									width 13px
									height 13px
									background url(/assets/images/common/ico-btn-bottom.svg)
									background-size 13px 13px

		&-vision
			margin-bottom 75px
			.company__wrap
				overflow hidden
				text-align center
				padding 0 0 45px
			&__pct
				margin-bottom 39px
				img
					width 100%
			&__title
				margin-bottom 40px
				img
					height 18px
			&__lead
				margin-bottom 34px
				img
					height 85px
			&__text
				padding 0 20px
				p
					font-size 12px
					font-weight 600
					line-height 24px

		&-message
			margin-bottom 75px
			.company__wrap
				overflow hidden
				padding 40px 0 35px
			&__title
				text-align center
				margin-bottom 25px
				img
					height 18px
			&__tab
				margin-bottom 25px
				ul
					text-align center
					li
						display inline-block
						margin 0 5px
						a
							font-size 14px
							font-weight 600
							color #ff72a2
							border-bottom 2px solid #ff72a2
							&:active
								border-bottom 2px solid #fff
						&.current a
							color #000
							border-bottom 3px solid #fff
			&__detail
				ul
					li
						display none
						&.current
							display block
				&__head
					text-align center
					margin-bottom 32px
					&__thumb
						width 120px
						margin 0 auto 17px
						img
							width 100%
					&__text
						strong
							display block
							font-size 20px
							font-weight 600
							letter-spacing 0.05em
							margin-bottom 4px
						p
							font-size 10px
							font-weight 600
							letter-spacing 0.05em
				&__body
					//text-align center
					padding 0 20px
					p
						font-size 12px
						font-weight 600
						line-height 24px
						strong
							font-size 16px
						+ p
							padding-top 1.6em
					a
						position relative
						overflow visible
						display inline
						color #ff72a2
						letter-spacing 0.05em
						border-bottom 1px solid #ff72a2
						&:active
							text-decoration none
							border-bottom 1px solid #fff

		&-member
			margin-bottom 75px
			.company__wrap
				overflow hidden
				padding 36px 0 44px
			&__title
				text-align center
				margin-bottom 37px
				img
					height 18px
			&__detail
				margin 0 auto
				dl
					margin-bottom 52px
					&:nth-last-of-type(1)
						margin-bottom 0
					dt
						width 100%
					dd
						width 100%
				&__pct
					overflow hidden
					width 150px
					margin 0 auto 20px
					img
						width 300px
					.current &
						img
							position relative
							left -150px
				&__text
					font-weight 600
					span
						display block
						text-align center
						font-size 12px
						letter-spacing 0.05em
						margin-bottom 14px
					strong
						display block
						text-align center
						font-size 17px
						letter-spacing 0.05em
						margin-bottom 15px
					p
						font-size 12px
						line-height 24px
						padding 0 20px
						a
							position relative
							overflow visible
							display inline
							color #ff72a2
							letter-spacing 0.05em
							border-bottom 1px solid #ff72a2
							&:active
								text-decoration none
								border-bottom 1px solid #fff
							&[target="_blank"]::after
								// position absolute
								bottom 4px
								right -13px
								content ""
								overflow hidden
								display inline-block
								width 10px
								height 8px
								background url(/assets/images/common/ico-blank.svg) center top
								background-size 10px 16px

		&-info
			margin-bottom 95px
			.company__wrap
				overflow hidden
				padding 40px 0 50px
			&__title
				text-align center
				margin-bottom 37px
				img
					height 18px
			&__detail
				margin 0 20px
				border-top 1px solid #a6a6a6
				table
					width 100%
					font-size 12px
					font-weight 600
					line-height 24px
					tr
						th
							text-align left
							vertical-align top
							padding-top 10px
							padding-bottom 11px
							border-bottom 1px solid #a6a6a6
						td
							padding-top 10px
							padding-bottom 11px
							border-bottom 1px solid #a6a6a6
							a
								position relative
								overflow visible
								display inline
								color #ff72a2
								letter-spacing 0.05em
								border-bottom 1px solid #ff72a2
								&:active
									text-decoration none
									border-bottom 1px solid #fff
								&[target="_blank"]::after
									position absolute
									bottom 4px
									right -13px
									content ""
									overflow hidden
									display inline-block
									width 10px
									height 8px
									background url(/assets/images/common/ico-blank.svg) center top
									background-size 10px 16px