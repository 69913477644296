a
	vertical-align: baseline
	text-decoration: none
	&:link,
	&:visited,
	&:hover
		text-decoration: none
	//&:hover
	//	text-decoration: underline

html,body
	width: 100%
	word-break: break-all
	-webkit-text-size-adjust: none

html
	//font-family Univers, 'Univers LT Std', Helvetica, Arial, YuGothic, 'Yu Gothic Medium', 'Hiragino Kaku Gothic ProN', sans-serif
	font-family "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif
	font-weight 400

*, *:before, *:after
	-webkit-box-sizing: border-box
	-moz-box-sizing: border-box
	box-sizing: border-box


// KEY FRAME ANIME
@keyframes btnStripe42 {
	0% {  background-position-x: 0;  }
	100% {  background-position-x: 42px;  }
}
@keyframes btnStripe59 {
	0% {  background-position-x: 0;  }
	100% {  background-position-x: 59px;  }
}
@keyframes btnStripe84 {
	0% {  background-position-x: 0;  }
	100% {  background-position-x: 84px;  }
}


//PC
@media screen and (min-width 769px)

	html,body
		overflow-x hidden

	.sp-view
		display none
	.sp-narrow-view
		display none

	.wave
		width 100%
		height 15px
		background-color transparent

	.header
		margin-bottom 95px
		//コンテンツが短い場合、フッタが下に張り付くように高さ設定
		+ section
			min-height calc(100vh - 515px)
		&__wrap
			//max-width 1200px
			min-width 960px
			padding 24px 30px 0
			margin 0 auto
		&__logo
			margin-bottom 185px
			a
				position absolute
				left 30px
				img
					width 170px
		&__title
			text-align center
			span
				display inline-block
				padding-bottom 20px
				border-bottom 3px solid #000
				img
					height 30px
				// IE11用SVG画像指定（小さい順）
				&.briefing
					width 94px
				&.news
					width 118px
				&.company
					width 123px
				&.studentdetail
				&.studentedit
				&.studentadd
					width 126px
				&.childdetail
					width 150px
				&.management
				&.selectclass
					width 156px
				&.report
					width 174px
				&.baseinfoedit
					width 186px
				&.shortschool
					width 189px
				&.faq
				&.accountdelete
					width 206px
				&.archiveshortschool
					width 207px
				&.childinfo
					width 214px
				&.studentdelete
					width 218px
				&.cookies
					width 233px
				&.privacy
				&.mypagetop
				&.childdelete
					width 248px
				&.regularschool
					width 272px
				&.archive
					width 276px
				&.confirm
					width 278px
				&.terms
					width 513px
				&.law
					width 375px
				&.privacypolicy
					width 402px
				&.about
					width 428px
				&.parentsinfo
					width 446px
					img
						height 29px
				&.aboutdelete
					width 326px
					img
						margin-left -76px
						height 82px
				&.portraitrights
					width 567px
					img
						height 28px


	.lowerPage
		position relative
		background-color #ffe600
		min-height 100vh
		padding-bottom 570px
		//完了画面などページが短い場合（footerの.fixedfooterと併せて）
		&.isshort
			position relative
			height 100vh
			min-height 700px

	.pagenotfound
		text-align center
		padding-top calc(50vh - 120px)
		&__logo
			margin-bottom 50px
			img
				width 100px
		&__text
			font-size 18px
			font-weight 600

	.browser
		text-align center
		padding-top 32px
		padding-bottom 140px
		&__logo
			margin-bottom 75px
			img
				width 302px
		&__text
			font-size 16px
			line-height 34px
			font-weight 600
			margin-bottom 68px
		&__link
			ul
				li
					margin-bottom 23px
			a
				overflow visible
				position relative
				display inline-block
				font-size 16px
				font-weight 600
				line-height 1.4
				color #000
				letter-spacing 0.05em
				&:hover
					color #ff72a2
					&[target="_blank"]::after
						content ''
						overflow hidden
						position absolute
						right -25px
						top 2px
						width 19px
						height 16px
						background url(/assets/images/common/ico-blank.svg) center top
						background-size 19px 32px
				&[target="_blank"]::after
					content ''
					overflow hidden
					position absolute
					right -25px
					top 2px
					width 19px
					height 16px
					background url(/assets/images/common/ico-blank.svg) center bottom
					background-size 19px 32px

	#mainLoading
		z-index: 10
	.loading
		position fixed
		top 0
		left 0
		display flex
		align-items center
		justify-content center
		width 100vw
		height 100vh
		background-color #ffe600
		//z-index 10
		&__pct
			width 100px
			height 80px
			img
				width 100%

	.bottombtn
		margin-bottom 160px
		a
			display block
			width 390px
			height 70px
			background-color #ffe600
			border-radius (70px/2)
			border 3px solid #000
			margin 0 auto
			&:hover
				text-decoration none
				background url(/assets/images/common/bg-btn-hover-pink.png)
				background-size (118px/2) (26px/2)
				animation btnStripe59 2s infinite linear
			span
				position relative
				display block
				text-align center
				width 100%
				height 100%
				font-size 18px
				font-weight 600
				line-height 67px
				color #000
		&-archive
			span
				&::after
					content ''
					position absolute
					top calc(50% - 10px)
					right 23px
					width 20px
					height 20px
					background url(/assets/images/common/ico-btn.svg)
					background-size 20px auto
		&-top
			span
				&::after
					content ''
					position absolute
					top calc(50% - 10px)
					left 23px
					width 20px
					height 20px
					background url(/assets/images/common/ico-btn.svg)
					background-size 20px auto
					transform scale(-1, 1)

	//汎用ページネーション
	.pagenation
		margin-bottom 160px
		&__wrap
			display flex
			flex-wrap wrap
			align-items center
			justify-content space-between
			width 960px
			margin 0 auto
		&__prev
		&__next
			width 70px
			height 70px
			a
				overflow hidden
				display block
				width 70px
				height 70px
				img
					width 70px
				&:hover
					img
						position relative
						top -70px
		&__prev
			transform scale(-1, 1)
		&__num
			span
				font-size 22px
		&__btn
			a
				display block
				width 390px
				height 70px
				background-color #ffe600
				border-radius (70px/2)
				border 3px solid #000
				margin 0 auto
				&:hover
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 18px
					font-weight 600
					line-height 67px
					color #000
					&::after
						content ''
						position absolute
						top calc(50% - 10px)
						left 24px
						width 20px
						height 20px
						background url(/assets/images/common/ico-btn.svg)
						background-size 20px auto
						transform scale(-1, 1)




//SP
@media screen and (max-width 768px)
	.pc-view
		display none
	.sp-narrow-view
		display none

	.wave
		width 100%
		height 15px

	.header
		margin-bottom 27px
		//コンテンツが短い場合、フッタが下に張り付くように高さ設定
		+ section
			min-height calc(100vh - 322px)
		&__wrap
			padding 15px 13px 0
		&__logo
			margin-bottom 67px
			img
				width (216px/2)
		&__title
			text-align center
			span
				display inline-block
				padding-bottom 17px
				border-bottom 2px solid #000
				img
					height 20px
				&.aboutdelete
					width 215px
					img
						margin-left -48px
						height 55px
				&.parentsinfo img
					height 18px
				&.portraitrights
					width 160px
					img
						position relative
						left -26px
						width 213px
						height auto

	.lowerPage
		position relative
		background-color #ffe600
		// min-height: calc(100vh - 100px)
		min-height: 100vh
		padding-bottom 430px
		&.isshort
			position relative
			height 100vh
			min-height 550px

	.pagenotfound
		text-align center
		padding-top: calc(50vh - 100px)
		padding-bottom 200px
		&__logo
			margin-bottom 30px
			img
				width 72px
		&__text
			font-size 11px
			font-weight 600

	.browser
		text-align center
		padding-top 100px
		padding-bottom 130px
		&__logo
			margin-bottom 30px
			img
				width 200px
		&__text
			padding 0 30px
			font-size 11px
			line-height 1.8
			font-weight 600
			margin-bottom 30px
		&__link
			ul
				li
					margin-bottom 15px
			a
				position relative
				overflow visible
				display inline
				color #000
				font-size 14px
				font-weight 600
				letter-spacing 0.05em
				&:active
					color #ff72a2
					&[target="_blank"]::after
						background url(/assets/images/common/ico-blank.svg) center top
						background-size 10px 16px
				&[target="_blank"]::after
					position absolute
					bottom 4px
					right -13px
					content ""
					overflow hidden
					display inline-block
					width 10px
					height 8px
					background url(/assets/images/common/ico-blank.svg) center bottom
					background-size 10px 16px

	.loading
		position fixed
		top 0
		left 0
		display flex
		align-items center
		justify-content center
		width 100vw
		height 100vh
		background-color #ffe600
		&__pct
			width 70px
			height 56px
			img
				width 100%

	.bottombtn
		margin-bottom 80px
		a
			display block
			width 84%
			height 60px
			background-color #ffe600
			border-radius (60px/2)
			border 2px solid #000
			margin 0 auto
			&:active
				text-decoration none
				background url(/assets/images/common/bg-btn-hover-pink.png)
				background-size (118px/2) (26px/2)
				animation btnStripe59 2s infinite linear
			span
				position relative
				display block
				text-align center
				width 100%
				height 100%
				font-size 15px
				font-weight 600
				line-height 56px
				color #000
		&-archive
			span
				&::after
					content ''
					position absolute
					top calc(50% - 12px)
					right 18px
					width 24px
					height 24px
					background url(/assets/images/common/ico-btn.svg)
					background-size 24px auto
		&-top
			span
				&::after
					content ''
					position absolute
					top calc(50% - 12px)
					left 18px
					width 24px
					height 24px
					background url(/assets/images/common/ico-btn.svg)
					background-size 24px auto
					transform scale(-1, 1)

	//汎用ページネーション
	.pagenation
		margin-bottom 85px
		&__wrap
			display flex
			flex-wrap wrap
			align-items center
			justify-content space-between
			width 84%
			margin 0 auto
		&__prev
		&__next
			a
				overflow hidden
				display block
				width 32px
				height 32px
				&:active
					img
						position relative
						top -32px
		&__prev
			transform scale(-1, 1)
		&__num
			span
				font-size 15px
		&__btn
			margin-top 37px
			a
				display block
				width 84%
				height 60px
				background-color #ffe600
				border-radius (60px/2)
				border 2px solid #000
				margin 0 auto
				&:active
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 15px
					font-weight 600
					line-height 56px
					color #000
					&::after
						content ''
						position absolute
						top calc(50% - 12px)
						left 18px
						width 24px
						height 24px
						background url(/assets/images/common/ico-btn.svg)
						background-size 24px auto
						transform scale(-1, 1)


//SP　狭い場合
@media screen and (max-width 340px)

	.sp-narrow-view
		display block
