//PC
@media screen and (min-width 769px)

	.about
		&__wrap
			width 960px
			background-color #fff
			border-radius 16px
			box-shadow 0px 0px 4px rgba(0,0,0,0.15)
			margin 0 auto

		&-lead
			margin-bottom 150px
			.about__wrap
				display flex
				align-items center
				justify-content center
				height 527px
				background url(/assets/images/about/bg-content.jpg) center top no-repeat
				background-size 100% auto
			&__title
				margin-bottom 87px
				img
					height 86px
			&__text
				text-align center
				p
					font-size 18px
					font-weight 600
					line-height 46px
					+ p
						padding-top 2.6em

		&-detail
			margin-bottom 40px
			.about__wrap
				text-align center
				padding 86px 0 90px
			&__title
				margin-bottom 80px
				span
					display inline-block
					padding-bottom 20px
					border-bottom 3px solid #000
					img
						width 252px
						height 30px
			&__text
				width 800px
				text-align left
				font-size 16px
				font-weight 600
				letter-spacing 0.05em
				line-height 32px
				margin 0 auto 144px
				&:nth-last-of-type(1)
					margin-bottom 0
			&__pct
				position relative
				width 640px
				margin 0 auto 47px
				&__bg
					img
						width 640px
				&__title
					position absolute
					top 0
					left 0
					width 100%
					height 100%
					span
						position absolute
						left 50%
						top 50%
						margin-top -30px
						margin-left -150px
						display flex
						align-items center
						justify-content center
						width 300px
						height 80px
						background-color #fff
						border 3px solid #000
						border-radius 40px
					img
						width 190px
			&__skills
				width 800px
				padding 20px 35px 50px
				border 3px solid #000
				border-radius 16px
				margin 0 auto 55px
				&__title
					text-align center
					margin-bottom 46px
					img
						width 188px
						visibility hidden
				&__list
					ul
						display flex
						flex-wrap wrap
						justify-content space-between
						li
							width 220px
							position relative
							transform translateY(20px)
							opacity 0
							transition transform 0.35s 0.25s ease-out, opacity 0.35s 0.25s ease-out
					&.active
						ul
							li
								transform translateY(0)
								opacity 1
						.about-detail__skills__list__text
							opacity 1
							transform translateY(0px)
					&__pct
						margin-left -2px
						margin-bottom 24px
						img
							width 224px
					&__text
						text-align left
						font-size 16px
						letter-spacing 0.05em
						font-weight 600
						line-height 32px
						opacity 0
						transform translateY(20px)
						transition transform 0.35s 0.5s ease-out, opacity 0.35s 0.5s ease-out

		&-bottom
			margin-bottom 50px


//IE11
@media all and (-ms-high-contrast: none)
	body
		margin 0
		padding 0


//SP
@media screen and (max-width 768px)

	.about
		&__wrap
			width 84%
			background-color #fff
			border-radius 8px
			box-shadow 0px 0px 2px rgba(0,0,0,0.15)
			margin 0 auto

		&-lead
			margin-bottom 75px
			.about__wrap
				display flex
				align-items center
				justify-content center
				height 396px
				background url(/assets/images/about/bg-content--sp.jpg) center top no-repeat
				background-size 500px auto
			&__title
				margin-bottom 30px
				img
					height 44px
			&__text
				text-align center
				p
					font-size 12px
					font-weight 600
					line-height 28px
					+ p
						padding-top 2.6em

		&-detail
			margin-bottom 0
			.about__wrap
				text-align center
				padding 36px 0 42px
			&__title
				margin-bottom 40px
				span
					display inline-block
					padding-bottom 10px
					border-bottom 2px solid #000
					img
						height 16px
			&__text
				text-align left
				font-size 12px
				font-weight 600
				line-height 24px
				padding 0 20px
				margin-bottom 72px
				&:nth-last-of-type(1)
					margin-bottom 0
			&__pct
				position relative
				padding 0 20px
				margin-bottom 33px
				&__bg
					img
						width 100%
				&__title
					position absolute
					display flex
					align-items center
					justify-content center
					top 0
					left 0
					width 100%
					height 100%
					span
						display flex
						align-items center
						justify-content center
						width 180px
						height 41px
						background-color #fff
						border 2px solid #000
						border-radius 20px
					img
						width 108px
			&__skills
				padding 14px 20px 12px
				margin 0 20px 35px
				border 2px solid #000
				border-radius 16px
				&__title
					text-align center
					margin-bottom 25px
					img
						visibility hidden
						width 107px
				&__list
					ul
						li
							position relative
							margin-bottom 50px
							transform translateY(20px)
							opacity 0
							transition transform 0.35s 0.25s ease-out, opacity 0.35s 0.25s ease-out
					&.active
						ul
							li
								transform translateY(0)
								opacity 1
						.about-detail__skills__list__text
							opacity 1
					&__pct
						text-align center
						margin-bottom -12px
						img
							width 173px
					&__text
						text-align left
						font-size 12px
						font-weight 600
						line-height 24px
						opacity 0
						transform translateY(20px)
						transition transform 0.35s 0.5s ease-out, opacity 0.35s 0.5s ease-out

		&-bottom
			margin-bottom 78px