//===== reset ===========================
*
	margin: 0
	padding: 0
table
	border-collapse: collapse
	border-spacing: 0
fieldset,
img
	border: 0
img
	vertical-align: top
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var
	font-style: normal
	font-weight: normal
ol,
ul
	list-style: none
caption,
th
	text-align: left
h1,
h2,
h3,
h4,
h5,
h6
	font-size: 100%
	font-weight: normal
q:before,
q:after
	content: ''
abbr,
acronym
	border: 0
iframe
	border: none
input:focus
	outline: none
//===== base ===========================
// base.css, part of YUI's CSS Foundation
h1
	font-size: 138.5%
h2
	font-size: 123.1%
h3
	font-size: 108%
h1,
h2,
h3
	margin: 0 0
h1,
h2,
h3,
h4,
h5,
h6,
strong
	font-weight: bold
abbr,
acronym
	border-bottom: 1px dotted #000
	cursor: help
em
	font-style: italic
blockquote,
ul,
ol,
dl
	margin: 0
ol,
ul,
dl
	margin: 0
ol li
	list-style: none
ul li
	list-style: none
dl dd
	margin: 0
th,
td
	padding: 0
th
	font-weight: bold
	text-align: center
caption
	margin-bottom: .5em
	text-align: center
p,
fieldset,
table
	margin: 0
// HTML5 display definitions
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section
	display: block
audio,
canvas,
video
	display: inline-block
	*display: inline
	*zoom: 1
audio:not([controls])
	display: none
[hidden]
	display: none
// FireFox リンク選択時の点線を消す
a:link,
a:visited,
a:active,
a:hover
	overflow: hidden
	outline: none
::-moz-selection
	background: #eee
	/* Safari */
::selection
	background: #eee
	/* Safari */
::-moz-selection
	background: #eee
	/* Firefox */