//PC
@media screen and (min-width 769px)
	.menu-me
		a
			-webkit-tap-highlight-color:rgba(0,0,0,0);
		&__btn
			z-index 100
			position fixed
			top 30px
			right 106px
			overflow hidden
			height 62px
			img
				width 100%
			&__menu
				display block
				width 62px
				&:hover img
					position relative
					top -62px
			&__login
				display flex
				align-items center
				justify-content center
				color #000
				height 62px
				font-size 18px
				font-weight 600
				padding 0 30px
				background-color #ffe600
				border 3px solid #000
				border-radius 31px
				&:hover
					background-color #ff72a2
		&__list
			position fixed
			z-index 200
			top 0
			left 0
			width 100vw
			height 100vh
			background-color rgba(0,0,0,0.0)
			transition background-color 0.5s ease, visibility 0.5s
			user-select none
			visibility hidden
			&.open
				visibility visible
				background-color rgba(0,0,0,0.5)
				transition background-color 3s ease, visibility 3s
				.menu-me__list__wrap
					transform translateX(0px)
					// transition transform .25s ease-in
			&__wrap
				transform translateX(530px)
				transition transform .25s ease-out
				position fixed
				top 0
				right 0
				width 530px
				height 100vh
				overflow-y scroll
				padding 30px 20px 0
				background-color #ffe600
			&__head
				height 90px
				&__logo
					img
						width 164px
				&__closebtn
					position absolute
					top 30px
					right 30px
					overflow hidden
					width 60px
					height 60px
					img
						width 100%
					a:hover img
						position relative
						top -60px
			&__top
				border-top 2px solid #000
				border-bottom 2px solid #000
				a
					position relative
					text-align center
					display block
					height 72px
					font-size 20px
					line-height 72px
					font-weight 600
					color #000
					padding-left 38px
					&::after
						content ''
						position absolute
						top calc(50% - 13px)
						right 15px
						width 26px
						height 26px
						background url(/assets/images/common/ico-btn.svg) center top
						background-size 26px auto
					&:hover
						color #ff72a2
						text-decoration none
						&::after
							background url(/assets/images/common/ico-btn.svg) center bottom
							background-size 26px auto
						span::before
							background url(/assets/images/common/ico-home.svg) center bottom
							background-size 30px 60px
				span
					position relative
					display inline-block
					&::before
						content ''
						position absolute
						top calc(50% - 15px)
						left -50px
						width 30px
						height 30px
						background url(/assets/images/common/ico-home.svg) center top
						background-size 30px 60px
			&__status
				padding-bottom 33px
				border-bottom 2px solid #000
				&__title
					text-align center
					height 70px
					font-size 20px
					font-weight 600
					line-height 70px
					border-bottom 1px solid #000
					margin-bottom 42px
				&__btns
					display flex
					flex-wrap wrap
					justify-content space-between
					a
						display block
						text-align center
						width calc(100% / 3)
						color #000
						&:hover .menu-me__list__status__btns__icon img
							position relative
							top -100px
					&__icon
						overflow hidden
						display block
						height 100px
						img
							width 100px
					&__text
						display block
						font-size 18px
						font-weight 600
						line-height 28px
						padding-top 14px
			&__link
				padding-bottom 10px
				border-bottom 2px solid #000
				&__title
					text-align center
					height 70px
					font-size 20px
					font-weight 600
					line-height 70px
					border-bottom 1px solid #000
					margin-bottom 10px
				&__btns
					display flex
					flex-wrap wrap
					justify-content space-between
					a
						position relative
						display block
						width calc(100% / 2)
						height 50px
						font-size 18px
						line-height 48px
						font-weight 600
						color #000
						padding-left 20px
						&:nth-of-type(1)
							border-right 1px solid #000
						&::after
							content ''
							position absolute
							top calc(50% - 13px)
							right 15px
							width 26px
							height 26px
							background url(/assets/images/common/ico-btn.svg) center top
							background-size 26px auto
						&:hover
							color #ff72a2
							text-decoration none
							&::after
								background url(/assets/images/common/ico-btn.svg) center bottom
								background-size 26px auto
			&__btn
				padding 60px 20px 35px
				a
					display block
					width 100%
					height 70px
					background-color #ffe600
					border-radius (70px/2)
					border 3px solid #000
					margin 0 auto
					&:hover
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 18px
						font-weight 600
						line-height 66px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 10px)
							right 25px
							width 20px
							height 20px
							background url(/assets/images/common/ico-btn.svg)
							background-size 20px auto
			&__textlink
				text-align center
				font-size 16px
				font-weight 600
				padding-bottom 40px
				display flex
				flex-direction column
				a
					color #000
					margin-bottom 18px
					&:hover
						color #ff72a2
						text-decoration none


//SP
@media screen and (max-width 768px)
	.menu-me
		a
			-webkit-tap-highlight-color:rgba(0,0,0,0);
		&__btn
			position fixed
			z-index 100
			top 14px
			right 70px
			overflow hidden
			height 46px
			img
				width 100%
			&__menu
				display block
				width 46px
				&:active img
					position relative
					top -46px
			&__login
				display flex
				align-items center
				justify-content center
				color #000
				height 46px
				font-size 12px
				font-weight 600
				padding 0 18px
				background-color #ffe600
				border 2px solid #000
				border-radius 23px
				&:active
					background-color #ff72a2
		&__list
			position fixed
			z-index 200
			top 0
			left 0
			width 100vw
			height 100vh
			background-color rgba(0,0,0,0.0)
			visibility hidden
			transition background-color 0.5s, visibility 0.5s
			&.open
				visibility visible
				background-color rgba(0,0,0,0.7)
				transition background-color 3s, visibility 3s
				.menu-me__list__wrap
					transform translateX(0px)
			&__wrap
				transform translateX(440px)
				transition transform 0.25s ease-out
				position fixed
				top 0
				right 0
				width 100%
				height 100vh
				overflow-y scroll
				padding 15px 14px 0
				background-color #ffe600
			&__head
				height 58px
				&__logo
					img
						width (234px/2)
				&__closebtn
					position absolute
					top 14px
					right 14px
					overflow hidden
					width 45px
					height 45px
					img
						width 100%
					a:active img
						position relative
						top -45px
			&__top
				border-top 2px solid #000
				border-bottom 2px solid #000
				a
					position relative
					text-align center
					display block
					height 52px
					font-size 12px
					line-height 50px
					font-weight 600
					color #000
					padding-left 38px
					&::after
						content ''
						position absolute
						top calc(50% - 8px)
						right 9px
						width 17px
						height 17px
						background url(/assets/images/common/ico-btn.svg) center top
						background-size 17px auto
					&:active
						color #ff72a2
						text-decoration none
						&::after
							background url(/assets/images/common/ico-btn.svg) center bottom
							background-size 17px auto
						span::before
							background url(/assets/images/common/ico-home.svg) center bottom
							background-size 20px 40px
				span
					position relative
					display inline-block
					&::before
						content ''
						position absolute
						top calc(50% - 10px)
						left -28px
						width 20px
						height 20px
						background url(/assets/images/common/ico-home.svg) center top
						background-size 20px 40px
			&__status
				padding-bottom 25px
				border-bottom 2px solid #000
				&__title
					text-align center
					height 50px
					font-size 14px
					font-weight 600
					line-height 48px
					border-bottom 1px solid #000
					margin-bottom 28px
				&__btns
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					a
						display block
						text-align center
						width calc(100% / 3)
						color #000
						&:active .menu-me__list__status__btns__icon img
							position relative
							top -70px
					&__icon
						overflow hidden
						display block
						height 70px
						img
							width 70px
					&__text
						display block
						font-size 12px
						font-weight 600
						line-height 18px
						padding-top 7px
			&__link
				padding-bottom 5px
				border-bottom 2px solid #000
				&__title
					text-align center
					height 50px
					font-size 14px
					font-weight 600
					line-height 48px
					border-bottom 1px solid #000
					margin-bottom 5px
				&__btns
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					a
						position relative
						display block
						width calc(100% / 2)
						height 40px
						font-size 12px
						line-height 38px
						font-weight 600
						color #000
						padding-left 10px
						&:nth-of-type(1)
							border-right 1px solid #000
						&::after
							content ''
							position absolute
							top calc(50% - 8px)
							right 9px
							width 17px
							height 17px
							background url(/assets/images/common/ico-btn.svg) center top
							background-size 17px auto
						&:active
							color #ff72a2
							text-decoration none
							&::after
								background url(/assets/images/common/ico-btn.svg) center bottom
								background-size 17px auto
			&__btn
				padding 30px 20px 15px
				a
					display block
					width 100%
					height 45px
					background-color #ffe600
					border-radius (45px/2)
					border 2px solid #000
					margin 0 auto
					&:active
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 14px
						font-weight 600
						line-height 42px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 8px)
							right 12px
							width 17px
							height 17px
							background url(/assets/images/common/ico-btn.svg)
							background-size 17px auto
			&__textlink
				text-align center
				font-size 11px
				padding-bottom 40px
				font-weight 600
				display flex
				flex-direction column
				a
					color #000
					margin-bottom 10px
					&:active
						color #ff72a2
						text-decoration none
