//PC
@media screen and (min-width 769px)

	.reportindex
		&__wrap
			width 960px
			background-color #fff
			box-shadow 0px 0px 4px rgba(0,0,0,0.15);
			border-radius 16px
			margin 0 auto 60px
		&__list
			padding 60px 78px 50px
			ul
				li
					border-bottom 1px solid #bcbcbc
					&:nth-last-of-type(1)
						border-bottom 0px
					a
						position relative
						display flex
						flex-wrap wrap
						align-items center
						justify-content space-between
						font-size 16px
						line-height 38px
						letter-spacing 0.08em
						color #000
						padding 18px 50px 20px 0
						&::after
							content ''
							position absolute
							right 0
							top calc(50% - 10px)
							width 20px
							height 20px
							background url(/assets/images/common/ico-btn.svg) center top
							background-size 20px auto
						&:hover
							text-decoration none
							&::after
								background url(/assets/images/common/ico-btn.svg) center bottom
								background-size 20px auto
							.reportindex__list__title
								color #ff72a2
			&__thumb
				overflow hidden
				position relative
				display block
				width 100px
				height 100px
				border-radius 8px
				img
					height 100%
					position absolute
					top 50%
					left 50%
					transform translateY(-50%) translateX(-50%)
			&__text
				width calc(100% - 132px)
			&__date
				display block
				margin-bottom 5px
			&__title
				font-size 20px
				font-weight 600
	/*
	.report
		&__wrap
			width 960px
			background-color #fff
			border-radius 16px
			box-shadow 0px 0px 4px rgba(0,0,0,0.15)
			margin 0 auto

		&-detail
			margin-top -22px
			margin-bottom 80px
			.report__wrap
				padding 77px 80px 90px
			&__head
				padding-bottom 56px
				border-bottom 1px solid #a6a6a6
				margin-bottom 60px
				&__date
					font-size 18px
					letter-spacing 0.08em
					margin-bottom 32px
				&__title
					font-size 24px
					letter-spacing 0.05em
					font-weight 600
			&__body
				h2
					font-size 20px
					letter-spacing 0.05em
					font-weight 600
					margin-bottom 1.1em
				p
					font-size 16px
					letter-spacing 0.05em
					font-weight 600
					line-height 38px
					margin-bottom 1.6em
					+ figure
						padding-top 18px
					&:nth-last-of-type(1)
						margin-bottom 10px
				figure
					margin-bottom 50px
					+ p
						margin-top -5px
					img
						width 100%
				a
					overflow visible
					position relative
					display inline-block
					line-height 1.9
					color #ff72a2
					letter-spacing 0.05em
					border-bottom 2px solid #ff72a2
					&:hover
						text-decoration none
						border-bottom 2px solid #fff
					&[target="_blank"]::after
						content ''
						overflow hidden
						position absolute
						right -25px
						top 7px
						width 19px
						height 16px
						background url(/assets/images/common/ico-blank.svg) center top
						background-size 19px 32px
	*/


//SP
@media screen and (max-width 768px)

	.reportindex
		&__wrap
			width 84%
			background-color #fff
			box-shadow 0px 0px 2px rgba(0,0,0,0.15);
			border-radius 8px
			padding 15px 0 12px
			margin 0 auto 25px
		&__list
			margin 0 20px
			ul
				li
					border-bottom 1px solid #bcbcbc
					&:nth-last-of-type(1)
						border-bottom 0px
					a
						position relative
						display flex
						flex-wrap wrap
						align-items center
						justify-content space-between
						letter-spacing 0.08em
						color #000
						padding 15px 30px 11px 0
						&::after
							content ''
							position absolute
							right 0
							top calc(50% - 6px)
							width 13px
							height 13px
							background url(/assets/images/common/ico-btn.svg) center top
							background-size 13px auto
						&:hover
							text-decoration none
						&:active
							text-decoration none
							&::after
								background url(/assets/images/common/ico-btn.svg) center bottom
								background-size 13px auto
							.top-report__list__title
								color #ff72a2
			&__thumb
				overflow hidden
				position relative
				display block
				width 60px
				height 60px
				border-radius 4px
				img
					height 100%
					position absolute
					top 50%
					left 50%
					transform translateY(-50%) translateX(-50%)
			&__text
				overflow hidden
				width calc(100% - 70px)
				height 4rem
			&__date
				display block
				font-size 9px
				margin-bottom 3px
			&__title
				display block
				font-size 12px
				line-height 24px
				font-weight 600

	/*
	.report
		&__wrap
			width 84%
			background-color #fff
			border-radius 8px
			box-shadow 0px 0px 2px rgba(0,0,0,0.15)
			margin 0 auto

		&-detail
			margin-bottom 40px
			.report__wrap
				padding 42px 20px 30px
			&__head
				padding-bottom 26px
				border-bottom 1px solid #a6a6a6
				margin-bottom 27px
				&__date
					font-size 12px
					letter-spacing 0.08em
					margin-bottom 14px
				&__title
					font-size 17px
					line-height 25px
					letter-spacing 0.05em
					font-weight 600
			&__body
				h2
					font-size 14px
					font-weight 600
					line-height 24px
					margin-bottom 1.4em
				p
					font-size 12px
					font-weight 600
					line-height 24px
					margin-bottom 1.6em
					+ figure
						padding-top 5px
					&:nth-last-of-type(1)
						margin-bottom 10px
				figure
					margin-bottom 30px
					+ p
						margin-top -5px
					img
						width 100%
				a
					position relative
					overflow visible
					display inline
					color #ff72a2
					letter-spacing 0.05em
					border-bottom 1px solid #ff72a2
					&:active
						text-decoration none
						border-bottom 1px solid #fff
					&[target="_blank"]::after
						position absolute
						bottom 4px
						right -13px
						content ""
						overflow hidden
						display inline-block
						width 10px
						height 8px
						background url(/assets/images/common/ico-blank.svg)
						background-size 10px 16px
	*/
