//PC
@media screen and (min-width 769px)
	.footer
		position relative
		text-align center
		height 570px
		padding-top 60px
		padding-bottom 10px
		background-color #fff
		.lowerPage &
			position absolute
			bottom 0
			left 0
			width 100%
		.wave
			position absolute
			top -14px
			left 0
		&__title
			margin-bottom 14px
			img
				width (292px/2)
		&__copyright
			color #888
			font-size 8px

		&__inquiry
			&--icon
				width 110px
				margin 0 auto 15px

			&--title
				margin-bottom 25px
				font-size 24px
				letter-spacing 2px

			&--msg
				margin-bottom 22px
				font-size 16px
				line-height 32px
				letter-spacing 1px

			&--btn
				margin-bottom 78px
				a
					display block
					width 410px
					height 70px
					background-color #ffe600
					border-radius (70px/2)
					border 3px solid #000
					margin 0 auto
					&:hover
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						border 3px solid #000
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 18px
						font-weight 600
						line-height 67px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 10px)
							right 23px
							width 20px
							height 20px
							background url(/assets/images/common/ico-btn.svg)
							background-size 20px auto


	.fixedfooter
		position absolute
		bottom 0
		left 0
		width 100%


//SP
@media screen and (max-width 768px)
	.footer
		position relative
		text-align center
		height 430px
		padding-top 40px
		padding-bottom 5px
		background-color #fff
		.lowerPage &
			position absolute
			bottom 0
			left 0
			width 100%
		.wave
			position absolute
			top -14px
			left 0
		&__title
			margin-bottom 18px
			img
				width (256px/2)
		&__copyright
			color #888
			font-size 8px
			margin-bottom 10px

		&__inquiry
			&--icon
				width 80px
				margin 0 auto 15px

			&--title
				margin-bottom 30px
				font-size 17px
				letter-spacing 2px

			&--msg
				margin-bottom 20px
				font-size 12px
				line-height 21px
				letter-spacing 1px

			&--btn
				margin-bottom 30px
				a
					display block
					width 315px
					height 50px
					background-color #ffe600
					border-radius (90px/2)
					border 2px solid #000
					margin 0 auto
					&:hover
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						border 2px solid #000
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 13px
						font-weight 600
						line-height 47px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 9px)
							right 17px
							width 18px
							height 18px
							background url(/assets/images/common/ico-btn.svg)
							background-size 18px auto

	.fixedfooter
		position absolute
		bottom 0
		left 0
		width 100%
