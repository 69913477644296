/* NOTE: どんなCSSが最適か */

._hide{
	opacity: 0;
	z-index: -100;
}

._show {
	transition: 0.5s all;
	opacity: 1;
	z-index: 100;
}