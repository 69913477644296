//PC
@media screen and (min-width 769px)

	.mypage
		//トップページのリスト部分
		&-list
			width 960px
			margin 0 auto 37px
			> ul
				padding-bottom 25px

			//見出し
			&__title
				display flex
				flex-wrap wrap
				align-items center
				justify-content space-between
				margin-bottom 20px
				span
					font-size 24px
					font-weight 600
				&-report
					display inline-block
					line-height 1.8
					height 41px
					padding-left 50px
					background url(/assets/images/me/ico-report.svg) left center no-repeat
					background-size 40px auto
				&-child
					display inline-block
					line-height 1.8
					height 41px
					padding-left 60px
					background url(/assets/images/me/ico-child.svg) left center no-repeat
					background-size 50px auto
				&__btn
					a
						display block
						width 160px
						height 40px
						background-color #ffe600
						border-radius (40px/2)
						border 3px solid #000
						margin 0 auto
						&:active
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-pink.png)
							background-size (118px/2) (26px/2)
							animation btnStripe59 2s infinite linear
						span
							position relative
							display block
							text-align center
							width 100%
							height 100%
							font-size 18px
							font-weight 600
							line-height 36px
							padding-right 3px
							color #000
							&::after
								content ''
								position absolute
								top calc(50% - 10px)
								right 10px
								width 20px
								height 20px
								background url(/assets/images/common/ico-btn.svg)
								background-size 20px auto
			//各ブロック
			&-block
				overflow hidden
				display flex
				color #000
				background-color #fff
				border-radius 16px
				box-shadow 0px 0px 4px rgba(0,0,0,0.2)
				margin-bottom 40px
				//PCのみ1行パターン
				&-line1
					display flex
					align-items center
					width 100%
					padding 20px 40px 20px 30px
					.mypage-list__user
						width 210px
						height 80px
						border-right 1px solid #c0c0c0
						&__name
							width 140px
					.mypage-list__schooltitle
						height auto
						margin 0
						padding 0
						//以上上書きのリセット分
						width 530px
						font-size 16px
						line-height 38px
						padding-left 40px
					.mypage-list__user__status
						width 158px
						text-align right
					.mypage-list__text
						width 585px
					//子どもの場合
					&-child
						display flex
						align-items center
						width 100%
						padding 20px 40px 20px 30px
						.mypage-list__report
							width 202px
						.mypage-list__text
							width 695px
				//詳細用
				&.pcdetail
					display block
					margin-bottom 60px

			//PCのみ各wrap
			// - マイページトップ版
			&__pcwrap-index
				width calc(100% - 480px)
				padding 0 40px
			&__pcwrap-index2
				overflow hidden
				display flex
				padding-top 25px
				border-top 1px solid #cacaca
				margin-top 20px
			// - 詳細版
			&__pcwrap-detail
				display flex
				align-items center
				justify-content space-between
				margin 0 80px 58px
				.mypage-list__btn
					padding 0
					width 312px
					margin-right 10px
				&.top //トップに来た場合 上パディング
					padding-top 65px
			&__pcwrap-detail2
				display flex
				align-items center
				justify-content space-between
				padding 58px 0 70px 10px
				border-top 1px solid #c0c0c0
				margin 0 80px
				.mypage-list__btns
					padding 0
					width 312px
					margin-right 10px

			&__pct
				overflow hidden
				position relative
				width 480px
				height 360px
				.wave
					position absolute
					left 465px
					top 360px
					transform-origin 0 0
					transform rotate(-90deg)
				img
					position absolute
					height 100%
					top 50%
					left 50%
					transform translateY(-50%) translateX(-50%)
					-webkit-transform translateY(-50%) translateX(-50%)
				&__icon
					position absolute
					top 10px
					left 10px
					width 94px
				//詳細用
				.pcdetail &
					width 960px
					height 350px
					.wave
						position absolute
						left 0
						top auto
						bottom 2px
						display block
						transform rotate(0deg)
						transform scale(1.5, 1.5)
					img
						width 100%
						height auto

			&__schooltitle
				overflow hidden
				height 120px //テキスト2行まで
				font-size 24px
				letter-spacing 0.05em
				font-weight 600
				line-height 40px
				padding-top 35px
				margin-bottom 10px
				&-detail //詳細用
					text-align center
					font-size 28px
					letter-spacing 0.05em
					font-weight 600
					line-height 54px
					padding 50px 80px 15px
					small
						display block
						font-size 16px
						padding-top 6px

			&__user
				display flex
				flex-wrap wrap
				align-items center
				justify-content space-between
				&__thumb
					overflow hidden
					width 60px
					height 60px
					border-radius 50%
					img
						width 100%
				&__name
					width calc(60% - 60px)
					font-size 20px
					font-weight 600
					line-height 1.5
					padding-left 20px
				&__onlyname //文字のみ
					font-size 24px
					font-weight 600
					line-height 1.5
					letter-spacing 0.03em
					span
						display block
						font-size 16px
						padding-top 15px
				&__status
					text-align right
					width 40%
					img
						height 28px
					span
						display inline-block
				&-detail //詳細用
					display flex
					flex-wrap wrap
					align-items center
					width 460px
					padding-left 10px
					.mypage-list__user__thumb
						width 90px
						height 90px
					.mypage-list__user__name
						width auto
						font-size 24px
					.mypage-list__user__status
						text-align left
						width auto
						padding-left 20px
				&-top //トップに来た場合（お子様管理）
					display flex
					flex-wrap wrap
					align-items center
					justify-content space-between
					.mypage-list__user__thumb
						width 150px
						height 150px
					.mypage-list__user__name
						width calc(100% - 150px)
						font-size 24px

			&__date
				width 190px
				letter-spacing 0.05em
				padding-top 2px
				span
					display block
					font-size 16px
					margin-bottom 12px
				p
					font-size 16px
					font-weight 600
					line-height 30px
				&-detail //詳細用
					letter-spacing 0.05em
					span
						display block
						font-size 16px
						margin-bottom 10px
					p
						width 470px
						font-size 20px
						font-weight 600
						line-height 40px

			&__btns
				display flex
				flex-wrap wrap
				justify-content space-between
				width 210px
				a
					display block
					width 50%
					text-align center
					color #000
					&:hover .mypage-list__btns__icon img
						position relative
						top -70px
				// zoomのオフ時
				> span
					display block
					width 50%
					text-align center
					color #cacaca
				&__icon
					overflow hidden
					display block
					height 70px
					margin-bottom 10px
					img
						width 70px
				&__text
					display block
					font-size 11px
					font-weight 600
				//詳細用
				.pcdetail &
					width 294px
					a
						&:hover .mypage-list__btns__icon img
							position relative
							top -86px
						.mypage-list__btns__icon
							height 86px
							margin-bottom 7px
							img
								width 86px
						.mypage-list__btns__text
							font-size 12px

			&__report
				display flex
				flex-wrap wrap
				align-items center
				justify-content space-between
				width 304px
				height 80px
				border-right 1px solid #c0c0c0
				&__thumb
					overflow hidden
					width 60px
					height 60px
					border-radius 50%
					img
						width 100%
				&__name
					width calc(100% - 60px)
					font-size 20px
					font-weight 600
					line-height 1.4
					padding-left 20px

			&__text
				font-size 16px
				line-height 24px
				font-weight 600
				padding-left 40px

			&__select
				width 472px
				margin 0 auto 58px
				select
					-webkit-appearance none
					-moz-appearance none
					appearance none
					font-size 24px
					font-weight 600
					width 100%
					height 50px
					border 0px
					text-indent 1em
					background #fff url(/assets/images/me/ico-pulldown.svg) right 15px center no-repeat
					background-size 16px 14px
					border-radius 6px
					box-shadow 0px 0px 4px rgba(0,0,0,0.2)

			//ここから詳細見た目
			&__lead //リード文
				text-align center
				font-size 24px
				letter-spacing 0.05em
				font-weight 600
				line-height 50px
				padding 0 0 46px
				//border-top 1px solid #c0c0c0
				margin 0 80px

			&__btn //ボタン
				padding 0 20px 30px
				+ .mypage-list__detail
					padding-top 10px
				a
					display block
					width 100%
					height 60px
					background-color #ffe600
					border-radius (60px/2)
					border 3px solid #000
					margin 0 auto
					&:hover
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 16px
						font-weight 600
						line-height 56px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 10px)
							right 20px
							width 20px
							height 20px
							background url(/assets/images/common/ico-btn.svg)
							background-size 20px auto

			&__description //概要（コンテンツトップの説明箇所）
				padding 0 80px 60px
				&__title
					text-align center
					font-size 24px
					font-weight 600
					letter-spacing 0.08em
					line-height 1.8
					padding 50px 0
					border-top 1px solid #cacaca
				&__list
					border-top 1px solid #cacaca
					dl
						display flex
						flex-wrap wrap
						align-items center
						justify-content space-between
						font-size 16px
						letter-spacing 0.02em
						line-height 32px
						font-weight 600
						padding 15px 0
						border-bottom 1px solid #cacaca
						dt
							text-align center
							width 160px
						dd
							width calc(100% - 160px)
							padding 5px 0 5px 40px
							border-left 1px solid #cacaca
							small
								font-size 12px
								color #666666

			&__detail //詳細（表組部分など）
				margin 0 80px 60px
				&__title
					display flex
					justify-content center
					margin 0 auto
					border-top 1px solid #cacaca
					span
						position relative
						top -20px
						display inline-block
						height 40px
						font-size 18px
						line-height 38px
						letter-spacing 0.02em
						font-weight 600
						padding 0 25px
						background-color #fff
						border 1px solid #cacaca
						border-radius 20px
				&__lead
					text-align center
					font-size 24px
					line-height 50px
					font-weight 600
					margin-bottom 30px
				&__list
					border-top 1px solid #cacaca
					+ .mypage-list__detail__lead
						padding-top 40px
					&__status
						display flex
						align-items center
						justify-content center
						width 70px
						height 24px
						font-size 14px
						font-weight 600
						color #fff
						background-color #0da5ff
						border-radius 3px
						margin 0 auto
						&.absence
							background-color #cacaca
					dl
						display flex
						flex-wrap wrap
						align-items center
						justify-content space-between
						font-size 16px
						letter-spacing 0.02em
						line-height 32px
						font-weight 600
						padding 14px 0
						border-bottom 1px solid #cacaca
						dt
							text-align center
							width 160px
						dd
							width calc(100% - 160px)
							padding 9px 0 9px 40px
							border-left 1px solid #cacaca
							small
								font-size 12px
								color #666666
						//&.dtwide
						//	dt
						//		width 123px
						//	dd
						//		width calc(100% - 123px)

						// 左数字のとき
						//&.dtnarrow
						//	align-items flex-start
						//	dt
						//		padding-top 9px

				&__report //詳細内 レポート
					padding 8px 10px 0
					ul
						li
							font-size 20px
							line-height 36px
							font-weight 600
							margin-bottom 25px
							&:nth-last-of-type(1)
								margin-bottom 0
							a
								position relative
								display block
								color #000
								padding 0 45px
								background url(/assets/images/me/ico-report.svg) left 0 no-repeat
								background-size 32px auto
								&::after
									content ''
									position absolute
									top 2px
									right 0px
									width 20px
									height 20px
									background url(/assets/images/common/ico-btn.svg) center top
									background-size 20px auto
								&:hover
									color #ff72a2
									text-decoration none
									&::after
										background url(/assets/images/common/ico-btn.svg) center bottom
										background-size 20px auto

				&__school //詳細内 受講中・予約中スクール
					padding 10px 10px 0
					&__thumb
						overflow hidden
						position relative
						width 180px
						height 100px
						border-radius 8px
						img
							width 180px
							height 100px
							object-fit cover
					dl
						display flex
						flex-wrap wrap
						align-items center
						justify-content space-between
						margin-bottom 40px
						dt
							width 180px
						dd
							width calc(100% - 210px)
							font-size 16px
							font-weight 600
							line-height 32px

			&__add //お子様を追加
				padding 60px 80px
				a
					position relative
					display block
					height 70px
					font-size 24px
					line-height 70px
					font-weight 600
					color #000
					padding-left 100px
					&::before
						content ''
						overflow hidden
						position absolute
						top 0
						left 0
						width 70px
						height 70px
						background-image url(/assets/images/me/btn-add.svg)
						background-position center top
						background-size 70px auto
					&:hover
						color #ff72a2
						&::before
							background-position center bottom
			//記事がない場合
			&-empty
				&__lead
					text-align center
					font-size 16px
					font-weight 600
					padding 18px 0 50px
				&__btns
					padding-bottom 190px
					a
						display block
						width 390px
						height 70px
						background-color #ffe600
						border-radius (70px/2)
						border 3px solid #000
						margin 0 auto
						+ a
							margin-top 40px
						&:hover
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-pink.png)
							background-size (118px/2) (26px/2)
							animation btnStripe59 2s infinite linear
						span
							position relative
							display block
							text-align center
							width 100%
							height 100%
							font-size 18px
							font-weight 600
							line-height 66px
							color #000
							&::after
								content ''
								position absolute
								top calc(50% - 10px)
								right 25px
								width 20px
								height 20px
								background url(/assets/images/common/ico-btn.svg)
								background-size 20px auto


		//タブ
		&-tab
			padding-top 12px
			border-bottom 4px solid #000
			margin-bottom 60px
			ul
				display flex
				flex-wrap wrap
				justify-content space-between
				width 960px
				margin 0 auto -4px
				li
					width calc(960px / 3)
					a
						display block
						text-align center
						height 58px
						color #000
						font-size 20px
						font-weight 600
						line-height 58px
						border-bottom 4px solid #000
						&:hover
							color #ff72a2
					&.mypage-tab-current
						a
							color #ff72a2
							border-bottom-color #ff72a2


		//最下部ボタン各種
		&-morebtn
		&-backbtn
			width 390px
			margin -40px auto 155px
			a
				display block
				width 390px
				height 70px
				background-color #ffe600
				border-radius (70px/2)
				border 3px solid #000
				margin 0 auto
				&:hover
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 18px
					font-weight 600
					line-height 67px
					color #000
					&::after
						content ''
						position absolute
						transform rotate(90deg)
						top calc(50% - 10px)
						right 23px
						width 20px
						height 20px
						background url(/assets/images/common/ico-btn.svg)
						background-size 20px auto
		&-backbtn
			margin 0 auto 155px
			a
				span
					&::after
						transform: scale(-1, 1);
						top calc(50% - 10px)
						right auto
						left 23px


		//編集
		&-edit
			&-wrap
				overflow hidden
				width 960px
				padding 72px 80px
				background-color #fff
				border-radius 8px
				box-shadow 0px 0px 2px rgba(0,0,0,0.2)
				margin 0 auto 75px

			//PCのみ各wrap
			// - 入力箇所を左右に分ける
			&__pcwrap-input2col
				display flex
				flex-wrap wrap
				dl
					width 376px
					&:nth-of-type(2n-1)
						margin-right 45px

			&__title
				text-align center
				font-size 28px
				font-weight 600
				margin-bottom 32px
			&__thumb
				position relative
				width 150px
				margin 0 auto 58px
				&__pct
					overflow hidden
					width 150px
					height 150px
					border-radius 50%
					img
						width 100%
				&__edit
					position absolute
					right -2px
					bottom -4px
					width 46px
					img
						width 100%
					&-require
						position absolute
						right -23px
						bottom -3px
						font-family serif
						font-size 16px
						color #ff0000
						font-weight 400
			&__entrydate
				font-size 16px
				letter-spacing 0.03em
				font-weight 600
				padding 5px 0 38px
				dl
					display flex
					flex-wrap wrap
					justify-content space-between
					margin-bottom 15px
					dt
						width 100px
					dd
						width calc(100% - 100px)
			&__subtitle
				display flex
				align-items center
				justify-content center
				width 120px
				height 25px
				font-size 14px
				line-height 1.1
				font-weight 600
				background-color #f0f0f0
				border-radius 13px
				margin-top -20px
				margin-bottom 14px

			&__input //以下入力エリア
				dl
					margin-bottom 60px
					dt
						display flex
						flex-wrap wrap
						margin-bottom 19px
					dd
						display flex
						flex-wrap wrap
						justify-content space-between
				input[type="text"],
				input[type="email"],
				select
					-webkit-appearance none
					-moz-appearance none
					appearance none
					width 100%
					height 50px
					border 2px solid #cacaca
					font-size 16px
					font-weight 600
					text-indent 14px
					border-radius 6px
					&.error
						border-color #ff0000
				select
					background #fff url(/assets/images/me/ico-pulldown.svg) right 14px center no-repeat
					background-size 16px 14px
				// フォーム選択時の見た目
				input[type="text"],
				input[type="email"]
					&:focus
						border 2px solid #000
					&::selection
						background-color #cacaca
					&::-webkit-input-placeholder
						color #cacaca
					&::-moz-placeholder
						color #cacaca
					&:-ms-input-placeholder
						color #cacaca
				&-title
					font-size 16px
					line-height 1.1
					font-weight 600
				&-require
					position relative
					top 1px
					font-family serif
					font-size 16px
					line-height 1.1
					color #ff0000
					font-weight 400
					margin-left 10px
				//&-require
				//	display flex
				//	align-items center
				//	justify-content center
				//	width 75px
				//	height 25px
				//	font-size 14px
				//	line-height 1.1
				//	font-weight 600
				//	background-color #ffe600
				//	border-radius 6px
				&-postnumber
					display flex
					flex-wrap wrap
					dl
						width 376px
						margin-right 45px
					&-btn
						width 230px
						padding-top 35px
						a
							display block
							width 230px
							height 52px
							background-color #f0f0f0
							border-radius (52px/2)
							border 3px solid #000
							margin 0 auto
							&:hover
								background-color #ff72a2
							span
								position relative
								display block
								text-align center
								width 100%
								height 100%
								font-size 18px
								font-weight 600
								line-height 48px
								color #000
								&::after
									content ''
									position absolute
									top calc(50% - 11px)
									right 15px
									width 22px
									height 22px
									background url(/assets/images/common/ico-btn.svg)
									background-size 22px auto
				&-form
					&-1col
						position relative
						width 100%
					&-2col
						position relative
						width 47.8%
					&-birth
						&-y
							position relative
							width 27.6%
						&-m
						&-d
							position relative
							width 22.9%
						&-text
							width 5.8%
							font-size 16px
							font-weight 600
							padding-top 30px
					&-postnumber1
						width 47.8%
					&-postnumber-text
						width 4.4%
						text-align center
						font-size 16px
						line-height 50px
						font-weight 600
					&-postnumber2
						width 47.8%
					&-tel1
					&-tel2
					&-tel3
						width 29%
					&-tel-text
						width 6%
						text-align center
						font-size 16px
						line-height 50px
						font-weight 600
				&-error
					position absolute
					left 0
					bottom -25px
					font-size 14px
					font-weight 600
					color #ff0000
				&-btn
					position relative
					width 390px
					margin 0 auto 10px
					input[type="submit"]
						-webkit-appearance none
						-moz-appearance none
						appearance none
						border 0px
						display block
						width 100%
						font-size 18px
						font-weight 600
						line-height 68px
						color #000
						height 70px
						background-color #ffe600
						border-radius (70px/2)
						border 3px solid #000
						&:hover
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-pink.png)
							background-size (118px/2) (26px/2)
							animation btnStripe59 2s infinite linear
					&::after
						content ''
						position absolute
						top calc(50% - 10px)
						right 25px
						width 20px
						height 20px
						background url(/assets/images/common/ico-btn.svg)
						background-size 20px auto


		//詳細
		&-information
			padding-bottom 80px
			+ .mypage-backbtn
				margin-top -48px
			&-wrap
				overflow hidden
				width 960px
				padding 80px
				background-color #fff
				border-radius 16px
				box-shadow 0px 0px 4px rgba(0,0,0,0.2)
				margin 0 auto 80px
				+ .mypage-information-wrap
					margin-top -20px

			//PCのみ各wrap
			&__pcwrap-studentdetail
				display flex
				flex-wrap wrap
				justify-content space-between
				&-sub
					width 240px
					.mypage-information__btn a
						width 100%
						height 50px
						span
							line-height 47px
							&::after
								right 12px
				&-main
					width 480px

			//タイトル
			&__title
				text-align center
				font-size 17px
				font-weight 600
				margin-bottom 27px
			//名前のみ
			&__name
				text-align center
				font-size 24px
				letter-spacing 0.03em
				font-weight 600
				margin-bottom 35px
			//サムネ＋名前
			&__thumb
				text-align center
				margin 0 auto 35px
				&__pct
					overflow hidden
					width 150px
					height 150px
					border-radius 50%
					margin 0 auto 15px
					img
						width 100%
				&__name
					font-size 24px
					letter-spacing 0.03em
					font-weight 600
					small
						display block
						font-size 12px
						font-weight 400
						padding-top 4px
			//サムネ＋名前 ※横
			&__thumb-horizontal
				display flex
				align-items center
				justify-content center
				margin-bottom 28px
				&__pct
					overflow hidden
					width 120px
					height 120px
					border-radius 50%
					margin-right 30px
					img
						width 100%
				&__name
					font-size 24px
					letter-spacing 0.03em
					font-weight 600
			//入力情報の確認リスト
			&__list
				border-top 1px solid #cacaca
				margin-bottom 5px
				dl
					display flex
					flex-wrap wrap
					justify-content space-between
					width 100%
					font-size 16px
					letter-spacing 0.05em
					font-weight 600
					line-height 32px
					padding 26px 0
					border-bottom 1px solid #cacaca
					dt
						width 195px
						padding-left 20px
					dd
						width calc(100% - 195px)
				&__passcode
					border-bottom 1px solid #cacaca
					dl
						align-items center
						border-bottom 0px
						padding 26px 0
						dd
							display flex
							align-items center
							a
								display inline-block
								width 42px
								margin-left 18px
								img
									width 100%
					&__attention
						font-size 12px
						line-height 1.5
						color #666666
						padding 0 0 20px 20px
						padding-bottom 20px
			//罫線
			hr
				border 0px
				border-bottom 1px solid #cacaca
				margin 30px 0
			//ボタン各種
			&__btn
				+ .mypage-information__btn
					margin-top 30px
				a
					display block
					width 390px
					height 70px
					background-color #ffe600
					border-radius (70px/2)
					border 3px solid #000
					margin 0 auto
					&:hover
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 18px
						font-weight 600
						line-height 66px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 10px)
							right 22px
							width 20px
							height 20px
							background url(/assets/images/common/ico-btn.svg)
							background-size 20px auto
				//生徒登録
				&.studententry
					margin-top 35px
					a
						width 100%
						height 70px
						border-radius (70px/2)
						span
							line-height 68px
							color #000
							padding-left 22px
							background url(/assets/images/me/ico-student.svg) left 84px center no-repeat
							background-size 30px 36px
				&.bggray a
					background-color #f0f0f0
				&.back a span::after
					right auto
					left 22px
					transform scale(-1, 1)
				&.disable
					opacity 0.25
					a
						pointer-events none
						background-color #ccc
			//タイトル（罫線あり）
			&__subtitle
				font-size 24px
				font-weight 600
				line-height 32px
				padding-bottom 30px
				border-bottom 1px solid #cacaca
				margin-bottom 30px
			//テキスト
			&__text
				font-size 16px
				font-weight 600
				line-height 38px
				margin-bottom 48px
				&.pccenter
					text-align center
				a
					color #ff72a2
					border-bottom 2px solid #ff72a2
					&:hover
						border-bottom-color #fff
			//チェックボックス
			&__checkbox
				display flex
				justify-content center
				margin-bottom 65px
				label
					position relative
					display flex
					align-items center
					min-height 30px
				&__label
					font-size 16px
					line-height 1.2
					font-weight 600
					padding-left 52px
				input
					position absolute
					left -1000px
					top 0
					&:checked + .mypage-information__checkbox__toggle::after
						content ""
						display block
						position absolute
						top 0px
						left 0px
						width 33px
						height 33px
						background url(/assets/images/me/ico-checkbox-on.svg)
						background-size 100%
				&__toggle
					content ""
					position absolute
					top 0px
					left 0px
					margin auto
					width 33px
					height 33px
					background url(/assets/images/me/ico-checkbox-off.svg)
					background-size 100%


		//モーダル
		&-modal
			position fixed
			overflow-y scroll
			top 0
			left 0
			width 100%
			height 100vh
			background-color rgba(255,230,0,0.9)
			&-bg
				position absolute
				top 0
				left 0
				width 100%
				height 100vh
			&-wrap
				position absolute
				top 50%
				left 50%
				transform translateY(-50%) translateX(-50%)
				width 960px
				padding 75px 40px 70px
				background-color #fff
				border-radius 16px
				box-shadow 0px 0px 2px rgba(0,0,0,0.2)
				&.error
					width 800px
					padding 50px 80px 70px
			&-top
				margin-bottom 26px
			&__closebtn
				position absolute
				top 28px
				right 28px
				a
					img
						width 30px
			&__title
				text-align center
				font-size 28px
				font-weight 600
				margin-bottom 50px
			&__btn
				padding-bottom 17px
				a
					display block
					width 390px
					height 70px
					background-color #ffe600
					border-radius (70px/2)
					border 3px solid #000
					margin 0 auto
					&:hover
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 18px
						font-weight 600
						line-height 66px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 10px)
							right 20px
							width 20px
							height 20px
							background url(/assets/images/common/ico-btn.svg)
							background-size 20px auto
			&__passcode
				text-align center
				font-size 15px
				font-weight 600
			&__qrcode
				&__title
					text-align center
					font-size 16px
					font-weight 600
					margin-bottom 25px
					+ .mypage-modal__passcode
						margin -10px 0 10px
						font-size 16px
				&__pct
					text-align center
					margin-bottom 30px
					img
						width 150px
				&__btns
					display flex
					flex-wrap wrap
					justify-content space-between
					width 290px
					margin 0 auto
					a
						display block
						width 50%
						text-align center
						color #000
						img
							height 60px
							margin-bottom 9px
						span
							display block
							font-size 16px
							letter-spacing 0.05em
							font-weight 600
						&.disable
							opacity 0.5
			&__error
				font-size 16px
				line-height 32px
				strong
					display block
					font-weight 600
					margin-bottom 35px


	.alert
		position fixed
		z-index 20
		bottom 0
		left 0
		width 100vw
		height 230px
		padding-top 40px
		background #ffe600
		visibility hidden
		transform translateY(230px)
		transition transform 0.25s ease-out, visibility 0.25s
		&.open
			visibility visible
			transform translateY(0)
		&__text
			width 715px
			height 60px
			padding-left 80px
			margin 0 auto
			background url(/assets/images/me/ico-alert.svg) left center no-repeat
			background-size 60px 60px
			margin-bottom 30px
			p
				font-size 15px
				font-weight 600
				line-height 28px
		&__btn
			a
				display block
				width 370px
				height 70px
				background-color #ffe600
				border-radius (70px/2)
				border 3px solid #000
				margin 0 auto
				&:hover
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 18px
					font-weight 600
					line-height 67px
					color #000
					&::after
						content ''
						position absolute
						top calc(50% - 10px)
						right 23px
						width 20px
						height 20px
						background url(/assets/images/common/ico-btn.svg)
						background-size 20px auto
		&__closebtn
			position absolute
			top 16px
			right 18px
			width 22px

	//hover追加分
	a.mypage-list-block:hover
		color #ff72a2



//SP
@media screen and (max-width 768px)

	.myPage
		.header__logo
			margin-bottom 60px
		.header__title
			margin-bottom 35px

	.mypage
		//トップページのリスト部分
		&-list
			width 84%
			margin 0 auto 37px
			> ul
				padding-bottom 25px

			//見出し
			&__title
				display flex
				flex-wrap wrap
				align-items center
				justify-content space-between
				margin-bottom 20px
				span
					font-size 17px
					font-weight 600
				&-report
					display inline-block
					height 28px
					padding-left 32px
					background url(/assets/images/me/ico-report.svg) left center no-repeat
					background-size (52px/2) (56px/2)
				&-child
					display inline-block
					height 28px
					padding-left 37px
					background url(/assets/images/me/ico-child.svg) left center no-repeat
					background-size (67px/2) (56px/2)
				&__btn
					a
						display block
						width 93px
						height 30px
						background-color #ffe600
						border-radius (30px/2)
						border 2px solid #000
						margin 0 auto
						&:active
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-pink.png)
							background-size (118px/2) (26px/2)
							animation btnStripe59 2s infinite linear
						span
							position relative
							display block
							text-align center
							width 100%
							height 100%
							font-size 12px
							font-weight 600
							line-height 28px
							padding-right 3px
							color #000
							&::after
								content ''
								position absolute
								top calc(50% - 6px)
								right 9px
								width 13px
								height 13px
								background url(/assets/images/common/ico-btn.svg)
								background-size 13px auto
			//各ブロック
			&-block
				overflow hidden
				display block
				color #000
				background-color #fff
				border-radius 8px
				box-shadow 0px 0px 2px rgba(0,0,0,0.2)
				margin-bottom 26px

			&__pct
				position relative
				overflow hidden
				height 115px
				margin-bottom 10px
				.wave
					position absolute
					display block
					bottom 0
				img
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
					-webkit-transform: translateY(-50%) translateX(-50%);
					width: 100%;
				&__icon
					position absolute
					top 10px
					left 10px
					width 94px

			&__schooltitle
				font-size 17px
				letter-spacing 0.05em
				font-weight 600
				line-height 25px
				padding 20px 20px 16px
				&-detail //詳細用
					text-align center
					font-size 17px
					letter-spacing 0.05em
					font-weight 600
					line-height 25px
					padding 28px 20px 15px
					small
						display block
						font-size 12px
						padding-top 6px

			&__user
				display flex
				flex-wrap wrap
				align-items center
				justify-content space-between
				padding 0 20px
				margin-bottom 20px
				&__thumb
					overflow hidden
					width 45px
					height 45px
					border-radius 50%
					img
						width 100%
				&__name
					width calc(60% - 45px)
					font-size 12px
					font-weight 600
					line-height 1.5
					padding-left 15px
				&__onlyname //文字のみ
					font-size 17px
					font-weight 600
					line-height 1.5
					letter-spacing 0.03em
					padding-top 8px
					span
						display block
						font-size 12px
						padding-top 7px
				&__status
					text-align right
					width 40%
					img
						height 20px
					span
						display inline-block
				&-detail //詳細用
					display flex
					flex-wrap wrap
					align-items center
					justify-content space-between
					padding 0 20px
					margin-bottom 20px
					.mypage-list__user__name
						font-size 17px
				&-top //トップに来た場合
					display flex
					flex-wrap wrap
					align-items center
					justify-content space-between
					padding 20px 20px 0
					margin-bottom 20px
					.mypage-list__user__thumb
						width 60px
						height 60px
					.mypage-list__user__name
						width calc(100% - 60px)
						font-size 17px

			&__date
				letter-spacing 0.05em
				padding-top 18px
				border-top 1px solid #cacaca
				margin 0 20px 15px
				span
					display block
					font-size 12px
					margin-bottom 2px
				p
					font-size 14px
					font-weight 600
					line-height 24px
				&-detail //詳細用
					letter-spacing 0.05em
					padding-top 28px
					border-top 1px solid #cacaca
					margin 0 20px 25px
					span
						display block
						font-size 12px
						margin-bottom 10px
					p
						font-size 14px
						font-weight 600
						line-height 24px

			&__btns
				display flex
				flex-wrap wrap
				justify-content space-between
				padding 0 20px 25px
				a
					display block
					width 50%
					text-align center
					color #000
					&:active .mypage-list__btns__icon img
						position relative
						top -70px
				// zoomのオフ時
				> span
					display block
					width 50%
					text-align center
					color #cacaca
				&__icon
					overflow hidden
					display block
					height 70px
					margin-bottom 10px
					img
						width 70px
				&__text
					display block
					font-size 10px
					font-weight 600

			&__report
				display flex
				flex-wrap wrap
				align-items center
				justify-content space-between
				padding 20px
				&__thumb
					overflow hidden
					width 60px
					height 60px
					border-radius 50%
					img
						width 100%
				&__name
					width calc(100% - 60px)
					font-size 17px
					font-weight 600
					line-height 1.4
					padding-left 15px

			&__text
				font-size 12px
				font-weight 600
				line-height 24px
				padding 0 20px 20px

			&__select
				margin-bottom 27px
				select
					-webkit-appearance none
					-moz-appearance none
					appearance none
					font-size 17px
					font-weight 600
					width 100%
					height 37px
					border 0px
					text-indent 1em
					background #fff url(/assets/images/me/ico-pulldown.svg) right 14px center no-repeat
					background-size 10px 11px
					border-radius 8px
					box-shadow 0px 0px 2px rgba(0,0,0,0.2)

			&__lead //リード文
				text-align center
				font-size 17px
				letter-spacing 0.05em
				font-weight 600
				line-height 30px
				padding 0 20px 24px

			&__btn //ボタン
				padding 0 20px 30px
				+ .mypage-list__detail
					padding-top 10px
				a
					display block
					width 100%
					height 45px
					background-color #ffe600
					border-radius (45px/2)
					border 2px solid #000
					margin 0 auto
					&:active
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 14px
						font-weight 600
						line-height 42px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 8px)
							right 12px
							width 17px
							height 17px
							background url(/assets/images/common/ico-btn.svg)
							background-size 17px auto

			&__description //概要（コンテンツトップの説明箇所）
				padding 0 20px 20px
				&__title
					text-align center
					font-size 17px
					font-weight 600
					letter-spacing 0.08em
					line-height 1.8
					padding-top 23px
					margin-bottom 23px
					border-top 1px solid #cacaca
				&__list
					border-top 1px solid #cacaca
					dl
						display flex
						flex-wrap wrap
						align-items center
						justify-content space-between
						font-size 12px
						letter-spacing 0.02em
						line-height 24px
						font-weight 600
						padding 6px 0
						border-bottom 1px solid #cacaca
						dt
							text-align center
							width 75px
						dd
							width calc(100% - 75px)
							padding 5px 0 5px 19px
							border-left 1px solid #cacaca
							small
								font-size 12px
								color #666666

			&__detail //詳細（表組部分など）
				margin 0 20px 30px
				&__title
					display flex
					justify-content center
					margin 0 auto
					border-top 1px solid #cacaca
					span
						position relative
						top -13px
						display inline-block
						height 25px
						font-size 12px
						line-height 24px
						letter-spacing 0.02em
						font-weight 600
						padding 0 15px
						background-color #fff
						border 1px solid #cacaca
						border-radius 13px
				&__lead
					text-align center
					font-size 13px
					font-weight 600
					margin-bottom 20px
				&__list
					border-top 1px solid #cacaca
					+ .mypage-list__detail__lead
						padding-top 23px
					&__status
						display flex
						align-items center
						justify-content center
						width 35px
						height 17px
						font-size 9px
						font-weight 600
						color #fff
						background-color #0da5ff
						border-radius 3px
						margin 0 auto
						&.absence
							background-color #cacaca
					dl
						display flex
						flex-wrap wrap
						align-items center
						justify-content space-between
						font-size 12px
						letter-spacing 0.02em
						line-height 24px
						font-weight 600
						padding 6px 0
						border-bottom 1px solid #cacaca
						dt
							text-align center
							width 75px
						dd
							width calc(100% - 75px)
							padding 5px 0 5px 15px
							border-left 1px solid #cacaca
							small
								font-size 12px
								color #666666
						//&.dtwide
						//	dt
						//		width 123px
						//	dd
						//		width calc(100% - 123px)

						// 左数字のとき
						&.dtnarrow
							//align-items flex-start
							dt
								width 45px
								//padding-top 5px
							dd
								width calc(100% - 45px)

				&__report //詳細内 レポート
					padding-top 15px
					ul
						li
							font-size 13px
							line-height 22px
							font-weight 600
							margin-bottom 16px
							&:nth-last-of-type(1)
								margin-bottom 0
							a
								position relative
								display block
								color #000
								padding 0 32px
								background url(/assets/images/me/ico-report.svg) left 0 no-repeat
								background-size 21px auto
								&::after
									content ''
									position absolute
									top 2px
									right 0px
									width 17px
									height 17px
									background url(/assets/images/common/ico-btn.svg) center top
									background-size 17px auto
								&:active
									color #ff72a2
									text-decoration none
									&::after
										background url(/assets/images/common/ico-btn.svg) center bottom
										background-size 17px auto

				&__school //詳細内 受講中・予約中スクール
					padding-top 15px
					&__thumb
						overflow hidden
						position relative
						width 108px
						height 60px
						border-radius 4px
						img
							width 108px
							height 60px
							object-fit cover
					dl
						display flex
						flex-wrap wrap
						justify-content space-between
						margin-bottom 15px
						dt
							padding-top 5px
							width 122px
						dd
							width calc(100% - 122px)
							font-size 12px
							font-weight 600
							line-height 24px

			&__add //お子様を追加
				padding 20px
				a
					position relative
					display block
					height 60px
					font-size 17px
					line-height 60px
					font-weight 600
					color #000
					padding-left 75px
					&::before
						content ''
						overflow hidden
						position absolute
						top 0
						left 0
						width 60px
						height 60px
						background-image url(/assets/images/me/btn-add.svg)
						background-position center top
						background-size 60px auto
					&:active
						color #ff72a2
						&::before
							background-position center bottom

			//記事がない場合
			&-empty
				&__lead
					text-align center
					font-size 12px
					font-weight 600
					padding-bottom 30px
				&__btns
					padding 0 30px 90px
					a
						display block
						width 100%
						height 45px
						background-color #ffe600
						border-radius (45px/2)
						border 2px solid #000
						margin 0 auto
						+ a
							margin-top 18px
						&:active
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-pink.png)
							background-size (118px/2) (26px/2)
							animation btnStripe59 2s infinite linear
						span
							position relative
							display block
							text-align center
							width 100%
							height 100%
							font-size 13px
							font-weight 600
							line-height 42px
							color #000
							&::after
								content ''
								position absolute
								top calc(50% - 8px)
								right 12px
								width 17px
								height 17px
								background url(/assets/images/common/ico-btn.svg)
								background-size 17px auto


		//タブ
		&-tab
			margin-top -14px
			margin-bottom 40px
			ul
				display flex
				flex-wrap wrap
				justify-content space-between
				li
					width calc(100% / 3)
					a
						display block
						text-align center
						height 50px
						color #000
						font-size 14px
						font-weight 600
						line-height 50px
						border-bottom 2px solid #000
						&:active
							color #ff72a2
					&.mypage-tab-current
						a
							color #ff72a2
							border-bottom-color #ff72a2


		//最下部ボタン各種
		&-morebtn
		&-backbtn
			width 84%
			margin -52px auto 78px
			a
				display block
				width 100%
				height 45px
				background-color #ffe600
				border-radius (45px/2)
				border 2px solid #000
				margin 0 auto
				&:active
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 14px
					font-weight 600
					line-height 42px
					color #000
					&::after
						content ''
						position absolute
						transform rotate(90deg)
						top calc(50% - 8px)
						right 12px
						width 17px
						height 17px
						background url(/assets/images/common/ico-btn.svg)
						background-size 17px auto
		&-backbtn
			margin 0 auto 78px
			a
				span
					&::after
						transform: scale(-1, 1);
						top calc(50% - 8px)
						right auto
						left 12px


		//編集
		&-edit
			&-wrap
				overflow hidden
				width 84%
				padding 30px 20px
				background-color #fff
				border-radius 8px
				box-shadow 0px 0px 2px rgba(0,0,0,0.2)
				margin 0 auto 38px
			&__title
				text-align center
				font-size 17px
				font-weight 600
				margin-bottom 27px
			&__thumb
				position relative
				width 90px
				margin 0 auto 30px
				&__pct
					overflow hidden
					width 90px
					height 90px
					border-radius 50%
					img
						width 100%
				&__edit
					position absolute
					right -2px
					bottom -4px
					width 34px
					img
						width 100%
					&-require
						position absolute
						right -12px
						bottom -2px
						font-family serif
						font-size 12px
						color #ff0000
						font-weight 400
			&__entrydate
				font-size 12px
				letter-spacing 0.03em
				font-weight 600
				padding 8px 0 20px
				dl
					display flex
					flex-wrap wrap
					justify-content space-between
					margin-bottom 7px
					dt
						width 80px
					dd
						width calc(100% - 80px)
			&__subtitle
				display flex
				align-items center
				justify-content center
				width 75px
				height 15px
				font-size 9px
				line-height 1.1
				font-weight 600
				background-color #f0f0f0
				border-radius 3px
				margin-bottom 14px

			&__input //以下入力エリア
				dl
					margin-bottom 25px
					dt
						display flex
						flex-wrap wrap
						margin-bottom 8px
					dd
						display flex
						flex-wrap wrap
						justify-content space-between
				input[type="text"],
				input[type="email"],
				select
					-webkit-appearance none
					-moz-appearance none
					appearance none
					width 100%
					height 30px
					border 1px solid #cacaca
					font-size 16px
					font-weight 600
					text-indent 8px
					border-radius 3px
					&.error
						border-color #ff0000
				select
					background #fff url(/assets/images/me/ico-pulldown.svg) right 14px center no-repeat
					background-size 10px 11px
				// フォーム選択時の見た目
				input[type="text"],
				input[type="email"]
					&:focus
						border 1px solid #000
					&::selection
						background-color #cacaca
					&::-webkit-input-placeholder
						color #cacaca
					&::-moz-placeholder
						color #cacaca
					&:-ms-input-placeholder
						color #cacaca
				&-title
					font-size 12px
					line-height 1.1
					font-weight 600
				&-require
					font-size 12px
					line-height 1.1
					font-weight 400
					font-family serif
					color #ff0000
					margin-left 5px
				//&-require
				//	display flex
				//	align-items center
				//	justify-content center
				//	width 43px
				//	height 15px
				//	font-size 9px
				//	line-height 1.1
				//	font-weight 600
				//	background-color #ffe600
				//	border-radius 3px
				&-postnumber
					display flex
					flex-wrap wrap
					justify-content space-between
					dl
						width 47.8%
					&-btn
						width 47.8%
						padding-top 22px
						a
							display block
							width 100%
							height 29px
							background-color #f0f0f0
							border-radius (29px/2)
							border 2px solid #000
							margin 0 auto
							&:active
								opacity 0.8
							span
								position relative
								display block
								text-align center
								width 100%
								height 100%
								font-size 12px
								font-weight 600
								line-height 26px
								color #000
								&::after
									content ''
									position absolute
									top calc(50% - 7px)
									right 8px
									width 13px
									height 13px
									background url(/assets/images/common/ico-btn.svg)
									background-size 13px auto
				&-form
					&-1col
						position relative
						width 100%
					&-2col
						position relative
						width 47.8%
					&-birth
						&-y
							position relative
							width 27.6%
						&-m
						&-d
							position relative
							width 22.9%
						&-text
							width 5.8%
							font-size 12px
							font-weight 600
							padding-top 13px
					&-postnumber1
						width 38%
					&-postnumber-text
						width 11.4%
						text-align center
						font-size 12px
						line-height 30px
						font-weight 600
					&-postnumber2
						width 48.6%
					&-tel1
					&-tel2
					&-tel3
						width 29%
					&-tel-text
						width 6%
						text-align center
						font-size 12px
						line-height 30px
						font-weight 600
				&-error
					position absolute
					left 0
					bottom -17px
					font-size 9px
					font-weight 600
					color #ff0000
				&-btn
					position relative
					margin-bottom 10px
					input[type="submit"]
						-webkit-appearance none
						-moz-appearance none
						appearance none
						border 0px
						display block
						width 100%
						font-size 14px
						font-weight 600
						line-height 42px
						color #000
						height 45px
						background-color #ffe600
						border-radius (45px/2)
						border 2px solid #000
						margin 0 auto
						&:active
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-pink.png)
							background-size (118px/2) (26px/2)
							animation btnStripe59 2s infinite linear
					&::after
						content ''
						position absolute
						top calc(50% - 8px)
						right 12px
						width 17px
						height 17px
						background url(/assets/images/common/ico-btn.svg)
						background-size 17px auto


		//詳細
		&-information
			padding-bottom 50px
			+ .mypage-backbtn
				margin-top -48px
			&-wrap
				overflow hidden
				width 84%
				padding 40px 20px
				background-color #fff
				border-radius 8px
				box-shadow 0px 0px 2px rgba(0,0,0,0.2)
				margin 0 auto 38px
				+ .mypage-information-wrap
					margin-top -10px
			//タイトル
			&__title
				text-align center
				font-size 17px
				font-weight 600
				margin-bottom 27px
			//名前のみ
			&__name
				text-align center
				font-size 17px
				letter-spacing 0.03em
				font-weight 600
				margin-bottom 14px
			//サムネ＋名前
			&__thumb
				text-align center
				margin 0 auto 30px
				&__pct
					overflow hidden
					width 90px
					height 90px
					border-radius 50%
					margin 0 auto 15px
					img
						width 100%
				&__name
					font-size 17px
					letter-spacing 0.03em
					font-weight 600
					small
						display block
						font-size 9px
						padding-top 6px
			//サムネ＋名前 ※横
			&__thumb-horizontal
				display flex
				align-items center
				justify-content center
				margin-bottom 25px
				&__pct
					overflow hidden
					width 60px
					height 60px
					border-radius 50%
					margin-right 15px
					img
						width 100%
				&__name
					font-size 17px
					letter-spacing 0.03em
					font-weight 600
			//入力情報の確認リスト
			&__list
				border-top 1px solid #cacaca
				margin-bottom 30px
				dl
					display flex
					flex-wrap wrap
					justify-content space-between
					font-size 12px
					font-weight 600
					line-height 1.5
					padding 14px 0
					border-bottom 1px solid #cacaca
					dt
						width 41.8%
					dd
						width calc(100% - 41.8%)
				&__passcode
					border-bottom 1px solid #cacaca
					dl
						align-items center
						border-bottom 0px
						padding 10px 0
						dd
							display flex
							align-items center
							a
								display inline-block
								width 28px
								margin-left 8px
								img
									width 100%
					&__attention
						font-size 9px
						line-height 1.5
						color #666666
						padding-bottom 15px
			//罫線
			hr
				border 0px
				border-bottom 1px solid #cacaca
				margin 30px 0
			//ボタン各種
			&__btn
				+ .mypage-information__btn
					margin-top 20px
				a
					display block
					width 100%
					height 45px
					background-color #ffe600
					border-radius (45px/2)
					border 2px solid #000
					margin 0 auto
					&:active
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 14px
						font-weight 600
						line-height 42px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 8px)
							right 12px
							width 17px
							height 17px
							background url(/assets/images/common/ico-btn.svg)
							background-size 17px auto
				&.bggray a
					background-color #f0f0f0
				&.back a span::after
					right auto
					left 12px
					transform scale(-1, 1)
				&.disable
					opacity 0.25
					a
						pointer-events none
						background-color #ccc
				&.studententry
					margin 20px 0
			//タイトル（罫線あり）
			&__subtitle
				font-size 17px
				font-weight 600
				line-height 27px
				padding-bottom 24px
				border-bottom 1px solid #cacaca
				margin-bottom 12px
			//テキスト
			&__text
				font-size 12px
				font-weight 600
				line-height 24px
				margin-bottom 20px
				a
					color #ff72a2
					border-bottom 1px solid #ff72a2
					&:active
						border-bottom-color #fff
			//チェックボックス
			&__checkbox
				display flex
				justify-content center
				margin-bottom 28px
				label
					position relative
					display flex
					align-items center
					min-height 30px
				&__label
					font-size 12px
					font-weight 600
					padding-left 35px
				input
					position absolute
					left -1000px
					top 0
					&:checked + .mypage-information__checkbox__toggle::after
						content ""
						display block
						position absolute
						top 0px
						left 0px
						width 27px
						height 27px
						background url(/assets/images/me/ico-checkbox-on--sp.svg)
						background-size 100%
				&__toggle
					content ""
					position absolute
					top 0px
					left 0px
					margin auto
					width 27px
					height 27px
					background url(/assets/images/me/ico-checkbox-off--sp.svg)
					background-size 100%



		//モーダル
		&-modal
			position fixed
			overflow-y scroll
			top 0
			left 0
			width 100%
			height 100vh
			background-color rgba(255,230,0,0.9)
			//background-color rgba(0,0,0,0.8)
			&-bg
				position absolute
				top 0
				left 0
				width 100%
				height 100vh
			&-wrap
				position absolute
				top 50%
				left 50%
				transform translateY(-50%) translateX(-50%)
				width 84%
				padding 35px 20px 30px
				background-color #fff
				border-radius 8px
			&-top
				padding-bottom 24px
				border-bottom 1px solid #c3c3c3
				margin-bottom 26px
			&__closebtn
				position absolute
				top 15px
				right 15px
				a
					img
						width 16px
			&__title
				text-align center
				font-size 17px
				font-weight 600
				margin-bottom 28px
			&__btn
				padding-bottom 17px
				a
					display block
					width 100%
					height 45px
					background-color #ffe600
					border-radius (45px/2)
					border 2px solid #000
					margin 0 auto
					&:active
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 14px
						font-weight 600
						line-height 42px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 8px)
							right 12px
							width 17px
							height 17px
							background url(/assets/images/common/ico-btn.svg)
							background-size 17px auto
			&__passcode
				text-align center
				font-size 12px
				font-weight 600
			&__qrcode
				&__title
					text-align center
					font-size 14px
					font-weight 600
					margin-bottom 10px
				&__pct
					text-align center
					margin-bottom 14px
					img
						width 150px
				&__btns
					display flex
					flex-wrap wrap
					justify-content space-between
					width 200px
					margin 0 auto
					a
						display block
						width 50%
						text-align center
						color #000
						img
							height 37px
							margin-bottom 9px
						span
							display block
							font-size 12px
							letter-spacing 0.05em
							font-weight 600
						&.disable
							opacity 0.5
			&__error
				font-size 12px
				font-weight 600
				line-height 24px
				strong
					display block
					margin-bottom 20px

	.alert
		position fixed
		z-index 100
		bottom 0
		left 0
		width 100vw
		padding 20px 30px 30px
		background #ffe600
		visibility hidden
		transform translateY(230px)
		transition transform 0.25s ease-out, visibility 0.25s
		&.open
			visibility visible
			transform translateY(0px)
		&__text
			padding-top 60px
			background url(/assets/images/me/ico-alert.svg) center top no-repeat
			background-size 45px 45px
			margin-bottom 15px
			p
				font-size 10px
				font-weight 600
				line-height 21px
		&__btn
			a
				display block
				width 100%
				height 45px
				background-color #ffe600
				border-radius (45px/2)
				border 2px solid #000
				margin 0 auto
				&:active
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 13px
					font-weight 600
					line-height 42px
					color #000
					&::after
						content ''
						position absolute
						top calc(50% - 8px)
						right 15px
						width 17px
						height 17px
						background url(/assets/images/common/ico-btn.svg)
						background-size 17px auto
		&__closebtn
			position absolute
			top 0
			right 0
			width 38px
			a
				display block
				padding 11px
				img
					width 100%

	//hover追加分
	a.mypage-list-block:active
		color #ff72a2
