//PC
@media screen and (min-width 769px)

	.school

		//トップ
		&-index
			max-width 1200px
			min-width 960px
			margin 0 auto 80px

			//トップ分上書き
			.top-course__lead
				margin-top -44px
				text-align center
				margin-bottom 74px
			.top-course__lead + div
				margin-top 0
				text-align center
				margin-bottom 74px
			.top-course__list__text
				text-align left
			// .top-course__list
			// 	ul
			// 		li
			// 			padding-bottom 290px

			&__btn
				padding-top 15px
				padding-bottom 155px
				a
					display block
					width 390px
					height 70px
					background-color #ffe600
					border-radius (70px/2)
					border 3px solid #000
					margin 0 auto
					&:hover
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						border 3px solid #000
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 18px
						font-weight 600
						line-height 67px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 10px)
							right 23px
							width 20px
							height 20px
							background url(/assets/images/common/ico-btn.svg)
							background-size 20px auto

		//詳細
		&-detail
			overflow hidden
			width 960px
			background-color #fff
			border-radius 16px
			box-shadow 0px 0px 4px rgba(0,0,0,0.15)
			margin 0 auto 150px
			&__pct
				position relative
				overflow hidden
				height 350px
				margin-bottom 52px
				.wave
					position absolute
					display block
					bottom 0
					transform scale(1.5, 1.5)
				img
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
					-webkit-transform: translateY(-50%) translateX(-50%);
					width: 100%;
				&__icon
					position absolute
					top 20px
					left 20px
					width 165px
			//レギュラースクール
			&__title
				text-align center
				font-size 28px
				line-height 1.5
				font-weight 600
				margin-bottom 25px
				//svg幅設定
				&.socialcreativecourse
					margin-top -15px
					margin-bottom 13px
					img
						width 356px
				&.artdesigncourse
					margin-top -15px
					margin-bottom 13px
					img
						width 316px
				&.programingcreativecourse
					margin-top -15px
					margin-bottom 13px
					img
						width 502px
			//短期スクール
			&__schooltitle
				width 800px
				text-align center
				font-size 28px
				letter-spacing 0.08em
				line-height 1.5
				font-weight 600
				padding-bottom 50px
				border-bottom 1px solid #c0c0c0
				margin -5px auto 56px
			&__schoolsubtitle
				width 800px
				text-align center
				font-size 24px
				letter-spacing 0.05em
				line-height 1.5
				font-weight 600
				margin -5px auto 25px
				&-logo
					display block
					padding-top 10px
					img
						width 400px
			&__status
				display flex
				align-items center
				justify-content center
				margin-bottom 40px
				&__target
					display inline-block
					font-size 16px
					letter-spacing 0.05em
					font-weight 600
					margin-right 20px
				&--statetag
					display flex
				&__icon
					display flex
					align-items center
					justify-content center
					width 130px
					height 24px
					font-size 12px
					line-height 1
					font-weight 600
					color #fff
					border-radius 5px
					&.entry-remain
						background-color #00C864
					&.entry-cancel
						background-color #007038
					&.standby
						background-color #009ffc
					&.end
						background-color #b8b8b8
					.small
						font-size 10px
					&.online
						margin-left 10px
						width auto
					&.online
					&.offline
					&.realschool
						// margin-left 22px
						img
							// width auto  # 0810
							height 24px
			&__btn
				margin-top -5px
				padding-bottom 60px
				a
					display block
					width 312px
					height 60px
					background-color #ffe600
					border-radius (60px/2)
					border 3px solid #000
					margin 0 auto
					&:hover
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						border 3px solid #000
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 16px
						font-weight 600
						line-height 56px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 10px)
							right 20px
							width 20px
							height 20px
							background url(/assets/images/common/ico-btn.svg)
							background-size 20px auto
			&__text
				width 800px
				padding-top 55px
				border-top 1px solid #c0c0c0
				margin 0 auto 32px
				+ .school-detail__description
					margin-top 70px
				strong
					display block
					text-align center
					font-size 24px
					margin-bottom 24px
				p
					font-size 16px
					letter-spacing 0.05em
					line-height 32px
					font-weight 600
					small
						font-size 16px
						//color #666666
					a
						color #ff72a2
						text-decoration underline
			&__fig
				width 800px
				margin 0 auto 75px
				ul
					display flex
					flex-wrap wrap
					li
						width 380px
						&:nth-of-type(2n)
							margin-left 40px
						figure
							img
								overflow hidden
								width 100%
								border-radius 16px
							figcaption
								font-size 14px
								font-weight 600
								line-height 28px
								padding-top 15px
			&__description
			&__attention
				width 800px
				margin 0 auto
				border-top 1px solid #cacaca
				&__title
					display flex
					justify-content center
					position relative
					top -20px
					margin 0 auto 5px
					span
						display inline-block
						text-align center
						min-width 120px
						height 40px
						font-size 18px
						line-height 38px
						letter-spacing 0.08em
						font-weight 600
						padding 0 25px
						background-color #fff
						border 1px solid #cacaca
						border-radius 20px
				p
					font-size 16px
					line-height 32px
					font-weight 600
					small
						font-size 16px
						//color #666666
			&__description
				&__subtitle
					width 800px
					text-align center
					font-size 24px
					font-weight 600
					padding-top 53px
					margin 0 auto 53px
					display flex
					flex-direction column
					align-items center
					&--statetag
						display flex
						justify-content center
						margin-top 30px
					// いまここ
					&__icon
						display flex
						align-items center
						justify-content center
						width 130px
						height 24px
						font-size 12px
						line-height 1
						font-weight 600
						color #fff
						border-radius 5px
						margin-top 10px
						margin-bottom 10px
						// margin-left auto  // 右寄せにする
						&.entry-remain
							background-color #00C864
						&.entry-cancel
							background-color #007038
						&.standby
							background-color #009ffc
						&.end
							background-color #b8b8b8
						.small
							font-size 10px
						&.online
							width auto
							margin-left 10px
						img
							height 24px
					small
						font-size 16px
						//color #666666
				&__list
					border-top 1px solid #cacaca
					margin 43px 0 100px
					&__title
						display flex
						justify-content center
						position relative
						top -20px
						margin 0 auto -20px
						span
							display inline-block
							height 40px
							font-size 18px
							line-height 38px
							letter-spacing 0.08em
							font-weight 600
							padding 0 25px
							background-color #fff
							border 1px solid #cacaca
							border-radius 20px
					&__btn
						margin-top 10px
						a
							display block
							width 160px
							height 40px
							background-color #ffe600
							border-radius (40px/2)
							border 3px solid #000
							margin 0 auto
							&:hover
								text-decoration none
								background url(/assets/images/common/bg-btn-hover-pink.png)
								background-size (118px/2) (26px/2)
								border 3px solid #000
								animation btnStripe59 2s infinite linear
							span
								position relative
								display block
								text-align center
								width 100%
								height 100%
								font-size 18px
								font-weight 600
								line-height 36px
								color #000
								&::after
									content ''
									position absolute
									top calc(50% - 10px)
									right 10px
									width 20px
									height 20px
									background url(/assets/images/common/ico-btn.svg)
									background-size 20px auto
					&__mapbtn
						a
							display block
							width 100px
							height 28px
							font-size 16px
							letter-spacing 0.1em
							line-height 28px
							font-weight 500
							color #fff
							padding-left 38px
							background #b3b3b3 url(/assets/images/common/ico-map.svg) left 14px center no-repeat;
							background-size 14px 20px
							border-radius 6px
							&:hover
								opacity 0.7
					dl
						display flex
						flex-wrap wrap
						align-items center
						justify-content space-between
						font-size 16px
						font-weight 600
						line-height 32px
						letter-spacing 0.08em
						padding 13px 0
						border-bottom 1px solid #cacaca
						dt
							text-align center
							width 160px
						dd
							display flex
							align-items center
							justify-content space-between
							width 640px
							padding 10px 0 10px 38px
							border-left 1px solid #cacaca
							.content_left
								padding-right 40px
							.content_right
								padding-right 10px
							.icon
								letter-spacing 0.01rem
								display flex
								align-items center
								justify-content center
								width 130px
								height 24px
								font-size 12px
								line-height 1
								font-weight 600
								color #fff
								border-radius 5px
								margin-top 10px
								margin-bottom 10px
								// margin-left auto  // 右寄せにする
								&.entry-remain
									background-color #00C864
								&.entry-cancel
									background-color #007038
								&.standby
									background-color #009ffc
								&.end
									background-color #b8b8b8
								.small
									font-size 10px
								&.online
									// margin-left 10px
									width auto
							small
								font-size 16px
								//color #666666
						&.dtwide
							dt
								.state
									width 240px
									display flex
									justify-content: flex-end
								width 240px
							dd
								width 560px
								padding 25px 0 28px 38px
						&.dtnarrow
							dt
								width 90px
							dd
								width 710px
			&__attention
				padding-bottom 10px
				border-bottom 1px solid #cacaca
				margin-bottom 50px
				p
					letter-spacing 0.05em
					margin-bottom 2em
			&__link
				text-align center
				padding-bottom 80px
				&__title
					font-size 16px
					font-weight 600
					line-height 32px
					margin-bottom 20px
					& + .caution
						color #f00
				&__caution
					font-size 16px
					font-weight 600
					line-height 32px
					color #666666
					margin-bottom 40px
				&__banner
					width 410px
					margin 0 auto
					transition transform 0.2s ease-out, box-shadow 0.2s ease-out
					border-radius 14px;
					&:hover
						position relative
						transform translateY(-5px)
						box-shadow 0px 0px 8px rgba(0,0,0,0.25)
					+ .school-detail__link__title
						padding-top 72px
					img
						width 100%
				&__btn
					+ .school-detail__link__title
						margin-top 50px
					> span
						display block
						width 410px
						height 70px
						text-align center
						font-size 18px
						letter-spacing 0.08em
						font-weight 600
						line-height 70px
						background-color #ffe600
						border-radius 35px
						margin 0 auto
					a
						display block
						width 390px
						height 70px
						background-color #ffe600
						border-radius (70px/2)
						border 3px solid #000
						margin 0 auto
						&:hover
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-pink.png)
							background-size (118px/2) (26px/2)
							border 3px solid #000
							animation btnStripe59 2s infinite linear
						span
							position relative
							display block
							text-align center
							width 100%
							height 100%
							font-size 18px
							font-weight 600
							line-height 66px
							color #000
							&::after
								content ''
								position absolute
								top calc(50% - 10px)
								right 25px
								width 20px
								height 20px
								background url(/assets/images/common/ico-btn.svg)
								background-size 20px auto
					// ラインのボタン
					&-line
						margin 40px 0 -20px
						a
							display block
							width 390px
							height 64px
							padding-left 66px
							background-color #00c300
							background-image url(/assets/images/form/ico-line.svg)
							background-position left center
							background-repeat no-repeat
							background-size auto 100%
							border-radius 8px
							margin 0 auto
							&:hover
								background-color #00e000
								background-image url(/assets/images/form/ico-line-hover.svg)
							&:active
								background-color #00b300
								background-image url(/assets/images/form/ico-line-active.svg)
							span
								display block
								text-align center
								font-size 18px
								letter-spacing 0.08em
								font-weight 600
								line-height 64px
								color #fff
					//btn枠内の注意書き
					&__caution
						width 390px
						text-align left
						font-size 16px
						font-weight 600
						line-height 32px
						color #f00
						margin 50px auto 20px
			//担当ナビゲーター
			&__member
				margin-top 60px
				a
					display block
					color #000
					&:hover
						color #ff72a2
				&__thumb
					position relative
					display block
					overflow hidden
					height 200px
					margin-bottom 20px
					border-radius 100px
					img
						position absolute
						width 100%
						top 50%
						left 50%
						transform translateY(-50%) translateX(-50%)
						-webkit-transform translateY(-50%) translateX(-50%)
				&__name
					display block
					text-align center
					font-size 24px
					font-weight 600
					margin-bottom 8px
				&__title
					display block
					text-align center
					font-size 14px
					font-weight 600
					line-height 32px
				ul
					display flex
					flex-wrap wrap
					//justify-content space-between
					li
						width 200px
						margin-right 100px
						margin-bottom 55px
						&:nth-of-type(3n)
							margin-right 0
				&__btn
					a
						display block
						width 384px
						height 70px
						background-color #ffe600
						border-radius (70px/2)
						border 3px solid #000
						margin 0 auto
						&:hover
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-pink.png)
							background-size (118px/2) (26px/2)
							border 3px solid #000
							animation btnStripe59 2s infinite linear
						span
							position relative
							display block
							text-align center
							width 100%
							height 100%
							font-size 18px
							font-weight 600
							line-height 66px
							color #000
							&::after
								content ''
								position absolute
								top calc(50% - 10px)
								right 20px
								width 20px
								height 20px
								transform rotate(90deg)
								background url(/assets/images/common/ico-btn.svg)
								background-size 20px auto

		//トップに戻るボタン
		&__topbtn
			margin -70px 0 160px
			a
				display block
				width 390px
				height 70px
				background-color #ffe600
				border-radius (70px/2)
				border 3px solid #000
				margin 0 auto
				&:hover
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 18px
					font-weight 600
					line-height 66px
					color #000
					&::after
						content ''
						position absolute
						top calc(50% - 10px)
						left 25px
						width 20px
						height 20px
						transform scale(-1, 1)
						background url(/assets/images/common/ico-btn.svg)
						background-size 20px auto

		//モーダル
		&-modal
			position fixed
			z-index 1000
			top 0
			left 0
			width 100vw
			height 100vh
			background rgba(255,230,0,0.9)
			&-wrap
				overflow-y scroll
				width 100vw
				height 100vh
			&__content
				width 960px
				padding 80px 80px 50px
				background-color #fff
				box-shadow 0px 0px 4px rgba(0,0,0,0.15)
				margin 100px auto 58px
				border-radius 16px
				&-head
					display flex
					flex-wrap wrap
					align-items center
					justify-content space-between
					width 590px
					margin 0 auto 70px
				&-body
					position relative
				&__thumb
					overflow hidden
					width 240px
					height 240px
					border-radius 120px
					margin-left 15px
					img
						width 100%
				&__title
					width 295px
					font-size 14px
					letter-spacing 0.03em
					font-weight 600
					line-height 32px
					strong
						display block
						font-size 36px
						line-height 50px
				&__desc
					font-size 16px
					letter-spacing 0.06em
					font-weight 600
					line-height 32px
					margin-bottom 90px
				&__list
					border-top 1px solid #cacaca
					margin 43px 0 0
					&__title
						display flex
						align-items center
						justify-content center
						position relative
						top -20px
						width 200px
						height 40px
						font-size 18px
						letter-spacing 0.08em
						font-weight 600
						background-color #fff
						border 1px solid #cacaca
						border-radius 20px
						margin 0 auto -27px
					&__thumb
						overflow hidden
						width 180px
						height 100px
						border-radius 8px
						margin-left 8px
						img
							width 100%
					a
						color: #000;
						&:hover
							color: #ff72a2;
						dl
							display flex
							flex-wrap wrap
							align-items center
							justify-content space-between
							padding 30px 0
							border-bottom 1px solid #cacaca
							&:nth-last-of-type(1)
								border-bottom 0px
							dt
								width 190px
							dd
								width 578px
								font-size 16px
								letter-spacing 0.08em
								font-weight 600
								line-height 32px
			&__pagenation
				display flex
				flex-wrap wrap
				justify-content space-between
				width 960px
				margin 0 auto 100px
				&-prev
				&-next
					width 210px
				&-next
					.school-modal__pagenation__name
						text-align right
					a
						flex-flow row-reverse
				&-closebtn
					overflow hidden
					width 70px
					height 70px
					img
						width 70px
					a:hover img
						position relative
						top -70px
				a
					display flex
					flex-wrap wrap
					align-items center
					justify-content space-between
					width 210px
					color #000
					&:hover
						color #ff72a2
				&__thumb
					display block
					overflow hidden
					width 70px
					height 70px
					border-radius 35px
					border 3px solid #000
					img
						width 100%
				&__name
					display block
					width 130px
					font-size 18px
					font-weight 600



//SP
@media screen and (max-width 768px)

	.school

		//トップ
		&-index
			margin-bottom 10px

			//トップ分上書き
			.top-course__lead
				width 84%
				padding-top 5px
				margin 0 auto 32px
			.top-course__list
				z-index 18
				ul
					display block
					width 84%
					padding-left 0
					margin 0 auto
					li
						width 100%
						padding-bottom 0
						margin-bottom 75px
			&__btn
				padding-top 20px
				padding-bottom 100px
				a
					display block
					width 84%
					height 45px
					background-color #ffe600
					border-radius (46px/2)
					border 2px solid #000
					margin 0 auto
					&:active
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						border 2px solid #000
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 13px
						font-weight 600
						line-height 45px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 8px)
							right 14px
							width 17px
							height 17px
							background url(/assets/images/common/ico-btn.svg)
							background-size 17px auto

		//詳細
		&-detail
			overflow hidden
			width 84%
			background-color #fff
			border-radius 8px
			box-shadow 0px 0px 2px rgba(0,0,0,0.15)
			margin 0 auto 75px
			&__pct
				position relative
				overflow hidden
				height 115px
				margin-bottom 26px
				.wave
					position absolute
					display block
					bottom 0
				img
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
					-webkit-transform: translateY(-50%) translateX(-50%);
					width: 100%;
				&__icon
					position absolute
					top 10px
					left 10px
					width 94px
			//レギュラースクール
			&__title
				text-align center
				font-size 18px
				line-height 1.5
				font-weight 600
				margin-bottom 16px
				//svg幅設定
				&.socialcreativecourse
				&.artdesigncourse
				&.programingcreativecourse
					margin-top -7px
					img
						height 88px
			//短期スクール
			&__schooltitle
				text-align center
				font-size 18px
				letter-spacing 0.08em
				line-height 1.5
				font-weight 600
				padding-bottom 25px
				border-bottom 1px solid #c0c0c0
				margin -2px 20px 22px
			&__schoolsubtitle
				text-align center
				font-size 16px
				letter-spacing 0.05em
				line-height 1.7
				font-weight 600
				margin 0 20px 12px
				&-logo
					display block
					padding-top 5px
					padding-bottom 5px
					img
						width 220px

			&__status
				display flex
				flex-direction column
				flex-wrap nowrap
				align-items center
				justify-content center
				margin-bottom 30px
				&__target
					display block
					text-align center
					width 100%
					font-size 12px
					font-weight 600
					padding-bottom 15px
				&--statetag
					display flex
				&__icon
					display flex
					align-items center
					justify-content center
					width 141px
					height 20px
					font-size 10px
					line-height 1
					font-weight 600
					color #fff
					border-radius 5px
					&.entry-remain
						background-color #00C864
					&.entry-cancel
						background-color #007038
					&.standby
						background-color #009ffc
					&.end
						background-color #b8b8b8
					.small
						font-size 8px
					//オンライン/リアルスクールアイコン
					&.online
					&.offline
					&.realschool
						display inline
						width auto
						margin-left 5px
						img
							// width 100%
							height 20px
			&__btn
				padding 15px 20px 30px
				a
					display block
					width 100%
					height 45px
					background-color #ffe600
					border-radius (45px/2)
					border 2px solid #000
					margin 0 auto
					&:active
						text-decoration none
						background url(/assets/images/common/bg-btn-hover-pink.png)
						background-size (118px/2) (26px/2)
						border 2px solid #000
						animation btnStripe59 2s infinite linear
					span
						position relative
						display block
						text-align center
						width 100%
						height 100%
						font-size 14px
						font-weight 600
						line-height 42px
						color #000
						&::after
							content ''
							position absolute
							top calc(50% - 8px)
							right 12px
							width 17px
							height 17px
							background url(/assets/images/common/ico-btn.svg)
							background-size 17px auto
			&__text
				padding-top 32px
				border-top 1px solid #c0c0c0
				margin 0 20px 24px
				+ .school-detail__description
					margin-top 35px
				strong
					display block
					font-size 17px
					letter-spacing 0.08em
					line-height 1.8
					margin-bottom 16px
				p
					font-size 12px
					letter-spacing 0.02em
					line-height 24px
					font-weight 600
					small
						font-size 12px
						//color #666666
					a
						color #ff72a2
						text-decoration underline
			&__fig
				margin 0 20px 45px
				ul
					li
						width 100%
						margin-bottom 30px
						figure
							img
								overflow hidden
								width 100%
								border-radius 8px
							figcaption
								font-size 9px
								font-weight 600
								line-height 18px
								padding-top 7px
			&__description
			&__attention
				margin 0 20px
				border-top 1px solid #cacaca
				&__title
					display flex
					justify-content center
					position relative
					top -12px
					margin 0 auto 3px
					span
						display inline-block
						height 25px
						font-size 12px
						line-height 24px
						letter-spacing 0.08em
						font-weight 600
						padding 0 15px
						background-color #fff
						border 1px solid #cacaca
						border-radius 13px
				p
					font-size 12px
					letter-spacing 0.02em
					line-height 24px
					font-weight 600
					small
						font-size 12px
						//color #666666
			&__description
				&__subtitle
					text-align center
					font-size 17px
					font-weight 600
					letter-spacing 0.08em
					line-height 1.8
					padding-top 23px
					margin-bottom 18px
					&--statetag
						display flex
						justify-content center
						margin-top 15px
						margin-bottom 30px
					&__icon
						letter-spacing 0.01em
						display flex
						align-items center
						justify-content center
						width 141px
						height 20px
						font-size 10px
						line-height 1
						font-weight 600
						color #fff
						border-radius 5px
						&.entry-remain
							background-color #00C864
						&.entry-cancel
							background-color #007038
						&.standby
							background-color #009ffc
						&.end
							background-color #b8b8b8
						.small
							font-size 8px
						//オンライン/リアルスクールアイコン
						&.online
						&.offline
						&.realschool
							display inline
							width auto
							margin-left 5px
							img
								// width 100%
								height 20px
				&__list
					border-top 1px solid #cacaca
					margin 22px 0 50px
					&__title
						display flex
						justify-content center
						position relative
						top -13px
						margin 0 auto -10px
						span
							display inline-block
							height 25px
							font-size 12px
							line-height 24px
							letter-spacing 0.02em
							font-weight 600
							padding 0 15px
							background-color #fff
							border 1px solid #cacaca
							border-radius 13px
					&__btn
						margin-top 5px
						a
							display block
							width 113px
							height 30px
							background-color #ffe600
							border-radius (30px/2)
							border 2px solid #000
							margin 0 auto
							&:active
								text-decoration none
								background url(/assets/images/common/bg-btn-hover-pink.png)
								background-size (118px/2) (26px/2)
								border 2px solid #000
								animation btnStripe59 2s infinite linear
							span
								position relative
								display block
								text-align center
								width 100%
								height 100%
								font-size 12px
								font-weight 600
								line-height 28px
								color #000
								&::after
									content ''
									position absolute
									top calc(50% - 6px)
									right 9px
									width 13px
									height 13px
									background url(/assets/images/common/ico-btn.svg)
									background-size 13px auto
					&__text
						margin-right 13px
					&__mapbtn
						a
							display inline-block
							width 55px
							height 17px
							font-size 9px
							letter-spacing 0.1em
							line-height 16px
							font-weight 500
							color #fff
							padding-left 20px
							background #b3b3b3 url(/assets/images/common/ico-map.svg) left 7px center no-repeat;
							background-size 8px auto
							border-radius 3px
							&:active
								opacity 0.7

					dl
						display flex
						flex-wrap wrap
						align-items center
						justify-content space-between
						font-size 12px
						letter-spacing 0.02em
						line-height 24px
						font-weight 600
						padding 6px 0
						border-bottom 1px solid #cacaca
						dt
							text-align center
							width 75px
						dd
							width calc(100% - 75px)
							padding 5px 0 5px 19px
							border-left 1px solid #cacaca
							.icon
								display flex
								align-items center
								justify-content center
								width 100%
								// max-width 141px
								height 20px
								font-size 10px
								line-height 1
								font-weight 600
								color #fff
								border-radius 3px
								margin-top 10px
								margin-bottom 10px
								// margin-left auto  // 右寄せにする
								&.entry-remain
									background-color #00C864
								&.entry-cancel
									background-color #007038
								&.standby
									background-color #009ffc
								&.end
									background-color #b8b8b8
								.small
									font-size 8px
							small
								font-size 12px
								//color #666666
						&.dtwide
							dt
								width 123px
							dd
								width calc(100% - 123px)
						&.dtnarrow
							dt
								width 40px
							dd
								width calc(100% - 40px)
			&__attention
				padding-bottom 5px
				border-bottom 1px solid #cacaca
				margin-bottom 35px
				p
					letter-spacing 0.05em
					margin-bottom 2em
			&__link
				text-align center
				padding-bottom 40px
				&__title
					font-size 12px
					font-weight 600
					line-height 22px
					margin-bottom 15px
					& + .caution
						color #f00
				&__caution
					text-align left
					font-size 12px
					font-weight 600
					line-height 21px
					color #666666
					padding 0 20px
					margin-bottom 40px
				&__banner
					margin 0 20px
					border-radius 14px;
					transition transform 0.2s ease-out, box-shadow 0.2s ease-out
					&:hover
						position relative
						transform translateY(-5px)
						box-shadow 0px 0px 8px rgba(0,0,0,0.25)
					+ .school-detail__link__title
						padding-top 34px
					img
						width 100%
				&__btn
					margin 0 20px
					+ .school-detail__link__title
						margin-top 25px
					> span
						display block
						height 45px
						text-align center
						font-size 12px
						letter-spacing 0.08em
						font-weight 600
						line-height 45px
						background-color #ffe600
						border-radius 18px
						margin 0 auto
					a
						display block
						width 100%
						height 45px
						background-color #ffe600
						border-radius (45px/2)
						border 2px solid #000
						margin 0 auto
						&:active
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-pink.png)
							background-size (118px/2) (26px/2)
							border 2px solid #000
							animation btnStripe59 2s infinite linear
						span
							position relative
							display block
							text-align center
							width 100%
							height 100%
							font-size 14px
							font-weight 600
							line-height 42px
							color #000
							&::after
								content ''
								position absolute
								top calc(50% - 8px)
								right 12px
								width 17px
								height 17px
								background url(/assets/images/common/ico-btn.svg)
								background-size 17px auto
					// ラインのボタン
					&-line
						margin 20px 20px -16px
						a
							display block
							width 100%
							height 45px
							padding-left 54px
							background-color #00c300
							background-image url(/assets/images/form/ico-line.svg)
							background-position left center
							background-repeat no-repeat
							background-size auto 100%
							border-radius 8px
							margin 0 auto
							&:active
								background-color #00b300
								background-image url(/assets/images/form/ico-line-active.svg)
							span
								display block
								text-align center
								font-size 13px
								letter-spacing 0.08em
								font-weight 600
								line-height 44px
								color #fff
					//btn枠内の注意書き
					&__caution
						width 100%
						text-align left
						font-size 12px
						font-weight 600
						line-height 21px
						color #f00
						margin-top 40px
						+ a
							margin-top 25px
			//担当ナビゲーター
			&__member
				margin-top 15px
				a
					display block
					color #000
					&:active
						color #ff72a2
				&__thumb
					position relative
					display block
					overflow hidden
					width 100px
					height 100px
					margin 0 auto 10px
					border-radius 50px
					img
						position absolute
						width 100%
						top 50%
						left 50%
						transform translateY(-50%) translateX(-50%)
						-webkit-transform translateY(-50%) translateX(-50%)
				&__name
					display block
					text-align center
					font-size 17px
					font-weight 600
					margin-bottom 5px
				&__title
					display block
					text-align center
					font-size 12px
					font-weight 600
					line-height 24px
				ul
					display flex
					flex-wrap wrap
					justify-content space-between
					li
						width 46.4%
						margin-bottom 32px
				&__btn
					padding-bottom 15px
					a
						display block
						width 100%
						height 45px
						background-color #ffe600
						border-radius (45px/2)
						border 2px solid #000
						margin 0 auto
						&:active
							text-decoration none
							background url(/assets/images/common/bg-btn-hover-pink.png)
							background-size (118px/2) (26px/2)
							border 2px solid #000
							animation btnStripe59 2s infinite linear
						span
							position relative
							display block
							text-align center
							width 100%
							height 100%
							font-size 14px
							font-weight 600
							line-height 42px
							color #000
							&::after
								content ''
								position absolute
								top calc(50% - 8px)
								right 12px
								width 17px
								height 17px
								transform rotate(90deg)
								background url(/assets/images/common/ico-btn.svg)
								background-size 17px auto

		//トップに戻るボタン
		&__topbtn
			margin -35px 0 80px
			a
				display block
				width 74%
				height 45px
				background-color #ffe600
				border-radius (45px/2)
				border 2px solid #000
				margin 0 auto
				&:active
					text-decoration none
					background url(/assets/images/common/bg-btn-hover-pink.png)
					background-size (118px/2) (26px/2)
					animation btnStripe59 2s infinite linear
				span
					position relative
					display block
					text-align center
					width 100%
					height 100%
					font-size 14px
					font-weight 600
					line-height 42px
					color #000
					&::after
						content ''
						position absolute
						top calc(50% - 8px)
						left 12px
						width 17px
						height 17px
						transform scale(-1, 1)
						background url(/assets/images/common/ico-btn.svg)
						background-size 17px auto

		//モーダル
		&-modal
			position fixed
			z-index 1000
			top 0
			left 0
			width 100vw
			height 100vh
			background rgba(255,230,0,0.9)
			&-wrap
				overflow-y scroll
				width 100vw
				height 100vh
			&__content
				width 84%
				padding 38px 20px 25px
				background-color #fff
				box-shadow 0px 0px 2px rgba(0,0,0,0.15)
				margin 50px auto 25px
				border-radius 8px
				&-head
					text-align center
					margin-bottom 20px
				&-body
					position relative
				&__thumb
					overflow hidden
					width 150px
					height 150px
					border-radius 75px
					margin 0 auto 23px
					img
						width 100%
				&__title
					font-size 10px
					letter-spacing 0.03em
					font-weight 600
					line-height 16px
					strong
						display block
						font-size 20px
						line-height 27px
						margin-bottom 8px
				&__desc
					font-size 12px
					letter-spacing 0.06em
					font-weight 600
					line-height 24px
					margin-bottom 52px
				&__list
					border-top 1px solid #cacaca
					margin 22px 0 5px
					&__title
						display flex
						align-items center
						justify-content center
						position relative
						top -13px
						width 140px
						height 25px
						font-size 12px
						letter-spacing 0.02em
						line-height 24px
						font-weight 600
						background-color #fff
						border 1px solid #cacaca
						border-radius 13px
						margin 0 auto -10px
					&__thumb
						overflow hidden
						width 108px
						height 60px
						border-radius 4px
						margin-left 5px
						img
							width 100%
					a
						color: #000;
						&:hover
							color: #ff72a2;
						dl
							display flex
							flex-wrap wrap
							align-items center
							justify-content space-between
							padding 15px 0
							border-bottom 1px solid #cacaca
							&:nth-last-of-type(1)
								border-bottom 0px
							dt
								width 112px
							dd
								width calc(100% - 123px)
								font-size 12px
								letter-spacing 0.08em
								font-weight 600
								line-height 24px
			&__pagenation
				display flex
				flex-wrap wrap
				justify-content space-between
				width 84%
				margin 0 auto 150px
				&-prev
				&-next
					width 32px
				&-prev
					transform scale(-1, 1)
				&-closebtn
					overflow hidden
					width 32px
					height 32px
					img
						width 32px
				a
					display flex
					flex-wrap wrap
					align-items center
					justify-content space-between
					width 32px
					color #000
					&:active img
						position relative
						top -32px
				&__thumb
					display block
					overflow hidden
					width 32px
					height 32px
					img
						width 100%
				&__name
					display none


//SP　狭い場合
@media screen and (max-width 333px)

	.school-detail__title
		img
			// width 84%  // 消した
	.school-detail__status
		flex-wrap nowrap  // かえた

	.school-detail__status__target
		display block
		width 100%
		text-align center
		margin 0 0 5px
